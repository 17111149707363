import fileDownload from "js-file-download";
import { API } from "@/boot/api";

const state = {
  processingOperations: [],
  processingOperationsMeta: null,
};

const getters = {};

const actions = {
  loadProcessingOperations({ commit }, params) {
    return new Promise((resolve, reject) => {
      API.get("account/operations/processing", params)
        .then((response) => {
          commit("SET_PROCESSING_OPERATIONS", response.data.data);
          commit("SET_PROCESSING_OPERATIONS_META", response.data.meta);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generateQr(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        "qr-code/download",
        { size: data.size, extension: data.extension, link: data.link },
        { responseType: "blob" }
      )
        .then((response) => {
          fileDownload(response.data, `qr.${data.extension}`);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generateMenu(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        "qr-code/template/download",
        { type: data.type, extension: data.extension, link: data.link },
        { responseType: "blob" }
      )
        .then((response) => {
          fileDownload(response.data, `${data.type}.${data.extension}`);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_PROCESSING_OPERATIONS(state, payload) {
    state.processingOperations = payload;
  },

  SET_PROCESSING_OPERATIONS_META(state, payload) {
    state.processingOperationsMeta = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
