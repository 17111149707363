import { API } from "@/boot/api";

const state = {
  wallet: null,
  defaultWallet: null,
};

const getters = {};

const mutations = {
  SET_WALLET: (state, wallet) => {
    state.wallet = wallet;
  },

  SET_DEFAULT_WALLET: (state, defaultWallet) => {
    state.defaultWallet = defaultWallet;
  },
};

const actions = {
  loadWallet({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`points/${id}/wallet-pass`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
        .then((response) => {
          commit("SET_WALLET", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editWallet(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`wallet-pass/${data.wallet_pass}`, data.data_wallet, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadDefaultWallet({ commit }, wallet_pass) {
    return new Promise((resolve, reject) => {
      API.post(`wallet-pass/${wallet_pass}/reset`)
        .then((response) => {
          commit("SET_DEFAULT_WALLET", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
