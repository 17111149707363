<script>
export default {
  name: "TransitionExpand",

  methods: {
    enter(element) {
      element.style.width = getComputedStyle(element).width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },

    afterEnter(element) {
      element.style.height = "auto";
    },

    leave(element) {
      element.style.height = getComputedStyle(element).height;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<template>
  <Transition
    name="expand"
    @enter="enter"
    @afterEnter="afterEnter"
    @leave="leave"
  >
    <slot />
  </Transition>
</template>

<style lang="scss" scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height 500ms ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
