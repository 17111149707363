<script>
export default {
  mounted() {
    const bitrix = document.getElementsByClassName(
      "b24-widget-button-visible"
    )[0];

    if (bitrix) {
      bitrix.style.display = "none";
    }
  },
};
</script>

<template>
  <div class="main-layout">
    <div class="main-container">
      <RouterView />
    </div>
  </div>
</template>

<style>
.main-layout {
  width: 100%;
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-container {
  transition:
    padding-top 0.12s,
    padding-right 0.12s,
    padding-bottom 0.12s,
    padding-left 0.12s !important;
}
</style>
