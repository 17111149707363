<script>
import { mapActions, mapMutations } from "vuex";
import { themeColorStore } from "@/store/themeColor";
import { mapActions as piniaMapActions } from "pinia";
import UiMessage from "@/ui/UiMessage.vue";
import { useStopZoom } from "@/package/global-use-case/useStopZoom";

export default {
  name: "App",
  components: { UiMessage },

  methods: {
    ...mapActions({
      getSystemCountries: "system/getSystemCountries",
    }),

    ...mapMutations({
      SET_SYSTEM_COUNTRY: "system/SET_SYSTEM_COUNTRY",
    }),

    ...piniaMapActions(themeColorStore, ["SET_THEME_COLOR_FROM_LOCAL_STORAGE"]),
  },

  created() {
    this.SET_THEME_COLOR_FROM_LOCAL_STORAGE();
  },

  beforeMount() {
    this.getSystemCountries();
  },

  mounted() {
    useStopZoom();

    if (window.location.host.includes("localhost")) {
      return;
    }

    if (
      window.location.host.includes("kz") &&
      localStorage.getItem("country") !== "kz"
    ) {
      localStorage.setItem("country", "kz");
      this.SET_SYSTEM_COUNTRY("kz");
    }

    if (
      !window.location.host.includes("kz") &&
      localStorage.getItem("country") === "kz"
    ) {
      localStorage.setItem("country", "ru");
      this.SET_SYSTEM_COUNTRY("ru");
    }
  },
};
</script>

<template>
  <RouterView />

  <UiMessage />
</template>

<style lang="scss">
input {
  outline: none;
  color-scheme: dark;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: $new-light-primary !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

input[data-autocompleted] {
  background-color: transparent !important;
}
</style>
