import rating from "@/assets/icons/rating.svg";
import desktop from "@/assets/icons/desktop.svg";
import chat from "@/assets/icons/chat.svg";
import monitoring_card_1_kz from "@/assets/images/monitoring_card_1_kz.png";
import index_header_notebook_kz from "@/assets/images/index_header_notebook_kz.png";
import monitoring_card_2_kz from "@/assets/images/monitoring_card_2_kz.png";
import monitoring_card_3_kz from "@/assets/images/monitoring_card_3_kz.png";
import monitoring_item_1_kz from "@/assets/images/monitoring_item_1_kz.png";
import monitoring_item_2_kz from "@/assets/images/monitoring_item_3_kz.png";
import monitoring_item_3_kz from "@/assets/images/monitoring_item_2_kz.png";

export default {
  HEADER: {
    moreButton: "Көбірек білу үшін",
    updates: "*Әр 24 сағат сайын көрсеткіштер",
    title: "Сіздің бизнесіңізге арналған <br> дайын ниеттестік бағдарламасы",
    subTitle:
      "Толық талдауға, мобильді қосымшаға <br> және жедел қолдауға ие жеке кабинет.",
    panels: {
      clients: "тұтынушылар",
      addRC: "сатып алу үшін ТК жіберді",
      tp: "бөлшек сауда нүктелері",
      city: "жабық қалалар",
      totalBuy: "сатып алулардың жалпы саны",
    },
  },
  clients: "Клиенттерге",
  franchising: "Франчайзи",
  btnRegister: "Тіркеу",
  btnLogin: "Кіру",
  title: "Сіздің бизнесіңізге арналған <br> дайын ниеттестік бағдарламасы",
  subTitle:
    "Толық талдауға, мобильді қосымшаға <br> және жедел қолдауға ие жеке кабинет.",
  mainImage: index_header_notebook_kz,
  staticTitle: "Өзіңіздің ЖШС немесе ЖК бойынша толық статистика жинаңыз",
  staticInfo: {
    title: "Ыңғайлы жүйеден де қарапайым",
    text: "Платформа заманауи бизнеске қажетті процестерді автоматтандырады. Өзіңізге қажеттісін таңдаңыз. Бірнеше рет басу арқылы жүйені және клиенттер туралы жиналған деректерді өзіңізге бейімдеңіз. Енді ешқандай қиындықтар мен айналма жолдар жоқ — тек дұрыс графиктер мен нақты сандар.",
  },
  statisticCards: [
    {
      imagePath: rating,
      title: "Клиеттердің белсенділігі <br/> графиктер мен сандарда",
      text: "Ағымдағы клиенттер туралы толық ақпарат — <br/>тұрақты клиенттерді ұстап қалыңыз және жаңаларын тартыңыз",
    },
    {
      imagePath: desktop,
      title: "Қарапайым және қолжетімді жүйелер — <br/> әр кәсіпкердің арманы",
      text: "Кірісті арттыру, беделді жақсарту және тұтынушылардың ниеттестігін <br/> арттыру үшін клиенттермен тығыз байланыста жұмыс істеу мүмкіндігі",
    },
    {
      imagePath: chat,
      title: "Клиенттермен қарым-қатынас",
      text: "Топтарға бөліңіз және клиенттермен нүктелік өзара әрекеттесу үшін <br/> PUSH-таратылымдар жасаңыз",
    },
  ],
  sliderItems: [
    {
      icon: "icon-share",
      text: "Ниеттестік бағдарламасы",
    },
    {
      icon: "icon-people",
      text: "Клиенттер",
    },
    {
      icon: "icon-wallet",
      text: "Wallet карталары",
    },
    {
      icon: "icon-person",
      text: "Менің қызметкерлерім",
    },
    {
      icon: "icon-tsp",
      text: "Менің кәсіпорындарым",
    },
    {
      icon: "icon-case",
      text: "Сервистер",
    },
    {
      icon: "icon-balance",
      text: "Processing",
    },
    {
      icon: "icon-share",
      text: "Ниеттестік бағдарламасы",
    },
    {
      icon: "icon-share",
      text: "Ниеттестік бағдарламасы",
    },
    {
      icon: "icon-people",
      text: "Клиенттер",
    },
    {
      icon: "icon-wallet",
      text: "Wallet карталары",
    },
    {
      icon: "icon-person",
      text: "Менің қызметкерлерім",
    },
    {
      icon: "icon-tsp",
      text: "Менің кәсіпорындарым",
    },
    {
      icon: "icon-case",
      text: "Сервистер",
    },
    {
      icon: "icon-balance",
      text: "Processing",
    },
    {
      icon: "icon-share",
      text: "Ниеттестік бағдарламасы",
    },
  ],
  loyaltyList: {
    firstColumn: [
      {
        id: 1,
        icon: "icon-home",
        title: "Тіркеу",
        text: "Біз сіздің уақытыңызды бағалаймыз және сіздің компанияңыздың адалдығы әлеміндегі тиімді басталуын қамтамасыз етуге дайынбыз",
      },

      {
        id: 2,
        icon: "icon-money-cents",
        title: "Ұпайлар алыңыз",
        text: "Ұпайларды төлем түрлері ретінде пайдалану пайдаға 25% және одан жоғары өнімді арттыруға көмектеседі",
      },

      {
        id: 3,
        icon: "icon-plus",
        title: "Розетканы қосыңыз",
        text: "Министрлер кабинетінің кеңейтілген функционалдығын толық пайдалану үшін негізгі қадам",
      },

      {
        id: 4,
        icon: "icon-wallet",
        title: "Бірегей карталар жасаңыз Wallet",
        text: "Клиенттерге арналған дизайн карточкасын баптау.Түрі мен мазмұнын сендірусіз және қосымша шығындарсыз өзгертіңіз",
      },

      {
        id: 5,
        icon: "icon-update",
        title: "Қайталама тапсырыс алыңыз",
        text: "Мөлдір жүйесі: 1 нүкте = 1 рубль.Ұпай жинайтын клиенттер жиі сатып алуды жиі жасайды",
      },
    ],

    secondColumn: [
      {
        id: 6,
        icon: "icon-card",
        title: "Үндеу",
        text: "Тұтынушыларды цифрландыру - компанияның кірістілігін арттырудың стратегиялық құралы",
      },

      {
        id: 7,
        icon: "icon-moneybox",
        title: "Қайырымдылық сыйлығы",
        text: "Ұпайларды есептеу үлкен сатып алуды ынталандырады, өйткені клиенттер қосымша құнды алады деп санайды",
      },

      {
        id: 8,
        icon: "icon-connection",
        title: "Орын rcpay.ru  және RC PAY",
        text: "Сіздің компанияңыз платформаны ұпайларды пайдаланатын жерлерді іздейтін көптеген пайдаланушылармен қосылады",
      },

      {
        id: 9,
        icon: "icon-send",
        title: "Push хабарландыруларын жіберіңіз",
        text: "Жарнамалар, жаңа заттар және басқа оқиғалар туралы есеп беру.Төмен белсенділік кезеңдері кезінде сатып алуды ынталандыру",
      },
    ],
  },

  monitoringGlobalTitle:
    "Адалдық бағдарламасы: Неліктен қажет және қалай таныстыру керек",

  monitoringTitle: "Ақпаратты алу және талдау — оңай",
  monitoringSubTitle:
    "Жаңа және тұрақты клиенттердің есебін жүргізіңіз. Ыңғайлы график көмегімен сату деңгейін қадағалаңыз. <br/>" +
    "Нарықтағы ең жаңа трендтерден хабардар болу және заманауи шешімдерді тез енгізу үшін, <br/>" +
    "сату сомасы мен орташа чек бойынша маңызды сандарды алыңыз. RC CITY - мен бұл оңай!",
  monitoringSecondTitle:
    "Өз компанияңыз туралы деректер үшін ыңғайлы интерфейс",
  monitoringCards: [
    {
      title: "Клиенттердің саны ",
      text: "Таңдалған уақыт кезеңінде келген клиенттердің жалпы санын есептеу",
      imagePath: monitoring_card_1_kz,
    },
    {
      title: "Транзакцияларды қарау",
      text: "Сатудың жалпы сомасы немесе сіздің сауда нүктелеріңіздің әрқайсысына орташа чек",
      imagePath: monitoring_card_2_kz,
    },
    {
      title: "Сатылымдар",
      text: "Сіздің сауда нүктеңіз бойынша бірегей сатылымдар саны",
      imagePath: monitoring_card_3_kz,
    },
  ],
  monitoringItems: [
    {
      title: "Жұмысты бастау үшін екі қарапайым қадам",
      text:
        "1. ЖСН енгізіңіз және ұйым түрін таңдаңыз <br/>" +
        "2. Жетекшінің Т.А.Ә., есеп шот нөмірін және банк БСК көрсетіңіз — барлық қажетті деректер өзі “тартылады”. " +
        "Минимум әрекеттер — максимум пайда!",
      imagePath: monitoring_item_1_kz,
    },
    {
      title:
        "Бизнестің тиімді және нәтижелі жұмыс <br/> істеуі үшін толық көрініс",
      text: "Өзіңіздің сауда нүктесі үшін қажетті позицияларды реттеңіз, фотосуреттерді жүктеңіз, сыйақы мен жеңілдіктер көлемін көрсетіңіз. Тек негізгі позициялар мен түсінікті интерфейс — артық ештеңе жоқ.",
      imagePath: monitoring_item_2_kz,
    },
    {
      title: "Маңызды құжаттар — бір папкада",
      text: "Жұмыс үшін қажет болуы мүмкін барлық нәрсе — шарт, тарифтер, құпиялылық саясаты, маркетингтік материалдар және т.б. —- енді осында. Бірнеше рет басу арқылы қажетті ақпаратқа қол жеткізіңіз. Қағазбастылық жоқ — бәрі жақсы.",
      imagePath: monitoring_item_3_kz,
    },
  ],
  tariffGlobalTitle: "Доступна регистрация коммерческих {nko} юридических лиц",
  tariffsTitle: "Сізге қандай тариф ыңғайлы?",
  mostPopular: "Ең танымалы",
  currency: "{currency} / жыл",
  tariffs: [
    {
      title: "Lite",
      subtitle: "Клиенттердің жаңа <br/> аудиториясын тарту",
      buttonType: "black-new",
      buttonText: "Байқап көру",
      additionalInfo: "",
      infoItems: [
        "1 ұйымды тіркеу",
        "5 сауда нүктесін жасау",
        "'Оңайлатылған статистика",
        "Промо-материалдар",
        "RC KASSA-ға қолжетімділік",
        "Электрондық құжат айналымы",
        "Айына 2 рет өзара есеп айырысу",
        "FAQ",
      ],
    },
    {
      title: "Promo",
      subtitle: "Сіздің бизнесіңізге бейімделген <br/> ниеттестік бағдарламасы",
      buttonType: "gold",
      buttonText: "Сатып алу",
      additionalInfo: "Lite тарифінен бәрі қолжетімді...",
      infoItems: [
        "15 ұйымды тіркеу",
        "15 сауда нүктесін жасау",
        "Кеңейтілген статистика",
        "RC KASSA-ға қолжетімділік",
        "Клиенттер үшін Push-таратылым",
        "Кері байланыс функциясы",
        "Клиенттерді цифрландыру үшін 1000 лицензия",
        "Wallet картасының дизайны",
        "Электрондық мәзір",
      ],
    },
    {
      title: "Pro +",
      subtitle: "Ірі компаниялар мен олардың <br/> филиалдары үшін",
      buttonType: "black-outline-new",
      buttonText: "Сатып алу",
      additionalInfo: "Lite, Promo тарифтерінен бәрі қолжетімді...",
      infoItems: [
        "30 ұйымды тіркеу",
        "30 сауда нүктесін жасау",
        "Кеңейтілген статистика",
        "Клиенттер үшін Push-таратылым",
        "Кері байланыс функциясы",
        "Wallet картасының дизайны",
        "Клиенттерді цифрландыру үшін <br/> лицензиялардың шексіз саны",
      ],
    },
  ],
  questionsTitle: "Сұрақтар мен жауаптар",
  questions: [
    {
      title:
        "Егер менде барбершоп болса, маған клиенттер ағынын ұлғайтуға ниеттестік бағдарламасы көмектесе ме?",
      text: "RC CITY ниеттестік бағдарламасы — бұл әмбебап шешім, шешім бизнестің кез келген саласына жарамды. Барлық ықтимал сұлулық салондарының, шаштараздардың және басқалардың барлық түрлерін қамтитын beauty-сегмент — -кез келген басқа салаларда ниеттестік бағдарламасын табысты қолдануға болатын бағыттар.",
    },
    {
      title: "Ниеттестік бағдарламасын іске қосу үшін не қажет?",
      text: "Интернетке шығатын ДК — ниеттестік бағдарламасын тікелей тіркеу және RC CITY.серіктесінің кабинетін пайдалану үшін. iOS немесе Android негізіндегі смартфонға RC KASSA қосымшасын жүктеу қажет болады, оның көмегімен қызметкерлер ұпайларды есептен шығару және есептеу үшін RC PAY клиенттік қолданбасынан QR кодын сканерлей алады.",
    },
    {
      title:
        "RC Group клиенттері үшін сыйақының қанша пайызын белгілеу керектігін қалай түсінуге болады?",
      text: "Ұсыныс компанияның клиенттері үшін тиімді болуы керек. Бонустардың оңтайлы мөлшері — тауар немесе қызмет құнының 4-25%-ы.",
    },
    {
      title: "Мен RC CITY-ге 3 заңды тұлғаны тіркей аламын ба?",
      text: "Иә, тіркей аласыз. Жалпы алғанда, сіз өзіңізге қанша қажет болса, сонша заңды тұлғаны тіркей аласыз. PROMO тарифі 15 заңды тұлғаға дейін, PRO+ 30 заңды тұлғаға дейін қосу мүмкіндігін қамтиды.",
    },
    {
      title:
        "Менде 10 сауда нүктесі бар. Олардың әрқайсысы бойынша жеке-жеке статистика ала аламын ба?",
      text: "Иә, әрине. Кабинет функционалы сізде бар әрбір сауда нүктесі туралы ақпаратты сұрыптай алатындай етіп ұйымдастырылған. Қажет болған жағдайда, барлық сауда нүктелері бойынша статистиканы бірден көруге болады.",
    },
    {
      title:
        "Клиент портреті, атап айтқанда, қандай позициялардан қалыптасады?",
      text: "Жасы, ерлер мен әйелдердің пайыздық қатынасы, орташа чек, осы бір анықталған сегменттегі клиенттер саны, сатылымдар саны мен сомасы, жынысын көрсетпеген клиенттердің пайыздық арақатынасы.",
    },
    {
      title: "Тарифтік жоспар деңгейін арттырудың маңызы бар ма?",
      text: "Сөзсіз. Апгрейд жағдайында сіз бір кабинетте көбірек нүктелер мен заңды тұлғаларды қосу мүмкіндігі клиенттердің едәуір толық статистикасына ие кеңейтілген функционалға және басқа бонустарға ие боласыз. Кейбір жағдайларда, мысалы, егер сізде бес сауда нүктесі болса, PROMO тарифінен бастау керек.",
    },
    {
      title: "RC KASSA қолданбасының негізгі артықшылықтары неде?",
      text: "Қолданбаның көмегімен сіз ұпайларды есептен шығарып, клиенттерге ұпай жинай аласыз, Wallet карталарының функционалдығымен жұмыс жасай аласыз (клиенттерді цифрландыру, қызметкерлердің жұмыс статистикасын жинау), осы операциялардың тарихын қадағалай аласыз, белгілі бір мекеменің акциялары туралы ақпаратты бөлек қойындыда көре аласыз.",
    },
    {
      title:
        "Push-хабарламалары тез тамақтану мейрамханасының кірісін арттыруға қалай көмектеседі?",
      text: "Push-хабарламалар — бұл бүгінгі күні клиентпен қарым-қатынастың ең жылдам тәсілі. Ол сатылымның өсуіне тікелей әсер етпейді, бірақ жанама түрде ниеттестік пен процеске қатысуды арттыру есебінен өте жақсы жұмыс істейді. Тез тамақтану мейрамханасының жұмысында мұндай хабарландыруларды ағымдағы жеңілдіктер, акцияның басталуы, жақсы мәмілелер және т.б. туралы хабарлау үшін пайдалануға болады.",
    },
  ],
  footer: {
    juristicDocs: "Заң құжаттары",
    contacts: "Байланыстар",
    tariffs: "Тарифтер",
    support: "Қолдау қызметі",
    privacy: "Құпиялылық саясаты",
    rewards: "Сыйақы тарату сценарийі",
    rcMark:
      "<p>" +
      '<span>RC Group</span> "ҚР-технологиялар" жауапкершілігі шектеулі серіктестігінің ("ҚР-технологиялар" ЖШҚ) сауда белгісі, DBA және сауда белгісі және/немесе қызмет көрсету белгісі болып табылады. СТН 7806578914; НМТН 1207800157256. Алдын ала жазбаша келісімсіз рұқсатсыз көшіруге немесе пайдалануға <span>тыйым салынады</span>.' +
      "</p>",
  },
};
