import enUS from "./en-us";
import ru from "./ru";
import kz from "./kz";
import ro from "./ro";

export default {
  "en-us": enUS,
  ru: ru,
  "en-gb": enUS,
  kz: kz,
  ro: ro,
};
