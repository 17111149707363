import { API } from "@/boot/api";

const state = {
  reviews: [],
  reviewsMeta: null,
  rating: null,
};

const getters = {};

const mutations = {
  SET_REVIEWS_LIST(state, reviewsList) {
    state.reviews = reviewsList;
  },

  SET_REVIEWS_LIST_META(state, reviewsMeta) {
    state.reviewsMeta = reviewsMeta;
  },

  SET_RATING(state, rating) {
    state.rating = rating;
  },
};

const actions = {
  loadReviewsList({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post("v2/account/reviews", data)
        .then((response) => {
          commit("SET_REVIEWS_LIST", response.data.data);
          commit("SET_REVIEWS_LIST_META", response.data.meta);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadRating({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("v2/account/reviews/statistics", {
        params: data,
      })
        .then((response) => {
          commit("SET_RATING", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeReviewStatus(_, review_id) {
    return new Promise((resolve) => {
      API.patch(`v2/account/reviews/${review_id}/visibility`).then(
        (response) => {
          resolve(response);
        }
      );
    });
  },

  globalChangeReviewsVisibility(_, payload) {
    return new Promise((resolve, reject) => {
      API.patch("v2/account/reviews/visibility", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
