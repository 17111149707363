<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import ButtonIcon from "@/components/common/ButtonIcon.vue";

const store = useStore();

defineOptions({
  name: "UiMessage",
});

const notification = computed(() => {
  return store.state.notification;
});

function close() {
  store.dispatch("clearNotification");
}
</script>

<template>
  <Teleport to="body">
    <Transition name="notify">
      <section
        v-if="notification"
        class="ui-message"
        :class="{
          'ui-message_error': notification.type === 'error',
          'ui-message_important': notification.type === 'important',
          'ui-message_success': notification.type === 'success',
        }"
      >
        <span
          :class="{
            'icon-warning-new': notification.type === 'error',
            'icon-link-fill': notification.type === 'important',
            'icon-success-fill': notification.type === 'success',
          }"
        />

        <div class="ui-message__info">
          <p v-if="notification.message">{{ notification.message }}</p>

          <h5 v-if="notification.status && notification.status.length > 0">
            {{ notification.status }}
          </h5>
        </div>

        <ButtonIcon
          v-if="notification.close"
          class="ui-message__close"
          @click="close"
        >
          <span class="icon-close" />
        </ButtonIcon>
      </section>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.ui-message {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 76px;
  z-index: 1200 !important;
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: 12px;
  border-radius: 8px;
  background: var(--color-semantic-background-normal-primary);
  box-shadow: 0px 12px 32px 0px rgba(33, 34, 36, 0.12);
  color: var(--color-semantic-content-normal-primary);
  overflow: hidden;
  width: max-content;
  max-width: 532px;

  &_error {
    background: var(--color-semantic-background-normal-negative);
    color: var(--color-semantic-content-normal-static-light);

    .icon-warning-new {
      background: var(--color-semantic-content-normal-static-light);
    }

    .icon-close {
      background: $new-light-fifth !important;
    }
  }

  &_important {
    align-items: center;

    .icon-link-fill {
      background: var(--color-semantic-content-normal-warning);
    }
  }

  &_success {
    align-items: center;

    .icon-success-fill {
      background: $other-green;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    > h5 {
      @include caption;
      height: fit-content;
      color: $new-light-fifth;
    }

    > p {
      @include text-2;
    }
  }

  &__close {
    padding: 0;
    height: fit-content;
  }
}

.icon-warning-new,
.icon-success-fill,
.icon-link-fill {
  min-width: 24px;
  height: 24px;
}

.icon-close {
  min-width: 20px;
  height: 20px;
  background: $new-light-sixth;
}

.notify-enter-active {
  animation: notify-show 150ms ease-out;
}

.notify-leave-active {
  animation: notify-show 150ms ease-out reverse;
}

@keyframes notify-show {
  0% {
    bottom: 0;
    opacity: 0;
  }

  100% {
    bottom: 76px;
    opacity: 1;
  }
}

@media (max-width: 1279px) {
  .ui-message {
    bottom: 48px;

    @keyframes notify-show {
      0% {
        bottom: 0;
        opacity: 0;
      }

      100% {
        bottom: 48px;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 767px) {
  .ui-message {
    width: 100%;
    max-width: calc(100% - 32px);
    bottom: 24px;

    @keyframes notify-show {
      0% {
        bottom: 0;
        opacity: 0;
      }

      100% {
        bottom: 24px;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 411px) {
  .ui-message {
    bottom: 24px;

    @keyframes notify-show {
      0% {
        bottom: 0;
        opacity: 0;
      }

      100% {
        bottom: 24px;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 374px) {
  .ui-message {
    max-width: calc(100% - 32px);
    bottom: 16px;

    @keyframes notify-show {
      0% {
        bottom: 0;
        opacity: 0;
      }

      100% {
        bottom: 16px;
        opacity: 0;
      }
    }
  }
}
</style>
