import index_header_notebook from "@/assets/images/index_header_notebook.png";
import rating from "@/assets/icons/rating.svg";
import desktop from "@/assets/icons/desktop.svg";
import chat from "@/assets/icons/chat.svg";
import monitoring_card_1 from "@/assets/images/monitoring_card_1.png";
import monitoring_card_2 from "@/assets/images/monitoring_card_2.png";
import monitoring_card_3 from "@/assets/images/monitoring_card_3.png";
import monitoring_item_1 from "@/assets/images/monitoring_item_1.png";
import monitoring_item_2 from "@/assets/images/monitoring_item_2.png";
import monitoring_item_3 from "@/assets/images/monitoring_item_3.png";

export default {
  HEADER: {
    moreButton: "Află mai multe",
    updates: "*Reînnoirea indicatorilor are loc la fiecare 24 de ore",
    title: "Готовая программа <br/> лояльности для вашего бизнеса",
    subTitle:
      "Личный кабинет с полной аналитикой, <br/> мобильным приложением и оперативной поддержкой",
    panels: {
      clients: "clienți",
      addRC: "a trecut RC pentru achiziții",
      tp: "puncte de vânzare cu amănuntul",
      city: "orașe acoperite",
      totalBuy: "numărul total de achiziții",
    },
  },
  clients: "Клиентам",
  franchising: "Франчайзи",
  btnRegister: "Регистрация",
  btnLogin: "Войти",
  title: "Готовая программа <br/> лояльности для вашего бизнеса",
  subTitle:
    "Личный кабинет с полной аналитикой, <br/> мобильным приложением и оперативной поддержкой",
  mainImage: index_header_notebook,
  staticTitle: "Собирайте полную статистику по вашему OOO или ИП",
  staticInfo: {
    title: "Больше чем просто удобная система",
    text:
      "Платформа автоматизирует процессы, необходимые современному бизнесу. Выбирайте то, что нужно именно вам. В несколько кликов адаптируйте под себя систему \n" +
      "и собранные о клиентах данные. Больше никаких сложностей и обходных путей — только корректные графики и реальные цифры.",
  },
  statisticCards: [
    {
      imagePath: rating,
      title: "Активность клиентов <br/> в графиках и цифрах",
      text: "Развернутая информация по текущим клиентам — <br/>удерживайте постоянных и привлекайте новых",
    },
    {
      imagePath: desktop,
      title:
        "Простые и доступные системы — <br/> мечта каждого предпринимателя",
      text:
        "Возможность работать с отзывами, чтобы увеличить доход, <br/> улучшить репутацию" +
        "и повысить лояльность клиентов",
    },
    {
      imagePath: chat,
      title: "Коммуникация с клиентами",
      text: "Сегментируйте по группам и создавайте <br/> PUSH-рассылки для точечного взаимодействия с клиентами",
    },
  ],
  sliderItems: [
    {
      icon: "icon-share",
      text: "Программа лояльности",
    },
    {
      icon: "icon-people",
      text: "Клиенты",
    },
    {
      icon: "icon-wallet",
      text: "Карты лояльности",
    },
    {
      icon: "icon-person",
      text: "Мои сотрудники",
    },
    {
      icon: "icon-tsp",
      text: "Мои предприятия ",
    },
    {
      icon: "icon-case",
      text: "Сервисы",
    },
    {
      icon: "icon-balance",
      text: "Processing",
    },
    {
      icon: "icon-share",
      text: "Программа лояльности",
    },
    {
      icon: "icon-people",
      text: "Клиенты",
    },
    {
      icon: "icon-wallet",
      text: "Карты лояльности",
    },
    {
      icon: "icon-person",
      text: "Мои сотрудники",
    },
    {
      icon: "icon-tsp",
      text: "Мои предприятия ",
    },
    {
      icon: "icon-case",
      text: "Сервисы",
    },
    {
      icon: "icon-balance",
      text: "Processing",
    },
  ],
  loyaltyList: {
    firstColumn: [
      {
        id: 1,
        icon: "icon-home",
        title: "Регистрируйтесь",
        text: "Мы ценим ваше время и готовы обеспечить эффективный старт вашей компании в мире лояльности",
      },

      {
        id: 2,
        icon: "icon-money-cents",
        title: "Принимайте баллы",
        text: "Использование баллов как формы оплаты помогает увеличить прибыль на 25% и выше",
      },

      {
        id: 3,
        icon: "icon-plus",
        title: "Добавляйте торговую точку",
        text: "Ключевой шаг для полноценного использования расширенного функционала кабинета",
      },

      {
        id: 4,
        icon: "icon-wallet",
        title: "Создавайте уникальные карты Wallet",
        text: "Настраивайте свой дизайн карты для клиентов. Изменяйте вид и содержание без перевыпуска и дополнительных трат",
      },

      {
        id: 5,
        icon: "icon-update",
        title: "Получайте повторные заказы",
        text: "Прозрачная система: 1 балл = 1 рубль. Клиенты, которые копят баллы чаще делают повторные покупки",
      },
    ],

    secondColumn: [
      {
        id: 6,
        icon: "icon-card",
        title: "Оцифровывайте",
        text: "Оцифровка клиентов – стратегический инструмент для увеличения доходности компании",
      },

      {
        id: 7,
        icon: "icon-moneybox",
        title: "Начисляйте вознаграждение",
        text: "Начисление баллов стимулирует совершать более крупные покупки, так как клиенты чувствуют что получают дополнительную ценность",
      },

      {
        id: 8,
        icon: "icon-connection",
        title: "Размещайтесь на rcpay.ru  и RC PAY",
        text: "Ваша компания присоединяется к платформе с огромным числом пользователей, которые ищут места для использования баллов",
      },

      {
        id: 9,
        icon: "icon-send",
        title: "Отправляйте push-уведомления",
        text: "Бесплатно сообщайте про акции, новинки и другие события. Стимулируйте покупки в периоды низкой активности",
      },
    ],
  },

  monitoringGlobalTitle: "Программа лояльности: зачем нужна и как внедрить",
  monitoringTitle: "Получать и анализировать информацию — легко",
  monitoringSubTitle:
    "Ведите учет новых и постоянных клиентов. Следите за уровнем продаж с помощью удобного графика. Получайте актуальные цифры по сумме продаж и среднему чеку, чтобы всегда быть в курсе самых свежих трендов рынка и быстро внедрять в работу современные решения. С RC CITY — это просто!",
  monitoringSecondTitle: "Удобный интерфейс для данных о вашей компании",
  monitoringCards: [
    {
      title: "Количество клиентов ",
      text: "Подсчет общего количества пришедших клиентов за выбранный период времени",
      imagePath: monitoring_card_1,
    },
    {
      title: "Просмотр транзакциий ",
      text:
        "Общая сумма продаж или средний чек\n" +
        "на каждую из ваших торговых точек",
      imagePath: monitoring_card_2,
    },
    {
      title: "Продажи",
      text: "Количество уникальных продаж \n" + "по вашей торговой точке",
      imagePath: monitoring_card_3,
    },
  ],
  monitoringItems: [
    {
      title: "Два простых шага для начала работы",
      text:
        "1. Введите ИНН и выберите тип организации. <br/>" +
        "2. Укажите Ф. И. О. руководителя, номер расчетного счета" +
        "и БИК банка — все нужные данные «подтянутся» сами." +
        "Минимум действий — максимум выгоды!",
      imagePath: monitoring_item_1,
    },
    {
      title:
        "Полная картина для эффективной <br/> и результативной работы бизнеса",
      text:
        "Настраивайте нужные позиции для вашей торговой точки, загружайте фотографии, указывайте объем вознаграждения \n" +
        "и скидок. Только ключевые позиции и понятный интерфейс — ничего лишнего.",
      imagePath: monitoring_item_2,
    },
    {
      title: "Важные документы — в одной папке",
      text:
        "Все, что может понадобиться для работы — договор, тарифы, политика конфиденциальности, маркетинговые материалы \n" +
        "и другое — уже здесь. В несколько кликов получайте доступ \n" +
        "к нужной информации. Нет бумажной волокиты — и все дела \n" +
        "в порядке.",
      imagePath: monitoring_item_3,
    },
  ],
  tariffGlobalTitle: "Доступна регистрация коммерческих {nko} юридических лиц",
  tariffsTitle: "Какой тариф подходит вам?",
  mostPopular: "Самое популярное",
  currency: "{currency} / год",
  tariffs: [
    {
      title: "Lite",
      subtitle: "Привлечение новой <br/> аудитории клиентов",
      buttonType: "black-new",
      buttonText: "Попробовать",
      additionalInfo: "",
      infoItems: [
        "Регистрация 1 организации",
        "Создание 5 торговых точек",
        "Упрощенная статистика",
        "Промо-материалы",
        "Доступ к RC KASSA",
        "Электронный документооборот",
        "Взаиморасчеты 2 раза в месяц",
        "FAQ",
      ],
    },
    {
      title: "Promo",
      subtitle: "Программа лояльности, <br/> адаптированная под ваш бизнес",
      buttonType: "gold",
      buttonText: "Купить",
      additionalInfo: "Доступно всё из тарифа Lite...",
      infoItems: [
        "Регистрация 15 организаций",
        "Создание 15 торговых точек",
        "Расширенная статистика",
        "Доступ к RC KASSA",
        "Push-рассылка для клиентов",
        "Функция обратной связи",
        "1000 лицензий для оцифровки клиентов",
        "Дизайн Wallet-карты",
        "Электронное меню",
      ],
    },
    {
      title: "Pro +",
      subtitle: "Для крупных компаний <br/> с филиалами",
      buttonType: "black-outline-new",
      buttonText: "Купить",
      additionalInfo: "Доступно всё из тарифов Lite, Promo...",
      infoItems: [
        "Регистрация 30 организаций",
        "Создание 30 торговых точек",
        "Расширенная статистика",
        "Push-рассылка для клиентов",
        "Функция обратной связи",
        "Дизайн Wallet-карты",
        "Неограниченное количество лицензий <br/> для оцифровки клиентов",
      ],
    },
  ],
  questionsTitle: "Вопросы и ответы",
  questions: [
    {
      title:
        "Поможет ли мне программа лояльности увеличить приток клиентов, если у меня барбершоп?",
      text:
        "Программа лояльности RC CITY это универсальное решение," +
        " оно подходит для любых сфер бизнеса. Бьюти-сегмент," +
        " к которому относятся всевозможные салоны красоты, парикмахерские и другое – направления," +
        " в которых можно успешно применять программу лояльности, как и в любых других.",
    },
    {
      title: "Что понадобится, чтобы запустить программу лояльности?",
      text:
        "ПК с выходом в интернет – для регистрации непосредственно программы лояльности" +
        " и пользования RC CITY." +
        " На смартфон на базе iOS или Android нужно будет скачать приложение RC KASSA," +
        " с его помощью сотрудники смогут сканировать QR-код из клиентского приложения RC PAY" +
        " на списание или начисление баллов.",
    },
    {
      title:
        "Как понять, какой балл вознаграждения установить для клиентов RC Group?",
      text:
        "Предложение должно быть выгодным для клиентов компании." +
        " Оптимальный размер бонусов –4-25% от стоимости товара или услуги.",
    },
    {
      title: "Могу ли я зарегистрировать в RC CITY 3 юридических лица?",
      text:
        "Да, сможете. В целом вы можете регистрировать столько юридических лиц, сколько вам необходимо." +
        " Тариф Промо включает возможность добавления до 15 юрлиц, Про+ – до 30-ти.",
    },
    {
      title:
        "У меня 10 торговых точек. Я могу получать статистику по каждой из них отдельно?",
      text:
        "Да, конечно. Функционал кабинета устроен таким образом," +
        " чтобы можно было сортировать информацию по каждой имеющейся у вас торговой точке." +
        " При необходимости можно смотреть статистику по всем торговым точкам сразу.",
    },
    {
      title: "Из каких именно позиций складывается портрет клиента?",
      text:
        "Возраст, процентное соотношение мужчин и женщин среди клиентов, средний чек," +
        " количество клиентов в этом возрастном сегменте, количество и сумма продаж," +
        " процентное соотношение клиентов, которые не указали пол.",
    },
    {
      title: "Есть ли смысл повышать уровень тарифного плана?",
      text:
        "Безусловно. При апгрейде вы получите расширенный функционал с более полной статистикой по клиентам," +
        " с возможностью добавлять больше точек и юрлиц в рамках одного кабинета и другими бонусами.\n" +
        "А в некоторых случаях например, если у вас более пяти торговых точек, начинать с тарифа Промо необходимо.",
    },
    {
      title: "В чем основные плюсы приложения RC KASSA?",
      text:
        "С помощью приложения вы можете списывать и начислять баллы клиентам," +
        " работать с функционалом Wallet-карт (оцифровывать клиентов, собирать статистику операций сотрудников)," +
        " отслеживать историю этих операций," +
        " просматривать информацию по акциям конкретного заведения в отдельной вкладке.",
    },
    {
      title:
        "Как push-уведомления помогут в увеличении дохода ресторана быстрого питания?",
      text:
        "Push-уведомления это на сегодня самый быстрый способ коммуникации с клиентом." +
        " Он не влияет напрямую на повышение продаж, но косвенно," +
        " за счет повышения лояльности и вовлеченности в процесс, работает отлично." +
        " В работе ресторана быстрого питания такие уведомления можно использовать," +
        " чтобы сообщать о текущих скидках, старте акции, о выгодных предложениях и т.д.",
    },
  ],
  footer: {
    juristicDocs: "Юридические документы",
    contacts: "Контакты",
    tariffs: "Тарифы",
    support: "Служба поддержки",
    privacy: "Политика конфиденциальности",
    rewards: "Сценарии распределения вознаграждения",
    rcMark:
      "<p>" +
      "<span>RC Group</span> является товарным знаком, DBA и торговой маркой и/или знаком обслуживания <br>" +
      'Общество с ограниченной ответственностью "РК-ТЕХНОЛОГИИ"' +
      '(ООО "РК-ТЕХНОЛОГИИ") ИНН 7806578914; ОГРН 1207800157256. <br>' +
      "Несанкционированное копирование или использование " +
      "без предварительного письменного согласия <span>запрещено</span>." +
      "</p>",
  },
};
