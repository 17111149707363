import { API } from "@/boot/api";

let lang = localStorage.getItem("selected-language");

if (lang && lang.length > 2) {
  lang = lang.substring(0, 2);
}

const state = {
  percentCompletedHalal: 0,
  uploadingStatusHalal: "",
  percentCompletedRegulation: 0,
  uploadingStatusRegulation: "",
  percentCompletedAcceptance: 0,
  uploadingStatusAcceptance: "",
  haveOkved: true,

  entity: {
    inn: "",
    name: "",
    is_halal: 0,
    address: "",
    head_name: "",
    meta: {
      name_of_bank: "",
      bik_of_bank: "",
      corresp_account: "",
      number_account: "",
      vat_code: "",
      international_bank: "",
      deposit_sum: "",
    },
    legal_type: "",
    tax_system: "",
    monthly_turnover: "",
    ogrn: "",
    okved_code: "",
    documents: [],
    do_business: false,
    organization_type: "commercial",
  },

  profile: {
    promocode: "",
    country_code: "",
    phone: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    password: "",
  },
};

const getters = {};

const actions = {
  sendRegistrationMainInfo(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("auth/pre-registration", payload, {
        headers: { "Accept-Language": lang },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendRegistration(_, data) {
    return new Promise((resolve, reject) => {
      API.post("auth/register", data.payload, {
        headers: {
          "Accept-Language": lang,
          "X-Idempotency-Key": data.uuid,
        },
      })
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadDocument({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("document_type", payload.document_type);

      commit("SET_STATUS_UPLOAD", {
        status: "UPLOADING",
        type: payload.document_type,
      });

      API.post("account/documents", formData, {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          commit("SET_UPLOAD_PROGRESS", {
            percent: percentCompleted,
            type: payload.document_type,
          });
        },
      })
        .then((response) => {
          commit("SET_STATUS_UPLOAD", {
            status: "SUCCESS",
            type: payload.document_type,
          });
          resolve(response);
        })
        .catch((error) => {
          commit("SET_STATUS_UPLOAD", {
            status: "ERROR",
            type: payload.document_type,
          });
          reject(error);
        });
    });
  },

  uploadAccept(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`entities/${data.entity_id}/acceptance/attach`, {
        document_id: data.document_id,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_INN_ENTITY(state, newInfo) {
    for (const key in newInfo) {
      if (Object.keys(state.entity).includes(key)) {
        state.entity[key] = newInfo[key] ?? "";
      }
    }
  },

  SET_INN_META(state, newInfo) {
    for (const key in newInfo) {
      if (Object.keys(state.entity.meta).includes(key)) {
        state.entity.meta[key] = newInfo[key] ?? "";
      }
    }
  },

  SET_PROFILE(state, newInfo) {
    for (const key in newInfo) {
      if (Object.keys(state.profile).includes(key)) {
        state.profile[key] = newInfo[key] ?? "";
      }
    }
  },

  SET_STATUS_UPLOAD(state, data) {
    if (data.type === "certificate") {
      state.uploadingStatusHalal = data.status;
    } else if (data.type === "alternative-acceptance") {
      state.uploadingStatusAcceptance = data.status;
    } else {
      state.uploadingStatusRegulation = data.status;
    }
  },

  SET_UPLOAD_PROGRESS(state, data) {
    if (data.type === "certificate") {
      state.percentCompletedHalal = data.percent;
    } else if (data.type === "alternative-acceptance") {
      state.percentCompletedAcceptance = data.percent;
    } else {
      state.percentCompletedRegulation = data.percent;
    }
  },

  SET_HAVE_OKVED(state, value) {
    state.haveOkved = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
