export const balanceRo = {
  title: "Sold",
  chose: "Alegeți organizația pentru suplinire",
  enterSum: "Introduce suma",
  confirmSum: "Pe suma dată va fi generat o factură",
  choseOrganization: "Organizația de facturare",
  bill: "Emitere factură",
  billOk: "Factura este emisă cu succes",
  downloadBill: "Descărcare factură",
};
