<script>
export default {
  name: "ButtonIcon",

  emits: ["click", "mouseenter", "mouseleave"],

  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "button",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <button
    class="button-icon"
    :disabled="isDisable || disabled"
    :type="type"
    @click="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
.button-icon {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;

  :deep(p) {
    cursor: pointer !important;
  }

  &:disabled {
    cursor: not-allowed !important;
  }
}
</style>
