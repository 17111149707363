export default function declensionWord(amount, words) {
  let count = amount % 100;
  if (count >= 5 && count <= 20) {
    return amount + words[0];
  }

  count = amount % 10;
  if (count === 1) {
    return amount + words[1];
  } else if (count > 1 && count < 5) {
    return amount + words[2];
  } else {
    return amount + words[0];
  }
}

// TODO: заменить названия
export function declensionWordText(amount, words) {
  let count = amount % 100;
  if (count >= 5 && count <= 20) {
    return words[0];
  }

  count = amount % 10;
  if (count === 1) {
    return words[1];
  } else if (count > 1 && count < 5) {
    return words[2];
  } else {
    return words[0];
  }
}
