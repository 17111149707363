import declensionWord from "@/package/global-helpers/declinationHelper";
import { i18n } from "@/boot/i18n";
import { API } from "@/boot/api";

const state = {
  claims: [],
  historyClaims: [],
  clients: null,
  ageOfClients: null,
  clientsList: [],
  clientsListMeta: null,
  clientInfo: null,
  clientStats: null,
  clientVisits: [],
  clientVisitsMeta: null,
  clientReceipts: [],
  clientReceiptsMeta: null,
  clientReviews: [],
  clientReviewsMeta: null,
};

const getters = {};

const mutations = {
  SET_CLAIMS: (state, claims) => {
    state.claims = claims;
  },

  SET_DATA_FOR_GRAF: (state, claims) => {
    if (claims.length === 1) {
      state.clients = claims;
      state.clients.unshift({ age: 18 });
      state.clients.push({ age: 100 });
      state.clients.push({
        customer_count: state.clients[1].customer_count * 2,
      });
    } else {
      state.clients = claims;
    }
  },

  SET_CLIENT_AGE: (state, claims) => {
    if (claims.length === 1) {
      state.ageOfClients = claims;
      state.ageOfClients.unshift(18);
      state.ageOfClients.push(100);
    } else {
      state.ageOfClients = claims;
    }
    state.ageOfClients = state.ageOfClients.map((c) =>
      declensionWord(c, i18n.global.tm("declinationHelperWords.years"))
    );
  },

  SET_CLIENTS_LIST(state, clientsList) {
    state.clientsList = clientsList;
  },

  SET_CLIENTS_LIST_META(state, clientsListMeta) {
    state.clientsListMeta = clientsListMeta;
  },

  SET_CLIENT_INFO(state, clientInfo) {
    state.clientInfo = clientInfo;
  },

  SET_CLIENT_STATS(state, clientStats) {
    state.clientStats = clientStats;
  },

  SET_CLIENT_VISITS(state, clientVisits) {
    state.clientVisits = clientVisits;
  },

  SET_CLIENT_VISITS_META(state, clientVisitsMeta) {
    state.clientVisitsMeta = clientVisitsMeta;
  },

  SET_CLIENT_RECEIPTS(state, response) {
    if (
      (state.clientReceiptsMeta &&
        state.clientReceiptsMeta.clientId !== response.id) ||
      response.isRefresh ||
      !response.payload.cursor
    ) {
      state.clientReceipts = [];
    }

    state.clientReceiptsMeta = response.meta;
    state.clientReceiptsMeta.clientId = response.id;
    state.clientReceipts = state.clientReceipts.concat(response.clientReceipts);
  },

  SET_CLIENT_REVIEWS(state, response) {
    if (
      (state.clientReviewsMeta &&
        state.clientReviewsMeta.clientId !== response.id) ||
      response.isRefresh ||
      !response.payload.cursor
    ) {
      state.clientReviews = [];
    }

    state.clientReviewsMeta = response.meta;
    state.clientReviewsMeta.clientId = response.id;
    state.clientReviews = state.clientReviews.concat(response.clientReview);
  },
};

const actions = {
  claims({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`account/${data.id}/claims`, {
        params: {
          type: data.type,
          page: data.page,
          select_all: data.select_all,
          point_id: data.point_id,
          date_start: data.date_start,
          date_finish: data.date_finish,
        },
      })
        .then((response) => {
          commit("SET_CLAIMS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  allShopPoints() {
    return new Promise((resolve, reject) => {
      API.get("account/points")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  clientPortrait({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/client/portrait", {
        params: {
          point_ids: data.point_ids,
        },
      })
        .then((response) => {
          commit(
            "SET_CLIENT_AGE",
            response.data.data.map((d) => d.age)
          );
          commit("SET_DATA_FOR_GRAF", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadClientsList({ commit }, params) {
    return new Promise((resolve, reject) => {
      API.post("customer", params)
        .then((response) => {
          commit("SET_CLIENTS_LIST", response.data.data);
          commit("SET_CLIENTS_LIST_META", response.data.meta);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadClientInfo({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`customer/${id}`)
        .then((response) => {
          commit("SET_CLIENT_INFO", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendClientNote(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`customer/${data.id}/note`, { text: data.note })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadClientStats({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`customer/${id}/stats`)
        .then((response) => {
          commit("SET_CLIENT_STATS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadClientVisits({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`customer/${id}/visits`)
        .then((response) => {
          commit("SET_CLIENT_VISITS", response.data.data);
          commit("SET_CLIENT_VISITS_META", response.data.meta);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadReceipts({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`customer/${data.id}/receipts`, data.payload)
        .then((response) => {
          commit("SET_CLIENT_RECEIPTS", {
            clientReceipts: response.data.data,
            meta: response.data.meta,
            id: data.id,
            isRefresh: data.isRefresh,
            payload: data.payload,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadReviews({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`customer/${data.id}/reviews`, data.payload)
        .then((response) => {
          commit("SET_CLIENT_REVIEWS", {
            clientReview: response.data.data,
            meta: response.data.meta,
            id: data.id,
            isRefresh: data.isRefresh,
            payload: data.payload,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
