import { API } from "@/boot/api";

const state = {
  groups: [],
};

const getters = {};

const mutations = {
  SET_GROUPS: (state, groups) => {
    state.groups = groups;
  },
};

const actions = {
  getGroupsByTradingPoint({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("points/groups/", {
        params: {
          type: "customer",
          point_ids: data.point_id,
          page: data.page,
        },
      })
        .then((response) => {
          commit("SET_GROUPS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateGroup(_, data) {
    return new Promise((resolve, reject) => {
      API.patch(`groups/${data.id}`, {
        amount: data.amount,
        name: data.name,
        cashback_percent: data.cashback_percent,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteGroup(_, id) {
    return new Promise((resolve, reject) => {
      API.delete(`groups/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createGroup(_, data) {
    return new Promise((resolve, reject) => {
      API.post("groups", {
        name: data.name,
        amount: data.amount,
        cashback_percent: data.cashback_percent,
        points: data.points,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
