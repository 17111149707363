import { i18n } from "@/boot/i18n";
import { API } from "@/boot/api";

const state = {
  balance: 0,
  servicesList: [],
  tradingPoints: [],
  history: [],
};

const getters = {};

const actions = {
  loadPromotionServicesList({ commit }, point_id) {
    return new Promise((resolve, reject) => {
      API.get(`promotions/trading-point/${point_id}`)
        .then((response) => {
          commit(
            "SET_BALANCE",
            response.data.data.available_for_withdraw_balance
          );
          commit("SET_SERVICES_LIST", response.data.data.promotions);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTradingPoints({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("promotions/trading-point/list")
        .then((response) => {
          commit("SET_TRADING_POINTS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPromotionsHistory({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("promotions/trading-point/history")
        .then((response) => {
          commit("SET_PROMOTION_SERVICES_HISTORY", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createOrder(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("promotions/trading-point", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_BALANCE: (state, balance) => {
    state.balance = balance;
  },

  SET_SERVICES_LIST: (state, servicesList) => {
    state.servicesList = servicesList.map((service) => ({
      available_for_buy: service.available_for_buy,
      near_available_date: service.near_available_date,
      periods: service.periods.map((period, index) => ({
        interval: period.interval,
        name: i18n.global.t(`promotionServices.periodsList.${period.interval}`),
        price: period.price,
        priceValue: `• ${period.price} RC`,
        id: index,
        days: Number(period.interval.split(" ")[0]),
      })),
      id: service.type,
      type: service.type,
      name: i18n.global.t(
        `promotionServices.servicesList.${service.type}.name`
      ),
    }));
  },

  SET_TRADING_POINTS: (state, tradingPoints) => {
    state.tradingPoints = tradingPoints;
  },

  SET_PROMOTION_SERVICES_HISTORY: (state, history) => {
    state.history = history;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
