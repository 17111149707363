<script lang="ts" setup>
import { computed, PropType, ref, useSlots, watch } from "vue";
import { useWindowSize, useScrollLock } from "@vueuse/core";
import UiBottomSheet from "@/ui/UiBottomSheet/UiBottomSheet.vue";
import { IconCloseThin } from "@packages/icon-library";
import { ModalTypes } from "@/ui/UiModal/types";

defineOptions({
  name: "UiModal",
});

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },

  size: {
    type: String as PropType<ModalTypes>,
    default: "m",
  },

  title: {
    type: String,
    default: "",
  },

  subTitle: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue", "initRef"]);

const _modelValue = computed({
  get() {
    return props.modelValue;
  },

  set(newValue) {
    emit("update:modelValue", newValue);
  },
});

const isNotMobile = computed(() => width.value > 767);

const slots = useSlots();

const el = document.body;

const lockBodyScroll = useScrollLock(el);

const target = ref<HTMLElement | null>(null);

const { width } = useWindowSize();

const closeModal = () => {
  _modelValue.value = false;
};

const openListener = () => {
  document.addEventListener("keyup", function (evt) {
    if (evt.keyCode === 27) {
      closeModal();
    }
  });
};

const closeListener = () => {
  document.removeEventListener("keyup", function (evt) {
    if (evt.keyCode === 27) {
      closeModal();
    }
  });
};

watch(
  () => props.modelValue,
  (newValue) => {
    lockBodyScroll.value = newValue;

    if (newValue) {
      openListener();
    } else {
      closeListener();
    }
  }
);
</script>

<template>
  <Teleport v-if="isNotMobile" to="body">
    <Transition name="modal">
      <div v-if="modelValue" class="ui-modal light-elevation-shadow-low">
        <div class="ui-modal__close" @click="closeModal"></div>
        <div
          ref="target"
          class="ui-modal__modal-container modal-container"
          :class="[`modal-container__size_${size}`]"
        >
          <button type="button" class="close-button" @click="closeModal">
            <IconCloseThin />
          </button>

          <div class="modal-container__header">
            <slot name="title">
              <h4 v-if="title.trim()" class="title">{{ title }}</h4>
            </slot>

            <slot name="subTitle">
              <p v-if="subTitle.trim()" class="sub-title">{{ subTitle }}</p>
            </slot>
          </div>

          <div v-if="slots.body" class="modal-container__content">
            <slot name="body" />
          </div>

          <div v-if="slots.footer" class="modal-container__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>

  <UiBottomSheet
    v-else
    v-model="_modelValue"
    :title="title"
    :subtitle="subTitle"
  >
    <template v-if="slots['title']" #title>
      <slot name="title" />
    </template>

    <template v-if="slots['subTitle']" #subTitle>
      <slot name="subTitle" />
    </template>

    <template v-if="slots.body" #body>
      <slot name="body" />
    </template>

    <template v-if="slots.footer" #footer>
      <slot name="footer" />
    </template>
  </UiBottomSheet>
</template>

<style scoped lang="scss">
.ui-modal {
  background: var(--color-semantic-background-normal-overlay);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  transition: opacity 0.3s ease;

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 1;
  }

  .modal-container {
    margin: auto;
    border-radius: 12px;
    overflow: hidden;
    background: var(--color-semantic-background-normal-bg-primary);
    padding: 32px 32px 16px 32px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    max-height: calc(100vh - 96px);
    opacity: 1;
    position: relative;
    transition: all 0.3s ease;
    z-index: 2;

    .close-button {
      position: absolute;
      z-index: 1;
      width: 32px;
      height: 32px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      right: 16px;
      top: 16px;
      cursor: pointer;

      > span {
        width: 32px;
        height: 32px;
        background: var(--color-semantic-content-normal-tertiary);
        transition: background 0.15s ease-out;
      }

      @media (hover: hover) {
        &:hover {
          > span {
            background: var(--color-semantic-content-hover-primary);
          }
        }

        &:active {
          > span {
            background: var(--color-semantic-content-normal-tertiary);
          }
        }
      }

      @media (hover: none) {
        &:active {
          > span {
            background: var(--color-semantic-content-hover-primary);
          }
        }
      }
    }

    &__size {
      &_m {
        width: 600px;
      }

      &_s {
        width: 400px;
      }
    }

    &__header {
      padding-bottom: 16px;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 12px;

      :deep(h4),
      .title {
        width: calc(100% - 32px);
        @include title-3;
        color: var(--color-semantic-content-normal-primary);
      }

      :deep(p),
      .sub-title {
        @include body;
        color: var(--color-semantic-content-normal-secondary);
      }
    }

    &__content {
      padding: 4px 0 16px 0;
      overflow: auto;
      overscroll-behavior: contain;
      display: flex;
      flex-direction: column;
      gap: 4px;
      @include scrollbar;

      &:deep(> p) {
        @include body;
        color: var(--color-semantic-content-normal-secondary);
      }
    }

    &__footer {
      padding: 12px 0 16px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 1279px) {
  .ui-modal {
    padding: 24px;

    .modal-container {
      max-height: 680px;
    }
  }
}
</style>
