const MODALS_WITH_IMAGE = [
  "tt-update",
  "product-release",
  "review-update-release",
];

export function checkModalType(type) {
  return MODALS_WITH_IMAGE.includes(type);
}

export function switchRedirect(pageType) {
  switch (pageType) {
    case "clients-update":
      return { name: "Clients" };
    case "tt-update":
      return { name: "TradingPoint" };
    case "product-release":
      return { name: "Emenu" };
    case "review-update-release":
      return { name: "Reviews" };
    default:
      break;
  }
}
