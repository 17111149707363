import { API } from "@/boot/api";

const state = {
  productsList: [],
  productsListMeta: null,
  tradingPointsWithProducts: [],
  dimensionsList: [],
  categoriesList: [],
  categoriesListMeta: null,
  productForCategory: [],
  filterCategoriesList: [],
  createCategoriesList: [],
  updateCategoriesList: [],
};

const getters = {};

const actions = {
  loadFilterCategoriesList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get("products/categories", {
        params: payload,
      })
        .then((response) => {
          switch (payload.type) {
            case "filter":
              commit("SET_FILTERS_CATEGORIES", response.data.data);
              break;
            case "update":
              commit("SET_UPDATE_CATEGORIES", response.data.data);
              break;
            case "create":
              commit("SET_CREATE_CATEGORIES", response.data.data);
              break;
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadProductsList({ commit }, params) {
    return new Promise((resolve, reject) => {
      API.post("products/index", params)
        .then((response) => {
          commit("SET_PRODUCTS_LIST", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createProduct(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("products", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editProduct(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(`products/${payload.id}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  hideProduct(_, id) {
    return new Promise((resolve, reject) => {
      API.post(`products/${id}/hide`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  revealProduct(_, id) {
    return new Promise((resolve, reject) => {
      API.post(`products/${id}/reveal`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteProduct(_, id) {
    return new Promise((resolve, reject) => {
      API.delete(`products/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTradingPointsWithProducts({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("account/points/have-products")
        .then((response) => {
          commit("SET_TRADING_POINTS_WITH_PRODUCTS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTradingPointsLink(_, id) {
    return new Promise((resolve) => {
      API.get(`points/${id}/client-menu`).then((response) => {
        resolve(response);
      });
    });
  },

  loadDimensionsList({ commit }, search) {
    return new Promise((resolve, reject) => {
      API.get("products/dimensions", {
        params: {
          search: search,
        },
      })
        .then((response) => {
          commit("SET_DIMENSIONS_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCategoriesList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.post("products/custom-categories/index", payload)
        .then((response) => {
          commit("SET_CATEGORIES_LIST", response.data.data);
          commit("SET_CATEGORIES_LIST_META", response.data.meta);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadProductsForCategory({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`products/custom-categories/${id}/products`)
        .then((response) => {
          commit("SET_PRODUCTS_FOR_CATEGORY", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createCategory(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("products/custom-categories", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateCategory(_, payload) {
    return new Promise((resolve, reject) => {
      API.patch(`products/custom-categories/${payload.id}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteCategory(_, id) {
    return new Promise((resolve, reject) => {
      API.delete(`products/custom-categories/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getProductFromId(_, id) {
    return new Promise((resolve, reject) => {
      API.get(`products/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_PRODUCTS_LIST(state, response) {
    state.productsList = response.data;
    state.productsListMeta = response.meta;
  },

  SET_TRADING_POINTS_WITH_PRODUCTS(state, points) {
    state.tradingPointsWithProducts = points;
  },

  SET_DIMENSIONS_LIST(state, dimensionsList) {
    state.dimensionsList = dimensionsList;
  },

  SET_CATEGORIES_LIST(state, categoriesList) {
    state.categoriesList = categoriesList;
  },

  SET_CATEGORIES_LIST_META(state, categoriesListMeta) {
    state.categoriesListMeta = categoriesListMeta;
  },

  SET_PRODUCTS_FOR_CATEGORY(state, productForCategory) {
    state.productForCategory = productForCategory;
  },

  SET_FILTERS_CATEGORIES(state, filterCategoriesList) {
    state.filterCategoriesList = [
      { id: "System", title: "Основные", items: [] },
      { id: "Confirmation", title: "Подтвержденные", items: [] },
      { id: "Moderation", title: "На модерации", items: [] },
      { id: "Reject", title: "Отклоненные", items: [] },
    ];

    filterCategoriesList.forEach((category) => {
      state.filterCategoriesList[
        state.filterCategoriesList.findIndex(
          (categoryType) => categoryType.id === category.type
        )
      ].items.push(category);
    });

    state.filterCategoriesList = state.filterCategoriesList.filter(
      (list) => list.items.length > 0
    );
  },

  SET_UPDATE_CATEGORIES(state, updateCategoriesList) {
    state.updateCategoriesList = [
      { id: "System", title: "Основные", items: [] },
      { id: "Confirmation", title: "Подтвержденные", items: [] },
      { id: "Moderation", title: "На модерации", items: [] },
      { id: "Reject", title: "Отклоненные", items: [] },
    ];

    updateCategoriesList.forEach((category) => {
      state.updateCategoriesList[
        state.updateCategoriesList.findIndex(
          (categoryType) => categoryType.id === category.type
        )
      ].items.push(category);
    });

    state.updateCategoriesList = state.updateCategoriesList.filter(
      (list) => list.items.length > 0
    );
  },

  SET_CREATE_CATEGORIES(state, createCategoriesList) {
    state.createCategoriesList = createCategoriesList;

    state.createCategoriesList = [
      { id: "System", title: "Основные", items: [] },
      { id: "Confirmation", title: "Подтвержденные", items: [] },
      { id: "Moderation", title: "На модерации", items: [] },
      { id: "Reject", title: "Отклоненные", items: [] },
    ];

    createCategoriesList.forEach((category) => {
      state.createCategoriesList[
        state.createCategoriesList.findIndex(
          (categoryType) => categoryType.id === category.type
        )
      ].items.push(category);
    });

    state.createCategoriesList = state.createCategoriesList.filter(
      (list) => list.items.length > 0
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
