import { API } from "@/boot/api";

const state = {
  invoices: [],
  acts: [],
  maxPage: null,

  operations: [],
  operationsLastPage: 1,
};

const getters = {};

const mutations = {
  SET_INVOICES: (state, payload) => {
    state.invoices = payload;
  },
  SET_ACTS: (state, payload) => {
    state.acts = payload;
  },
  SET_MAX_PAGE: (state, payload) => {
    state.maxPage = payload;
  },

  SET_OPERATIONS(state, payload) {
    state.operations = payload;
  },

  SET_OPERATIONS_LAST_PAGE(state, payload) {
    state.operationsLastPage = payload;
  },
};

const actions = {
  getBlockOperations(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`entities/${data.entity_id}/blocked`, {
        params: {
          page: data.page,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createAct(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`entities/${data.entity}/act`, {
        params: {
          page: data.page,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getActs({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`entities/${data.entity_id}/acts`, {
        params: {
          page: data.page,
          act_type: data.act_type,
        },
      })
        .then((response) => {
          commit("SET_ACTS", response.data.data);
          commit("SET_MAX_PAGE", response.data.meta.last_page);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getChecksAct({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`act/${data.actId}/operations`, {
        params: {
          page: data.page,
          order_type: data.orderType,
          order_field: data.orderField,
        },
      })
        .then((response) => {
          commit("SET_OPERATIONS", response.data.data);
          commit("SET_OPERATIONS_LAST_PAGE", response.data.meta.last_page);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getInvoices({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`entities/${data.entity}/invoices`, {
        params: {
          page: data.page,
        },
      })
        .then((response) => {
          commit("SET_INVOICES", response.data.data);
          commit("SET_MAX_PAGE", response.data.meta.last_page);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createCheck(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`act/${data.act}/invoice`, {})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getActById(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`act/${data.actId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadAct(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`act/${data.act}/download`, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "act.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCustomOperations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.post("act/custom-operations", {
        page: payload.page,
        date_start: payload.dateStart,
        date_finish: payload.dateFinish,
        order_field: payload.orderField,
        order_type: payload.orderType,
        entity_id: payload.entityId,
        act_type: payload.act_type,
      })
        .then((response) => {
          commit("SET_OPERATIONS", response.data.data);
          commit("SET_OPERATIONS_LAST_PAGE", response.data.meta.last_page);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadCustomAct(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        "act/custom-download",
        {
          entity_id: data.entity_id,
          date_start: data.date_start,
          date_finish: data.date_finish,
          act_type: data.act_type,
        },
        {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        }
      )
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
