import { defineStore } from "pinia";
import { i18n } from "@/boot/i18n";
import { TLang } from "@/package/global-types";

const i18nLocal = i18n;
i18nLocal.global.locale.value =
  (localStorage.getItem("selected-language") as TLang) || "ru";

export const translateStore = defineStore("translate-store", {
  state: () => ({
    t: i18nLocal.global.t,
  }),
});
