import { settings } from "@/boot/api";
import axios from "axios";
import { API } from "@/boot/api";

const state = {
  tags: [],
  shops: [],
  options: [],
  activeWait: 0,
  tradingPoints: [],
  geocoderResponse: [],
  availableLinks: null,
  tradingPoint: null,
  invitesList: [],
  activities: [],
};
const getters = {};
const mutations = {
  SET_TAGS: (state, tags) => {
    state.tags = tags;
  },

  SET_SHOPS: (state, shops) => {
    state.shops = shops;
  },

  SET_OPTIONS: (state, options) => {
    state.options = options;
  },

  SET_GEOCODER_RESPONSE(state, payload) {
    state.geocoderResponse = payload;
  },

  SET_TRADING_POINTS: (state, tradingPoints) => {
    state.tradingPoints = tradingPoints;
  },

  SET_AVAILABLE_LINKS: (state, availableLinks) => {
    state.availableLinks = availableLinks;
  },

  SET_TRADING_POINT: (state, tradingPoint) => {
    state.tradingPoint = tradingPoint;
  },

  SET_INVITES_LIST: (state, invitesList) => {
    state.invitesList = invitesList;
  },

  SET_ACTIVITIES: (state, activities) => {
    state.activities = activities;
  },
};
const actions = {
  getCustomer(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`points/${data.point}/customers`, {
        params: {
          name: data.name,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addNewTags(_, data) {
    return new Promise((resolve, reject) => {
      API.post("tags", {
        name: data.name,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getShops({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("account/points", {
        params: {
          page: data.page,
          order_type: data.order_type,
          order_field: data.order_field,
        },
      })
        .then((response) => {
          commit("SET_SHOPS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateActiveWait(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`points/${data.point}/active-wait`, {
        active_wait: data.active_wait,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getShopById(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`points/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  closeShop(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`points/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getShopHistory(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`points/${data.id}/receipts`, data.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getTags({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("tags", data)
        .then((response) => {
          commit("SET_TAGS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getOptions({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("options", data)
        .then((response) => {
          commit("SET_OPTIONS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendFiles(_, data) {
    const formData = new FormData();

    formData.append("point_id", data.point_id);

    for (let i = 0; i < data.files.length; i++) {
      if (data.files[i].size <= 10485760) {
        const file = data.files[i];
        formData.append("file[" + i + "]", file);
      }
    }

    return new Promise((resolve, reject) => {
      API.post("points/store/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendShop(_, data) {
    return new Promise((resolve, reject) => {
      API.post("points", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendShopTranslate(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`points/${data.point}/translate`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateShop(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`points/${data.id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateShopTranslate(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`points/${data.point}/translate`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteShop(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`points/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  lockShop(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`points/${data.point}/blocked`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteImage(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`images/destroy/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createNotification(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`points/${data.id}/notification`, { message: data.message })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  geocoderRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${settings.apiKey}&geocode=${payload}`
        )
        .then((response) => {
          commit(
            "SET_GEOCODER_RESPONSE",
            response.data.response.GeoObjectCollection.featureMember
          );

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Список полной информации о ТТ, используется на странице ТТ для таблицы
  loadTradingPoints({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post("points/general", data)
        .then((response) => {
          commit("SET_TRADING_POINTS", response.data.data ?? []);
          resolve(response);
        })
        .catch((error) => {
          if (error.status === 204) {
            commit("SET_TRADING_POINTS", []);
          }
          reject(error);
        });
    });
  },

  createTradingPoint(_, data) {
    return new Promise((resolve, reject) => {
      API.post("points", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadAvailableLinksType({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("links/available")
        .then((response) => {
          commit("SET_AVAILABLE_LINKS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTradingPoint({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`points/${id}`)
        .then((response) => {
          commit("SET_TRADING_POINT", response.data.point);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editTradingPoint(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`points/${data.id}`, data.formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadInvitationsList({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("points/invitations")
        .then((response) => {
          commit("SET_INVITES_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getActivity({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("categories")
        .then((response) => {
          commit("SET_ACTIVITIES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
