<script setup>
import { computed, onMounted, ref, useSlots, watch } from "vue";
import {
  useScrollLock,
  useWindowSize,
  useSwipe,
  useScroll,
} from "@vueuse/core";
import { IconCloseThin } from "@packages/icon-library";
import { hideContactWidget } from "@/package/helpers/contactWidget";

defineOptions({
  name: "UiBottomSheet",
});

const emit = defineEmits(["update:modelValue", "initRef"]);

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },

  filters: {
    type: Boolean,
    default: false,
  },

  showCloseButton: {
    type: Boolean,
    default: true,
  },

  title: {
    type: String,
    default: "",
  },

  subtitle: {
    type: String,
    default: "",
  },

  bodyStyle: {
    type: Object,
    default: () => ({}),
  },
});

const swipeBlock = ref(null);
const content = ref(null);
const bottom = ref("0");
const opacity = ref(1);
const body = ref(document.body);
const html = ref(document.html);
const lockBodyScrollBody = useScrollLock(body);
const lockBodyScrollHtml = useScrollLock(html);
const { width, height } = useWindowSize();
const { isScrolling } = useScroll(content);

const { lengthY, direction } = useSwipe(swipeBlock, {
  passive: true,

  onSwipe() {
    if (direction.value === "down" && !isScrolling.value) {
      const distance = Math.abs(lengthY.value);
      bottom.value = `-${distance}px`;
      opacity.value = 1.25 - distance / height.value;
    } else {
      bottom.value = "0";
      opacity.value = 1;
    }
  },

  onSwipeEnd() {
    if (isScrolling.value || direction.value !== "down") {
      return;
    }

    if (lengthY.value < 200) {
      bottom.value = "0";
      opacity.value = 1;
      emit("update:modelValue", false);
    } else {
      bottom.value = "0";
      opacity.value = 1;
    }
  },
});

watch(
  () => props.modelValue,
  (newValue) => {
    hideContactWidget(newValue);

    if (newValue) {
      lockBodyScrollBody.value = true;
      lockBodyScrollHtml.value = true;
      body.value.style["touch-action"] = "none";
    } else {
      lockBodyScrollBody.value = false;
      lockBodyScrollHtml.value = false;
      body.value.style["touch-action"] = "auto";
    }
  }
);

watch(width, (newValue) => {
  if (newValue > 767) {
    emit("update:modelValue", false);
  }

  if (props.modelValue) {
    hideContactWidget(true);
  }
});

const slots = useSlots();

const isShowHeader = computed(
  () =>
    props.title.trim() || props.subtitle.trim() || slots.title || slots.subtitle
);

const clickOutSide = () => {
  emit("update:modelValue", false);
};

onMounted(() => {
  if (props.modelValue) {
    hideContactWidget(true);
  }
});
</script>

<template>
  <Teleport to="body">
    <Transition name="bottom-sheet">
      <div
        v-if="modelValue"
        :ref="
          (e) => {
            $emit('initRef', e);
          }
        "
        class="ui-bottom-sheet"
        :class="{ 'ui-bottom-sheet_filters': props.filters }"
      >
        <div class="bottom-sheet-container__close" @click="clickOutSide"></div>
        <div
          v-if="modelValue"
          :style="{
            bottom: bottom,
            opacity: opacity,
          }"
          class="ui-bottom-sheet__bottom-sheet-container bottom-sheet-container"
        >
          <div ref="swipeBlock" class="swipe-block" />

          <button
            v-if="showCloseButton"
            type="button"
            class="close-button"
            @click="emit('update:modelValue', false)"
          >
            <IconCloseThin />
          </button>

          <div v-if="isShowHeader" class="bottom-sheet-container__header">
            <slot name="title">
              <h4 v-if="title.trim()">{{ title }}</h4>
            </slot>

            <slot name="subTitle">
              <p v-if="subtitle.trim()">{{ subtitle }}</p>
            </slot>
          </div>

          <div
            v-if="slots.body"
            ref="content"
            class="bottom-sheet-container__body"
            :style="bodyStyle"
          >
            <slot name="body" />
          </div>

          <div v-if="slots.footer" class="bottom-sheet-container__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
