import { TARIFF_SORT } from "@/package/variables/sortType";
import { API } from "@/boot/api";

const state = {
  tariffs: [],
  tariffsMeta: null,
  landingCommercialTariffs: [],
  landingNonprofitTariffs: [],
};

const getters = {};

const mutations = {
  SET_TARIFFS: (state, tariffs) => {
    state.tariffs = tariffs.sort(
      (a, b) => TARIFF_SORT.indexOf(a.code) - TARIFF_SORT.indexOf(b.code)
    );
  },

  SET_TARIFFS_META: (state, meta) => {
    state.tariffsMeta = meta;
  },

  SET_COMMERCIAL_TARIFFS(state, tariffs) {
    state.landingCommercialTariffs = [...tariffs];
    state.landingCommercialTariffs
      .sort((a, b) => TARIFF_SORT.indexOf(a.code) - TARIFF_SORT.indexOf(b.code))
      .splice(3, 1);
  },

  SET_NONPROFIT_TARIFFS(state, tariffs) {
    state.landingNonprofitTariffs = [...tariffs];
    state.landingNonprofitTariffs = state.landingNonprofitTariffs.filter(
      (tariff) => tariff.code !== "promo_p" && tariff.code !== "pluspro_p"
    );
  },
};

const actions = {
  buyTariff(_, data) {
    return new Promise((resolve, reject) => {
      API.post("account/tariff", {
        tariff_id: data.tariff,
        entity_id: data.entity_id,
        period: data.period,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getTariffs({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("tariffs")
        .then((response) => {
          commit("SET_TARIFFS", response.data.data);
          commit("SET_TARIFFS_META", response.data.meta);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadingLandingTariffs({ commit }, country) {
    return new Promise((resolve, reject) => {
      API.post("landing/tariffs", {
        country_code: country,
      })
        .then((response) => {
          commit("SET_COMMERCIAL_TARIFFS", response.data.data);
          commit("SET_NONPROFIT_TARIFFS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
