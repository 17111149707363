import landing from "./landing";
import { balanceEn } from "@/modules/balance/i18n/en-us";

export default {
  balance: {
    ...balanceEn,
  },

  //SYSTEM
  sys: {
    error: "Error: ",
    descError: "Description: ",
    wrongLogin: "Invalid login",
    wrongPass: "Invalid password",
    unknown: "Unknown",
    unauthenticated: "Not signed up",
    iikAlready: "Entity with such IIC already exists",
    noMoney: "Insufficient balance",
    wrongPeriod: "ProPlus for one year only",
    noPermissions: "User has no permits.",
    firstLoginManager: "You logged in for the first time, create a password",
    limitShops: "Outlet creation is limited by tariff",
    limitLegalEntity: "Entity creation is limited by tariff",
    wrongTariff: "Not available for your tariff",
    wrongTariffPromo: "Promo code is not applicable for registration",
    tagExist: "This tag already exists",
    503: "Server is inaccessible",
    blockManager: "Manager is blocked",
    wrongCode: "Invalid code",
    codeExists: "Partner’s promo code is incorrect",
    noExam: "Test failed",
    noActivateAccount: "Account is not activated",
    emailExist: "Owner with such email already exists",
    userExist: "Such email is already registered with other services",
    noAccepted: "New agreement is not accepted",
    balanceNotEnough: "Insufficient balance",
    innExist: "Entity with such tax payer code already exists",
    noTodayChangeTariff:
      "You may change tariff only one day before expiration of the current tariff",
    badToken: "This link is invalid",
    wrongEmail: "Wrong email",
    emailTaken: "Email is already used",
    notAvailableCurrentTariff: "Unavailable with current tariff",
    dataNoCorrect: "Incorrect data",
    accountNotFound: "Account is not found",
    TPExist: "Outlet already exists",
    serverError: "Server error",
    ownerExist: "Account is not found",
    unauthorizedAction: "Your have insufficient authorities",
    legalEntityExist: "Entity is not found",
    tariffChangeOneDay:
      "You may change tariff one day before expiration of the current tariff",
    blockedByTariff: "Blocked as per tariff",
    documentInProcessOfCreation: "Document in the process of creation",
    emailAlreadyVerified: "Email already verified",
    emailConfirmed: "Ваша почта успешно подтверждена!",
    codeDoesNotExist: "Code does not exist",
    defaultCashbackCanNotBeDeleted:
      "Вознаграждение для клиентов без брендированной карты не может быть удалено",
    cashbackUpdateSuccessful: "Процент вознаграждения был успешно обновлен",
    cashbackDeleteSuccessful: "Вознаграждение удалено",
    cashbackCreateSuccessful: "Вознаграждение создано",
    fullCashback: "Клиент без брендированной карты",
    clientGroupAlreadyInUse:
      "Группа клиентов уже используется в группе кешбека",
    enteredAmountIsAlreadyInUse:
      "Введенная сумма вознаграждения уже выставлена",
    dateStartIsInvalid: "Дата начала периода введена некорректно",
    dateEndIsInvalid: "Дата конца периода введена некорректно",
    organizationNotConfirmed: "Юр.лицо не подтверждено",
    periodNotAvailable: "Выбранный период недоступен для покупки",
    notAvailableInYourCountry: "Not available in your country",
    exceededActivatedPromotionLimit:
      "Только 3 активированных акции на одну торговую точку",
    tradingPointLimitOnProPlus:
      "Tariff ProPlus allows to have no more than 30 trading points",
    tradingPointLimitOnPromo:
      "Tariff ProPlus allows to have no more than 15 trading points",
    tradingPointLimitOnLight:
      "Tariff ProPlus allows to have no more than 5 trading points",
    wrongDateOrder:
      "Дата начала периода должна быть раньше, чем дата конца периода",
    descriptionFormatInvalid: "Описание не должно содержать хештегов и emoji",
    descriptionMustNotContainEmoji: "Описание не должно содержать emoji",
    descriptionMinLength:
      "Описание должно быть длинее 600 символов без учета пробелов",
    descriptionMaxLength: "Описание не должно быть длинее 3000 символов",
    maxFileSizeError: "Размер файла превышает лимит в 10 Мбайт",
    addFile:
      "Перетащите или <span style='color: #447ECB; cursor: pointer'>загрузите файлы</span>",
    balance: "Баланс",
    noData: "Нет данных",
    changeMainOrganizationError:
      "Изменить главную организацию возможно только через 30 дней после предыдущего изменения",
    resetWallet: "Не удалось сбросить дизайн wallet pass",
    updateWallet: "Не удалось обновить дизайн wallet pass",
    userUnauthorized: "Логин или пароль введены неверно",
    kb: "КБ",
    dateMask: "дд.мм.гггг",
    sort: "Сортировка",
    noDataTitle: "Данных пока нет",
    syncError: "Проблема с синхронизацией. Попробуйте еще раз позже.",

    enterName: "Введите название",
    countries: {
      ru: "Russia",
      kz: "Kazakhstan",
      md: "Moldova",
    },
    select_country: "Choose a country",
    country: "Country",
    q_date: {
      days: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday",
      daysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat",
      months:
        "January_February_March_April_May_June_July_August_September_October_November_December",
      monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec",
    },
    errorTitle: "Что-то пошло не так",
    dateFrom: "Данные доступны с ",
    dateToToday: " по сегодняшний день",
    dateFormat: "Формат дд.мм.гггг или дд.мм.гггг - дд.мм.гггг",
    showBy: "Показывать по: ",
    showByMobile: "По: ",
    searchResponseEmpty: "По вашему запросу ничего не найдено",
    search: "Поиск",
    tagSearchResponseEmpty:
      "Тег не найден. Для его добавления нажмите Enter или Пробел.",
    from: "От:",
    to: "До:",
    gender: {
      m: "Мужской",
      f: "Женский",
    },
    500: "Что-то пошло не так. Что-то пошло не так. Проверьте интернет-соединение.",
  },
  entity: {
    title: "Add your company’s details",
    thirdStepTitle:
      "Please check if details are correct. Now you need to wait from 24 to 48 hours for us to confirm your entity.",
    accept: "I hereby accept the terms and conditions",
    offers: "of the public offer agreement",
    titleReject: "Request is declined!",
    rejectDescription:
      "Sorry, the details you have provided are incorrect. Please read carefully the moderators’ response and enter amended information on the entity.",
    message: "Message from the moderators:",
    change: "Change data",
    // titleWaiting: "Thank you for waiting", – changed of (NK-589)
    titleWaiting: "Thank you for registration!",
    descriptionWaiting:
      "Your entity is currently under moderation. You may enter your profile only after we make sure that your details are correct. Normally, verification takes 24 to 48 hours.",
    descriptionWaitingRu:
      "<p>В данный момент ваша организация проходит модерацию. </p> <p>После того, как мы удостоверимся в корректности ваших данных \n" +
      "вы сможете войти в кабинет партнёра RC Group. </p>  <p> Как правило, проверка занимает от 24 до 48 часов.</p>",
    descriptionWaitingRuWithoutPos:
      "<p>В данный момент ваша организация проходит модерацию. </p> <p>После того, как мы удостоверимся в корректности ваших данных \n" +
      "вы сможете войти в кабинет партнёра RC Group. </p> <p> Как правило, проверка занимает от 24 до 48 часов.</p>",
    // downloadAccept: "Download payment by acceptance invoice ", – changed of (NK-589)
    downloadAccept: "Download invoice for test payment",
    addDone: "Поздравляем с созданием юрлица!",
    waitingApprove:
      "<p>В данный момент ваше юрлицо проходит проверку. Она занимает от 24 до 48 часов.</p><p>Для прохождения проверки вам необходимо оплатить счет с указанного юрлица, скачав его по кнопке ниже. Вы также можете найти счет в личном кабинете: в разделе «Мои  предприятия» - «Документы» под названием «Тестовый платеж».</p>",
  },

  landing,

  contacts: {
    title: "Contacts",
    subtitle:
      "RC TECHNOLOGIES Limited Liability Company (RC TECHNOLOGIES LLC) Registered office: <br/> 191124, г. Санкт-Петербург, вн.тер.г. муниципальный округ Смольнинское, ул. Красного Текстильщика, д.10-12, литера О, помещ. 1-Н <br/> ИНН/КПП 7806578914/780601001 ОГРН 1207800157256",
    mailAddress: "E-mail:",
  },

  failed: "Error. Action failed",
  success: "Action completed",
  navbar: {
    page1: "Home",
    page2: "Registration",
    page3: "Signing in",
    page4: "Personal account",
  },
  registerCode: {
    ttl: "Enter partner’s promo code",
    promocode: "Promo code",
    invalidLength: "Incorrect promo code length",
    hasAccount: "Do you have an account?",
  },
  newPassword: {
    passwordChange: "Change Password",
    enterNewPassword:
      "Enter the new password that you will use to log into your personal account",
  },
  registerEmail: {
    ttl: "Enter your email",
    hasEmail1: "This email already exists. Want to",
    hasEmail2: "sign in?",
    enterEmailCode: "Please enter the code sent to your e-mail",
    noEmailCode: "Didn’t get the code?",
    sendCodeAgain: "The code may be sent again after",
    spamTitle: "If you did not receive the email, check your «Spam» folder",
  },
  resetPage: {
    title: "Password recovery",
    titleOk: "Your password has been successfully changed!",
    backToAuth: "Back to signing in",
    messageToMail: "You will receive a link to your email!",
    enterEmail: "Enter new email",
    descriptionNewEmail:
      "For security reasons, partner’s account email should be unique. Please enter new email.",
    oldEmail: "Previous email",
    newEmail: "New email",
    descriptionPassword: "Your password will not be changed",
    notice: "Don't worry, we will send you a recovery link by email.",
    loginInformation: "Login information has been sent to your email",
    resetPasswordLink: "We have sent a link to change your password by email",
    messageNotReceived: "Didn't receive the letter?",
    resendMail: "Resend email after 00:%{variable}",
  },
  //cabinet layout
  leftPanel: {
    counterparties: "Проверка контрагентов",
    entities: "Юрлица",
    tradingPoint: "Outlets",
    employees: "Personnel management",
    documents: "Documents",
    businesses: "Business",
    tariffs: "Tariffs",
    historyPayment: "Payment history",
    promotions: "Акции",
    actsAndAccounts: "Statements and invoices",
    accrualCashback: "Accrual of points",
    managers: "Management of managers",
    spread: "Распространение карт RC",
    analytics: "Insights",
    points: "Очки кассирам",
    enterprises: "Мои предприятия",
    paymentDecision: "Платежное решение",
    portfolio: "Portfolio",
    employees_and_managers: "Мои сотрудники",
    loyalty: "Loyalty program",
    clients: "Customers",
    claims: "Requests",
    claims_history: "History of requests",
    claims_groups: "Группировка клиентов",
    reviews: "Reviews",
    extendTariff: "Extend your tariff validity",
    changeTo: "Your tariff has been changed to",
    wallet: "Wallet card design",
    accountIs: "You have logged in as",
    manager: "manager",
    owner: "owner",
    terminals: "Checkout terminals",
    terminals_MSPOS: "MSPOS 4 в 1",
    terminals_LIMON: "Технология MPOS",
    FAQ: "FAQ",
    corporate: "Связь",
    push: "Push-уведомления",
    tips: "Безналичные чаевые",
    eMenu: "Электронное меню",
    giftCard: "Подарочные карты",
    processing: "Processing",
    services: "Сервисы",
    wallets: "Карты лояльности",
    clientsList: "Список клиентов",
    marketplaces: "Маркетплейсы",
    PromotionServices: "Продвижение",
  },
  headerButtons: {
    addCoin: "Top up",
    profile: "Profile",
    exit: "Log out",
  },
  //organization page
  organization: {
    title: "My entities",
    tooltip:
      "Главное Юр лицо используется для выставления счета при пополнении баланса. Изменять главное Юр лицо можно не чаще чем раз в 2 недели",
    name: "Entity name",
    iik: "IIC",
    inn: "Tax payer code",
    bin: "BIN",
    bic: "Bank BIC",
    codeFiscal: "Cod Fiscal",
    status: "Status",
    answear: "Moderator response",
    button: "Read more",
    bill: "History",
    deposit_sum: "Сумма депозита",
    head_name_description:
      "Введите Ф.И.О. ответственного лица, на которое будут автоматически создаваться акты и счета",
    mainOrganization: "Your main entity",
    mainOrganizationHint:
      "Основное юрлицо используется для покупки тарифа и остается активным при его деактивации. Изменить основное юрлицо можно не чаще чем раз в 14 дней",
    chooseMainOrganization: "Выберите Юр лицо",
    maxOrganizationError: "Maximum number of entities created",
    moderOrganizationError:
      "You may not add the new entity until the moderation process is complete",
    // about organization
    aboutTitle: "Information about your entity",
    typeOrganization: "Entity type",
    bankName: "Bank name",
    legalAddress: "Registered office",
    currentAccountNumber: "Bank account number",
    correspondentAccount: "Corr. account",
    buttonChangeOrganization: "Change entity info",
    buttonChangeRequisites: "Изменить данные",
    dataModeration: "Data are under moderation",
    organizationNotExist: "Such entity doesn’t exist",
    vatCode: "Код НДС",
    internationalBank: "Международный номер банковского счета",
    prompt: "Минимальный остаток на счете",
    deposit_min: "Минимальное значение ",
    addressFieldRequired: "Не введен адрес юр.лица",
    metaIsRequired: "Не введены банковские данные",
    //add organization
    add: {
      title: "Add your entity data",
      enterData: "Enter your entity data",
      commercial: "Commercial entity",
      halal: "Halal entity",
      button: "Создать организацию",
    },
    // popup Aliexpress
    link: "Link to website (optional)",
    addAliexpress: "Add goods at AliExpress",
    entities: "ЮР лица",
    modalTitle: "Изменение основного юрлица",
    modalText:
      "Основное юрлицо используется для выставление счета\n" +
      "        при пополнении баланса. Ваше основное юрлицо “{old}”,\n" +
      "        будет изменено на “{new}”",
    modalSubtext: "Изменить главное Юрлицо можно не чаще чем раз в 14 дней.",
    head_name: "Manager’s name",
    ogrn: "OGRN",
    legalTypes: "Legal entity’s incorporation form",
    taxSystem: "Taxation type",
    monthlyTurnover: "Monthly turnover",
    depositSum: "Deposit amount",
    okvedLabel: "Key activity type",
  },
  //Trading Points page
  shop: {
    title: "Outlets",
    name: "Outlet name",
    organization: "Entity",
    status: "Status",
    answear: "Moderator response",
    button: "Read more",
    hashtag: "Теги для торговой точки",
    searchTags: "Искать по тегам",
    //about Trading point
    sale: "Discount for RC customers",
    acceptPoints: "This outlet accepts RC points",
    noAcceptPoints: "This outlet doesn’t accept RC points",
    phoneAdmin: "Administrator’s phone",
    mailAdmin: "Administrator’s email",
    address: "Address",
    addressComment: "Comment to outlet address",
    workTime: "Working hours",
    buttonChangeShop: "Change outlet data",
    closeShop: "Close outlet",
    checkboxFeedback: "You may use it for records and feedback",
    checkboxPublish: "Publish at website",
    changePoint: "Change of outlet",
    maxShops:
      "Вы создали максимальное количество торговых точек для Вашего тарифа",
    noShops: "You have not yet any outlets",
    addShopButton: "Add an outlet",
    confirmationCloseShop: "Are you sure you want to delete outlet?",
    //add trading point
    add: {
      emenu: "Товары и услуги",
      reviews: "Отзывы",
      title: "Outlet creation",
      checkboxInternational: "Fill in worldwide version independently",
      descriptionInternational:
        "If you don’t fill in worldwide version, it will be translated automatically",
      name: "Type the name to find the outlet",
      description: "Описание",
      hashtag: "Enter hashtags to find the outlet",
      internationalName: "Type worldwide name (only in English)",
      sale: "Enter discount for RC program participants",
      additionalOptions: "Options",
      address: "Type address and comments to outlet",
      addressHint:
        "If the map does not have the address of your outlet, select the nearest one and contact the technical support of to install the correct address",
      internationalAddress:
        "Type worldwide address and comment to outlet (only in English)",
      phone: "Номер телефона",
      phoneInternational:
        "Type administrator’s international office phone and email",
      image: "Add photos to be shown to users",
      workTime: "Specify working hours",
      button: "Add outlet",
      options: {
        baby_chair: "Baby chair",
        children_room: "Children play-room",
        parking: "Parking",
        wifi: "WiFi",
        animals: "Animal allowed",
        round_the_clock: "Works non-stop",
        over_sixteen: "16+",
        yandex_program: "Программа Яндекс",
        no_smoking: "Курение запрещено",
      },
      yandexTooltip: "Ваше заведение есть в сервисах Яндекс.Еда, Яндекс Лавка",
      errorNoTime: "Вы не можете создать торговую точку без времени работы",
      addNewTag: "Новый тэг создан",
      mainInfo: "Основная информация",
      subInfo: "Дополнительная информация",
      bonusProgram: "Бонусная программа",
      isBonusPayable: "Скидка участникам программы RC",
      activeWait: "Задержка начислений",
      cashbackPercent: "Начисление вознаграждения",
      addressAndWorkTime: "Адрес и время работы",
      addressAndWorkTimeWithoutSpace: "Адрес и время&nbsp;работы",
      contactInfo: "Контакты",
      siteLink: "Ссылка на сайт",
      addLinks: "Добавить другие социальные сети",
      addAddressAndTime: "Добавить адрес и время работы",
      step: "Шаг",
      of: "из",
      comment: "Комментарий",
      email: "Почта",
      time: "Часы работы",
      weekend: "Только по выходным",
      weekdays: "Только по будням",
      another: "Работает в указанные часы работы",
      contacts: "Контакты",
      links: "Ссылки",
      thatPointUseRC: "Эта точка принимает баллы RC",
      logoAndPhotos: "Логотип и фотографии",
      logo: "Логотип торговой точки",
      filling: "Фотографии торговой точки",
      banner: "Баннер торговой точки",
      logoFormat: "Соотношение сторон для логотипа — 1:1,",
      anotherImagesFormat: "Соотношение сторон для фото — 16:9",
      bannerImagesFormat: "Соотношение сторон для баннера — 2:1",
      imagesFormat: "в формате PNG, JPG и JPEG и весом не более 10 Мб",
      onlyOnline: "Торговая точка работает онлайн",
      useForRegistration: "Использовать для записи клиентов",
      addMorePhone: "Добавить резервный номер телефона",
      addMoreEmail: "Добавить резервный адрес электронной почты",
      linkTo: "Ссылка на ",
      linkName: "Название ссылки",
      cropLogo: "Обрежьте логотип",
      cropBanner: "Обрежьте баннер",
      cropPhoto: "Обрежьте фото",
      publicTradingPoint: "Опубликовать торговую точку на сайте rcpay.ru",
      maxLengthDescription: "> 600 символов",
      //edit
      editTitle: "Просмотр торговой точки",
      noImage:
        "Если Вы не видите изображение для обрезки, значит загружаемый Вами файл не соответствует формату изображений. Пожалуйста, попробуйте загрузить другой файл.",
      noCanvas:
        "Не удалось обрезать изображение. Пожалуйста, попробуйте загрузить другой файл.",
      newTag: "(Новый тег)",
      saveEditChanges:
        "Информация о Вашей торговой точке обновится после прохождения модерации",
      noCheck: "У Вас еще нет ни одного чека",
    },
    // history
    historyOperation: "История чеков",
    search: "Start typing the customer name",
    noHistory: "Your history is empty",
    employee: "Employee",
    customer: "Buyer",
    source: "Source",
    typeOperation: "Transaction type",
    writing: "Debited",
    accrual: "Accrued",
    sumBuy: "Purchase amount",
    sumWrite: "Amount to be debited",
    toPay: "Payable",
    cashback: "Cashback",
    totalCashback: "Общее вознаграждение",
    salePopup: "Discount",
    types: {
      balance_up: "Пополнение баланса",
      balance_down: "Списание средств",
      tsp_approve: "Взаиморасчет по чеку",
      balance_refill: "Пополнение баланса",
      tariff_change: "Покупка тарифа",
      cashback_approve: "Клиентское путешествие",
      cashback_charge: "Чек. Списание баллов",
      certificate_charge: "Начисление. Использование подарочной карты",
      certificate_debt: "Списание. Выпуск подарочной карты",
      certificate_refund: "Возврат за выпуск подарочной карты",
      rc_withdraw: "Чек. Прием баллов",
      tsp_commission_fee: "Чек. Комиссия",
      tsp_commission_revert:
        "Доначисление некорректно списанной суммы за период с 1 апреля по дату начисления",
      tsp_commission_correct:
        "Корректировка взаиморасчетов в размере 1% от принятых баллов",
      manual_commission_approve: "Чек. Взаиморасчет по коммиссии",
      manual_withdraw: "Withdrawal",
      cashback_decline: "Отмена чека",
      rc_processing_receipts: "Чек кассового аппарата",
      cashback_approve_decline: "Отмена начисления за клиентское путешествие",
      partner_manual_withdraw: "Списание средств",
      inner_cashback_charge: "Чек. Списание вознаграждения",
      inner_cashback_approve: "Взаиморасчет по чеку",
      tsp_approve_payment: "Чек. Списание средств",
      rc_withdraw_approve: "Чек. Начисление за оплату баллами",
      withdraw_payment: "Чек. Списание средств",
      prodazha_top_mesta: "Покупка топ-места в rcpay",
      reserving_funds_withdrawal: "Зарезервировано к выплате",
      refund_reserved_funds_withdrawal: "Возврат резерва к выплате",
    },
    organizations: "Юр лицо",
    organizationsSelector: "Юрлица",
    moderationModalTitle: "Сообщение от модерации",
    tableSearch: "Поиск по таблице",
    customersSearch: "Поиск по покупателю",
    delete: "Вы уверены, что хотите удалить торговую точку?",
    nameSearch: "Поиск по названию",
    //edit
    editTitle: "Просмотр торговой точки",
    dataTooltip:
      "Для круглосуточно работающего заведения часы работы устанавливаются следующим образом: начало рабочего дня 00:00, конец рабочего дня 00:00.",
    preview: {
      title: "Режим предпросмотра торговой точки",
      points: "Баллы от заведения",
      discount: "Скидка",
      payPoints: "Оплата баллами",
      fromCost: "от стоимости",
      enroll: "Связаться",
      onMap: "На карте",
      aroundTheClock: "Круглосуточно",
      yandex: "Программа Яндекс",
      online: "Онлайн",
      comment: "Комментарий к адресу: ",
      flags: {
        wifi: "WiFi",
        baby_chair: "Детское кресло",
        children_room: "Детская комната",
        animals: "Можно с животными",
        parking: "Парковка",
        no_smoking: "Курение запрещено",
        over_sixteen: "",
      },
    },
    clients: "клиенту: ",
    other: "комиссия: ",
    total: "Итого: ",
  },
  //Employee page
  employee: {
    title: "Employees",
    personName: "Surname, name",
    shop: "Outlet",
    login: "Login",
    password: "Password",
    last_name: "Type surname",
    first_name: "Type name",
    createEmployee: "Create",
    noShops: "To create an employee, you need at least one outlet",
    createShop: "Go to outlet creation",
    noEmployees: "You have no employees yet",
    addEmployee: "Add employee",
    deleteEmployee: "Are you sure you want to delete employee?",
    downloadRCPay: "Download RC KASSA",
    //score
    titleScore: "Employee points",
    points: "points",
    manageEmployee: "Кассиры",
  },
  // Managing managers page
  managers: {
    title: "Management of managers",
    noManagersError: "You have no managers yet",
    addManager: "Add manager",
    managerCreated: "Manager is created",
    managerReset: "Manager’s password was reset!",
    oneTimePass: "One-time manager’s password",
    messageManagerCreated:
      "Attention! Keep this password recorded, at the first signing-in the manager will need to create a new password.",
    changePass:
      "Внимание! Запишите данный пароль, при первой авторизации менеджера, ему необходимо будет создать новый пароль",
    add: {
      title: "Add manager",
      second_name: "Manager’s surname",
      first_name: "Manager’s name",
      patronymic: "Manager’s patronymic",
      position: "Manager’s position",
      phone: "Manager’s phone number",
      permissions: "Access to account views",
      button: "Create a manager",
    },
    //change
    changeTitle: "Change manager",
    buttonChange: "Change the manager’s details",
    buttonResetPassword: "Reset password",
    //reset password
    newPassword: "Type new password",
    newPassRules1: "Should consist of at least 8 symbols",
    newPassRules2: "Contain Latin letters and digits",
    //delete manager
    deleteManager: "Are you sure you want to delete the manager?",
  },
  //Documents page
  documents: {
    title: "Documents",
    descriptionAcceptance: "Test payment",
    descriptionCertificate: "Certificate",
    instruction: "Instruction",
    shortInstruction: "Краткая инструкция",
    instructionDescription: "for using RC KASSA app",
    instructionCabinetDescription:
      "for using personal account of retail and service outlet",
    shortInstructionRCKassa: "по работе с приложением RC KASSA",
    emailTaken: "The email has already been taken",
    marketingMaterials: "Marketing materials",
    marketingMaterialsDescription1:
      "Files for registration of outlets and leaflet templates",
    agreementMerchantOffer:
      "Public offer agreement with retail and service outlet",
    agreementMerchantOfferDescription:
      "Cooperation agreement with retail and service outlet",
    confidentialPolitics: "Privacy Policy",
    confidentialPoliticsDescription: "Website privacy policy",
    rewardScenario: "Reward distribution scenarios",
    rewardScenarioDescription: "Description of reward distribution scenarios",
    tspTariffs: "TSP tariffs",
    tspTariffsDescription: "Information about tariffs",
    instructionCashier: "Guideline for using checkout terminal by the cashier",
    commercialOffer: "Commercial offer",
    instructionRCKassa: "for using RC.Kassa app",
    dateOfSigningContract: "Дата подписания договора ",
  },

  //check counterparties page
  counterparties: {
    title: "Проверка контрагентов",
    buttonCheck: "Проверить",
    question:
      "Дата регистрации, ИНН, КПП, ОГРН,  Основной вид деятельности, Статус юрлица,ИФНС, Юридический адрес, Ген. директор, Учредители, Уставный капитал",
    instructionButton: "Инструкция для проверки контрагентов",
    listTitle: "Как использовать Бот для проверки контрагентов?",
    listItemFirst:
      "Введите ИНН или ОГРН организации, которую собираетесь проверять.",
    listItemSecond: "Telegram-Бот покажет вам всю необходимую информацию.",
    listItemThird:
      "Для проверки блокированных счетов нужно нажать на кнопку «Проверить блокировку счетов».",
    listItemFourth:
      "После нажатия на кнопку «Проверить блокировку счетов» Бот покажет все заблокированные счета (при их наличии), ФНС, дату блокировки, № постановления, основание  в соответствии со статьей. Данную информацию можно скачать в PDF-формате для подробного изучения: нажмите кнопку «Скачать файл отчетом».",
    instructionCardTitleFirst: "Зачем проверять контрагентов?",
    instructionCardTitleSecond: "Своевременно узнавайте о фактах.",
    instructionCardTextFirst:
      "<p>Проверка контрагентов необходима для оперативного выявления финансовых и налоговых рисков, которые могут возникнуть при работе с контрагентом, и принятия обоснованных решений — продолжить или прекратить сотрудничество. <br> Бот автоматически проверяет новых и текущих контрагентов по разным источникам и сообщает вам о критических факторах и сведениях, требующих внимания.</p>",
    instructionCardTextSecond:
      "<p>С помощью бота вы можете проводить массовую проверку и мониторинг изменений по всей базе контрагентов или по конкретной выделенной. Это позволяет вовремя реагировать на существенные изменения у клиентов и партнеров, снижая риск невозврата денежных средств.</p>",
  },

  //business page
  business: {
    title: "Business",
    name: "Name of business",
    type: "Activity type",
    status: "Status",
    answear: "Moderator response",
    noBusiness: "Уou have no business yet",
    addBusiness: "Add business",
    createBusiness: "Создать бизнес",
    //about
    logoAndBanner: "Company’s logo and banner",
    shops: "Outlets",
    socials: "Links to social media",
    description: "Business description",
    changeBusiness: "Change business details",
    fileSizeLogo: "Logo sides: 1 to 1",
    fileSizeBanner: "Banner sides: 2 to 1",
    website: "Website",
    inst: "Instagram",
    vk: "VK",
    fb: "Facebook",
    //add
    add: {
      title: "Business creation",
      name: "Type the name of business",
      nameInternational: "Type worldwide name of business (only in English)",
      logo: "Upload the company’s logo",
      banner: "Upload the banner for the catalogue",
      type: "Specify activity type",
      typeInternational: "Specify worldwide activity type",
      shops: "Connect outlets",
      socials: "Add links to social media",
      description: "Add business description",
      descriptionInternational:
        "Add worldwide business description (only in English)",
      button: "Add business",
      activity_type: "Activity type",
      filePrompt:
        "<li>Файл не может быть больше 10МБ</li><li>Соотношение сторон логотипа 1 к 1</li>",
    },
    //change
    titleChange: "Change business",
    categories: {
      titles: {
        food: "Food",
        shopping: "Shopping",
        health_and_beauty: "Health and beauty",
        technique: "Technique",
        house: "House",
        for_children: "For children",
        entertainment: "Entertainment",
        sport: "Sport",
        hobby: "Hobby",
        services: "Services",
        other: "Other",
        "arenda-i-bronirovanie": "Аренда и бронирование",
      },
      activities: {
        cafe: "A cafe",
        restaurants: "Restaurants",
        shops: "The shops",
        bars_and_clubs: "Bars and Clubs",
        fast_food: "Fast food",
        coffee_shops: "Coffee houses",
        canteens: "Canteens",
        special: "Special",
        clothing: "Clothing",
        shoes: "Shoes",
        accessories: "Accessories",
        cosmetics: "Cosmetics",
        care: "Care",
        spa: "Spa",
        medicine: "Medicine",
        pharmacy: "Pharmacies",
        electronics: "Electronics",
        appliances: "Appliances",
        office_equipment: "Office equipment",
        communication_salons: "Communication salons",
        household_goods: "Household goods",
        repair: "Repair",
        furniture: "Furniture",
        garden: "Garden",
        clothes_and_footwear: "Clothes and Shoes",
        toys: "Toys",
        school: "School",
        education: "Education",
        parks: "Parks",
        cinema: "Cinema",
        spaces: "Spaces",
        tourism: "Tourism",
        special_form: "Specialist. the form",
        movement: "Movement",
        gyms: "Gyms",
        sections: "Sections",
        goods_for_creativity: "Goods for creativity",
        specialty_stores: "Specialty Stores",
        studios: "Studios",
        renovation_and_construction: "Repair and construction",
        health_and_beauty: "Health and beauty",
        computer_help: "Computer help",
        photo_and_video: "Photo and video",
        gifts_holidays: "Gifts, Holidays",
        supermarkets: "Supermarkets",
        leisure: "Leisure",
        auto: "Auto",
        pet_supplies: "Pet supplies",
        renovations_and_interiors: "Renovation and interiors",
        rent_and_hire: "Rent and hire",
        books_and_stationery: "Books and Stationery",
        jewelry: "Jewelry",
        trips: "Trips",
        gas_station: "Gas station",
        halal: "Halal",
        children_clothes_and_footwear: "Clothes and Shoes",
        sport_clothes_and_footwear: "Clothes and Shoes",
        other: "Other",
      },
    },
    messages: {
      message: "Не верный тип файла!",
      caption: "Файл должен быть jpg или png",
      error_message: "Загрузите логотип и баннер!",
    },
  },
  //Tariffs page
  tariff: {
    title: "Tariffs",
    yourTariff: "Your tariff",
    lite_p: {
      title: "Лайт",
      list: [
        "Регистрация 1 организации",
        "Создание 5 торговых точек",
        "Упрощенная статистика",
        "Доступ к RC KASSA",
        "Электронный документооборот",
        "Взаиморасчеты 2 раза в месяц",
        "Раздел вопросов и ответов",
      ],
    },

    lite_p_nko: {
      title: "Базовый",
      list: [
        "Первоначальный тариф",
        "Доступен ограниченный функционал кабинета",
      ],
    },

    promo_p: {
      title: "Промо",
      beforeTariff: "Доступно всё из тарифа Лайт...",
      list: [
        "Регистрация 15 организаций",
        "Создание 15 торговых точек",
        "Добавление 150 товаров",
        "Push-рассылка для клиентов",
        "Оцифровка клиентов",
        "Распространение карт RC",
        "Дизайн Wallet-карты",
        "Электронное меню",
        "Функция обратной связи",
        "Приоритетная поддержка",
      ],
    },

    pluspro_p: {
      title: "Про+",
      beforeTariff: "Доступно всё из тарифов Лайт и Промо...",
      list: [
        "Регистрация 30 организаций",
        "Создание 30 торговых точек",
        "Расширенная статистика",
        "Добавление бесконечного числа товаров",
        "Раздел электронного меню и категории",
        "Приоритетная поддержка",
      ],
    },

    corporate: {
      title: "Корпоративный",
      list: [
        "Оцифровка клиентов",
        "Функционал «Пригласить»",
        "Подраздел «Список клиентов»",
        "Дизайн собственной Wallet-карты",
        "Push-рассылка для клиентов",
        "Функция обратной связи",
        "Акция «-50% на продление тарифа»",
        "<p>Монетизация до 30% от начисленного \n" +
          'вознаграждения клиента <span style="color: #CD5959">*</span></p>',
      ],
    },

    faq: [
      {
        title: "Как происходит оцифровка клиента?",
        text:
          "<p>Для оцифровки клиента вам необходимо предоставить ему промокод. Он может быть в виде реферальной ссылки, QR-кода или непосредственно самого кода оцифровки. С его использованием клиенту необходимо пройти регистрацию в клиентском сервисе rcpay.ru.</p>" +
          "<p>Обратите внимание: чтобы считать QR-код, клиенту понадобится камера с соответствующей функцией или специальное приложение для считывания QR-кодов.</p>",
      },
      {
        title: "Что значит «активный» клиент?",
        text:
          "<p>Это оцифрованный вами клиент, который совершил покупку в онлайн- или офлайн-заведении экосистемы RC Group. Онлайн-покупки подразумевают шопинг в RC Market, приобретение сертификатов, активацию сервисов Яндекс, покупку пользовательского тарифа. Операции в офлайн — это покупки в заведениях в городе с использованием RC.Kassa.</p>" +
          "<p>При покупке тарифа «Корпоративный» каждый партнер участвует в акции  «скидка 50%» на продление тарифа. Основное условие этой акции — оцифровать от 500 «активных» клиентов.</p>",
      },
      {
        title: "Что будет, если не продлить тариф?",
        text: "<p>В таком случае функционал кабинета сократится до уровня тарифа «Базовый». Вам останется доступен подраздел «Список клиентов», но вы лишитесь возможности выпускать карты Wallet, отправлять Push-уведомления и доступа к сервису «Безналичные чаевые».</p>",
      },
      {
        title: "Как привлечь и оцифровать больше клиентов?",
        text: "<p>Используйте функционал «Поделиться» для размещения в соцсетях реферальной ссылки на оцифровку. А также размещайте тейбл-тенты, наклейки-стикеры, листовки, воблеры в вашем заведении.</p>",
      },
      {
        title: "Моего вопроса нет, как получить ответ?",
        text:
          "<p>Задайте вопрос в техподдержку. Для этого нажмите на значок RC Group в правом нижнем углу, выберите «Telegram» и опишите в сообщении свою проблему, по необходимости приложите скрины. С вами обязательно свяжется наш специалист, чтобы решить вопрос в кратчайшие сроки. </p>" +
          "<p>Также можно отправить письмо на email: <b>hello@rc.company</b> </br>" +
          "или позвонить по телефону: <b>8 (800) 500-57-62.</b></p>",
      },
    ],
    // developmentTariff: "Tariff is under development",
    // lite: {
    //   ttl1: "Registration of 1 entity",
    //   descr1: "Creation of 5 outlets",
    //   product1: "Возможность создать 20 товаров",
    //   ttl2: "Simplified statistics",
    //   descr2: "Cooperation with entities and outlets",
    //   ttl3: "Access to RC KASSA",
    //   descr3: "Online invoices and statements",
    //   ttl4: "Promotional materials",
    //   descr4: "FAQ"
    // },
    // promo: {
    //   ttl1: "All benefits of Lite tariff",
    //   descr1: "Registration of 15 entities",
    //   ttl2: "Creation of 15 outlets",
    //   descr2: "Additional statistics",
    //   product2: "Возможность создать 150 товаров",
    //   ttl3: "Customer profile",
    //   descr3: "Push-notifications for customers",
    //   ttl4: "Feedback function",
    //   descr4: "Own Wallet card design"
    // },
    // proplus: {
    //   ttl1: "All benefits of Promo tariff",
    //   descr1: "Registration of 30 entities",
    //   ttl2: "Creation of 30 outlets",
    //   descr2: "Advanced statistics",
    //   product2: "Безлимит на создание товаров",
    //   ttl3: "Customer list",
    // },
    // popup: {
    //   chosePeriod: "Select period",
    //   description: "It may be extended at any time",
    //   organization: "Debiting",
    //   fullPrice: "Total price",
    // },
  },
  //History of payment page
  history: {
    title: "Payment history",
    number: "Number",
    type: "Type",
    typeBuyTariff: "Tariff purchase",
    typeAddBalance: "Topping up",
    typePaymentAct: "Invoice payment against statement",
    typePaymentAcceptance: "Test payment",
    amount: "Amount",
    currency: "Currency",
    date: "Date and time",
    noHistory: "Your history is empty",
    entityName: "Organization name",
    operationsTab: "All operations",
    processingTab: "RC Processing",
    reward: "Вознаграждение",
    paidFromPurchase:
      "{cash} {currency} оплачено <span>(с покупки на {amount} {currency})</span>",
    downloadModal: {
      title: "Скачать файл",
      fileType: "Тип файла",
      sendOnEmail: "Отправить копию на почту",
    },
  },
  //actsAndAccounts page
  actsAndAccounts: {
    title: "Statements and invoices",
    number: "Number",
    name: "Description",
    amount: "Amount",
    date: "Date and time",
    status: "Status",
    button: "Download",
    accounts: "Invoices",
    acts: "Statements",
    actsNew: "Акты (новые)",
    noActs: "You have no statements yet",
    buttonDetail: "Detailed preview",
    creteAccount: "Generate invoice",
    accountsTypeText: "Payment invoices",
    actsTypeText: "Statement of transactions for the period",
    noAccounts: "No invoices available",
    arbitraryAct: "Generate a random statement",
    choseDate: "Select the date",
    downloadAct: "Download the statement",
    viewAct: "Preview the statement",
    downloadArbitraryAct: "Download random statement",
    noCustomActs: "No receipts during the selected period",
    hideTitle: "«Акты и счета» временно находятся на обновлении",
    hideBody:
      "<div>" +
      "<p>Обращаем Ваше внимание, что подраздел <b>«Акты и счета»</b> <br> в разделе <b>«Мои предприятия»</b> временно находится на обновлении.</p>" +
      "<p> Сформировать счет на оплату Вы сможете в разделе <b>«Баланс»</b>. <br> Выберите опцию <b>«Пополнить»</b> и <b>«Ввести сумму»</b>, введите сумму <br> и нажмите <b>«Выставить счет»</b>, после чего — <b>«Скачать счет»</b>. </p>" +
      "<p> Акт сверки можно запросить через тех. поддержку, написав на почту <a href='mailto:{link}'>{link}</a>.<br> Для обращения укажите юр. наименование и период,<br> за который Вам необходим акт сверки. </p>" +
      "<p>Приносим извинения за доставленные неудобства.</p>" +
      "<p><b>С уважением, RC Group.</b></p>" +
      "</div>",
  },
  // Claims and history claims page
  claims: {
    title: "Requests",
    historytitle: "History of requests",
    group_name: "Название группы",
    group_nomination: "Наименование группы",
    group_sum: "Сумма покупок (без учета скидок)",
    create_group: "Создать новую группу",
    edit_group: "Редактировать группу",
    delete_group: "Вы уверены что хотите удалить группу?",
    sum: "Сумма",
    percent: "Процент начисления",
    noClients: "So far you have not had a single client",
    oneClient: "Ai avut un singur client",
  },
  // Reviews page
  review: {
    title: "Отзывы в RC PAY",
    pageHint:
      "Данный подраздел предназначен для сбора обратной связи от посетителей ваших заведений, которая будет размещена на сайте rcpay.ru. Вы можете сами управлять какие отзывы отображать, кликнув на значок «глаз»",
    goodFirst: "Сначала положительные",
    badFirst: "Сначала отрицательные",
    newFirst: "По новизне",
    noTradingPointsData:
      "Для просмотра отзывов, необходимо создать торговую точку",
    hint: "Отзыв зависит от фильтра по датам",
    noReviews: "У вас еще нет отзывов",
    all: "Все отзывы",
    shown: "Отображаемые",
    hidden: "Скрытые",
    hideStatus: "Отзыв будет скрыт со страницы отзывов на сайте rcpay.ru",
    showStatus: "Показать отзыв на странице Вашей торговой точки на rcpay.ru?",
    noDataLabel: "Данных по отзывам нет",
    noTradingPointText: "У вас пока нет Торговых точек ",
    noReview: "Пользователи еще не оставляли отзывы о Вашей торговой точке",
  },
  //login manager an login page
  login: {
    title: "Hallo!",
    subTitle: "Please, sign in.",
    loginInput: "Login",
    email: "Email",
    passwordInput: "Password",
    approvePasswordInput: "Confirm password",
    passwordMismatch: "Passwords do not match",
    backArrow: "Back home",
    forgotPassword: "Forgot password?",
    noAccount: "No account?",
    register: "Sign up",
    backToService: "Отменить и вернуться к сервису",
    repeatPassword: "Repeat password",
  },
  //analytic page
  analytics: {
    title: "Insights",
    organizationSelect: "Выберите Юр лицо",
    organizationButton: "Юр лица",
    organizationCheckbox: "All entities",
    newOrganizationCheckbox: "Все Юр лица",
    calendar: "Calendar",
    organizations: {
      newClients: "New customers",
      allClients: "All customers",
      sales: "Sales",
      amount: "Amount of sales",
      amountCashback: "Less the amount set off by points",
      average: "Average receipt",
      averageCashback: "Average reward",
    },
    countClients: "Number of customers",
    topSumEmployee: "Top employees by sales amount",
    topSaleEmployee: " Top employees by sales",
    points: "Points",
    addPoints: "Points accrued",
    writePoints: "Points debited",
    rfmAnalysis: "RFM Анализ",
    rfmGroupSizes: "Размер RFM групп",
    clientPortrait: {
      btn: "Портрет клиента",
      title: "Клиенты",
      customerCount: "Количество клиентов:",
      receiptAvg: "Средний чек:",
      receiptCount: "Количество продаж:",
      receiptSum: "Сумма продаж:",
      maleFemale: "М/Ж:",
      noGender: "Пол не указан:",
    },
  },
  // Add points page
  points: {
    title: "Points accrual",
    subTitle:
      "Enter number of points to be awarded to employees for effected transaction.",
    pointsForPoints: "Score for points accrual",
    pointsForNewClient: "Score for points accrual to a new customer",
    employeePoints: "Очки кассирам",
  },
  // Cashback page
  cashback: {
    title: "Points accrual",
    subTitle:
      "Укажите размер вознаграждения. <br> Обратите внимание <br> Клиенты на тарифе Фри получают 25% <br> Клиенты на тарифе Смарт 50% <br> Ваши оцифрованные клиенты получают 50% <br> (комиссия не взымается)",
    statusName: "Customers without branded card",
    delay: "Задержка начислений (В днях)",
    setDelay: "Изменить задержку",
    editDelay: "Изменить задержку начислений",
    maxDelay: "Максимальная задержка 14 дней",
    setReward: "Установить вознаграждение",
    selectGroup: "Выберите группу",
    rewardPercent: "Введите процент начисления",
    minReward: "Минимальный процент начисления - 4",
    maxReward: "Максимальный процент начисления - 25",
    delayTitle: "Текущая задержка торговой точки:",
    withoutActiveWait:
      "Ваш пользователь получит вознаграждение в течении 30 минут с момента проведения операции",
  },
  // Spread page
  spread: {
    title: "Распространение карт RC",
    p1: "By scanning this code, your customers will be able to get a card.",
    p2: "We strongly advise to place the table-tent with QR code and caption “Download the Bonus Card! Earn bonus points and enjoy special purchase terms” near the checkout or a place where your customers make payment. E.g., on the tables in a cafe, in the waiting area near the checkout terminal in a store, or in other place so that your customers would see this info.",
    getCard: "Get a card using this link",
    ttl1: "Get a card via RC KASSA",
    p4: "Use RC KASSA app to get your QR code: go to menu and select “issue a Wallet card”. Propose your customer to scan the code using mobile phone. Despite of the ease of card getting in such a way, we advise to print and place QR code and invitation near the checkout terminal.",
    ttl2: "Employee management",
    p5: "The works experience shows that employees often not supportive to implementation of loyalty programs. It relates both to obsolete plastic and digital solutions, such as RC Group. Many employees may feel that while telling the customers on the loyalty program they reduce the company’s revenues. Such thinking is misguiding. The more active the loyalty program, the higher the average bill, the buying frequency, and the brand loyalty. Moreover, expansion of the program helps reduce the business costs for SMS and other mailing and get to know own customers better.",
    coped: "Copied",
  },

  // Wallet page
  wallet: {
    title: "Wallet card design",
    viewCard: "Wallet card view",
    orderCard: "Order card design",
    imageFont: "Insert the image for your Wallet card background",
    imageLogo: "Insert logo for your Wallet card",
    choseTitle: "Select color for QR code, heading and card number",
    colorQR: "QR code color",
    colorTitle: "Heading color",
    colorNumberCard: "Card number color",
    confirmDesign: "Confirm design",
    clearDesign: "Return to standard design",
    titleNumber: "Card number",
    claimSend: "Request has been sent!",
    expectCall: "Wait for a call from our manager.",
    orderConfirmDesign: "Want to submit a request for design?",
    //new
    selectShop: "Выберите торговую точку",
    settingWalletColor: "Настройте цвет карты",
    colorBackground: "- Цвет фона",
    colorText: "- Цвет надписей",
    colorSubstrate: "- Цвет подложки",
    addLogo: "Добавьте логотип",
    withoutLogo: "Нет логотипа",
    haveLogo: "Есть логотип",
    addSubstrate: "Выберите цвет подложки",
    defaultColor: "Залить основным цветом",
    customColor: "Залить собственным цветом",
    customImage: "Установить изображение",
    logoPrompt:
      "<li>Файл не может быть больше 5МБ</li><li>Соотношение сторон логотипа 1 к 1</li>",
    substratePrompt:
      "<li>Файл не может быть больше 5МБ</li><li>Соотношение сторон подложки 3 к 1</li>",
    saveCustomDesign: "Сохранить дизайн",
    backDefaultDesign: "Вернуть стандартный дизайн",
    saveModal: "Отправить ваш запрос на создание дизайна?",

    // wallet-card
    walletCard: {
      title: "Вид Wallet карты",
      discount: "Скидка",
      balance: "Баланс",
    },
  },

  // Push notifications page
  notifications: {
    title: "Push-уведомления",
    modal:
      "Рассылка находится в обработке, Ваши пользователи получат push-уведомление в течение 15 минут",
    description:
      "Внимательно прочитайте текст. После создания уведомления, изменить его будет <span>невозможно.</span>",
    notificationText: "Текст уведомления",
    sendError: "Не удалось отправить Push-уведомление",
    sendTime: "По московскому времени",
    noNotifications: "У вас нет созданных push-уведомлений",
    buttonNoData: "Создать PUSH-сообщение",
    table: {
      shopName: "Название торговой точки",
      header: "Заголовок",
      date: "Дата",
      time: "Время",
    },

    view: {
      shop: "Торговая точка",
      header: "Заголовок",
      message: "Текст сообщения",
      date: "Дата",
      time: "Время",
    },

    create: {
      warning:
        "Внимательно прочитайте текст. После создания уведомления, изменить его будет <span>невозможно.</span>",
      shop: "Выбор торговой точки",
      header: "Текст заголовка",
      message: "Текст сообщения",
      button: "Создать рассылку",
      messageAfterCreate:
        "Рассылка находится в обработке, Ваши пользователи получат push-уведомление в течение 15 минут",
    },
  },

  //Clients Page
  clients: {
    title: "Список клиентов",
    allClients: "Все клиенты",
    internalClients: "Внутренние",
    externalClients: "Внешние",
    clientsType: "Тип клиента",
    noTradingPoints: {
      label: "Данных по клиентам нет",
      text: "У вас пока нет Торговых точек",
    },
    noClients: {
      label: "Данных по клиентам нет",
      text: "Оцифровывайте клиентов, чтобы они отображались в вашем списке",
    },
    table: {
      inviteDate: "Дата регистрации",
      fullName: "ФИО",
      phone: "Номер телефона",
      receiptsCount: "Количество покупок",
      receiptsSum: "Сумма покупок",
    },

    clientCard: {
      title: "Информация о клиенте",
      client: {
        title: "Клиент",
        fields: {
          fullName: "ФИО",
          gender: "Пол",
          birthDate: "Дата рождения",
          phone: "Номер телефона",
          clientType: "Тип клиента",
          inviteDate: "Дата регистрации",
          rcCard: "Номер карты RC",
          note: "Заметка",
          noteMax: "Макс. 1000 символов",
        },
      },
      statistics: {
        title: "Статистика",
        titles: {
          avgReceiptsSum: "Средний чек",
          receiptsCount: "Кол-во покупок",
          receiptsSum: "Оборот",
          writeOffPointsSum: "Опл. баллами",
        },
        totalNumberOfVisit: "Общее кол-во посещений",
        firstVisit: "Первое посещение",
        lastVisit: "Последнее посещение",
      },
      receipts: {
        title: "Чеки",
        status: {
          approved: "Проведен",
          new: "В ожидании",
          declined: "Отменен",
        },
        noReceipts: "Данных по чекам нет",
      },
      reviews: {
        title: "Отзывы",
        noReviews: "Данных по отзывам нет",
      },
    },
  },

  // Client Portrait Page
  clintPortrait: {
    title: "Customer profile",
  },

  // Terminals Page
  terminal: {
    listServiceCenters: "Список сервисных центров",
    mspos: "Инструкция mspos",
    instructions1C: "Инструкция 1С",
    title: "Checkout terminals",
    typeTerminal: "Checkout terminal type",
    terminalName: "Checkout terminal MSPOS E-F",
    fullNameTitle: "Receiver data",
    fullName: "Receiver name",
    postAddress: "Mailing address",
    acquirerBank: "Select acquiring bank",
    countTerminal: "Number of terminals",
    fullPrice: "Total price",
    delivery:
      "Please note that delivery is charged additionally. Your manager will contact you regarding delivery.",
    buy: "Buy",
    documents: "Documents",
    status: "Status",
    // Documents
    specifications: "Guideline for using checkout terminals by the cashier",
    specificationsDescription: "Terminal description",
    instruction: "Instruction",
    instructionDescription: "Terminal description",
    manual: "Guideline for using the terminal ",
    manualDescription: "Terminal description",
    commercialOffer: "Commercial offer",
    commercialOfferDescription: "Terminal description",
    // Status
    buttonAddClaim: "Leave one more request",
    device: "Terminal",
    deviceName: "Description",
    count: "Quantity",
    aboutOrder: "About order",
    // Buy
    firstBlock: {
      title:
        "RC Group offers to its partners the integrated solution MSPOS 4 in 1",
    },
    secondBlock: {
      title: "Checkout terminal MSPOS E-F",
      circle1: "Without hidden payments",
      circle2: "Complies with Law No. 54-FZ",
      circle3: "License 1С for free",
      circle4: "We do not sell the customer data",
      characteristics: "Properties",
      OS: "Operating system",
      dimensions: "Dimensions",
      cm: "cm",
      weight: "Weight",
      g: "g",
      memory: "Memory",
      screen: "Screen",
      screenDescription: "5.5 inches, 1280x720 px, 320 ppi",
    },
    thirdBlock: {
      title: "Payment software",
      description:
        "Versatile payment platform for automatic trading and Internet acquiring",
    },
    fourthBlock: {
      title: "Checkout software",
      description:
        "<ul> <li>User-friendly cashier’s workstation</li> <li>Marking goods sales</li> <li>Posting and editing new goods, target management</li> <li>Alcohol sales (connection to external UTM will be required)</li> <li>Direct submission of sales reports to specified email</li> <li>Easy synchronization with Internet service 1С:Checkout</li> <li>Integration via Enterprise Data and offline cash register</li> <li> Can be used as self-service checkout </li> </ul>",
    },
    fifthBlock: {
      title: "Loyalty program",
      subtitle1: "Provide discounts and accept payment by points",
      kassaAllows: "RC KASSA enables",
      description:
        "<li>Generate transaction reports</li> <li>Accept payment by cash, bank card and RC points</li> <li>Track the employee’s score</li> <li>Track the employee’s colleague score</li>",
      stayClaim: "Leave a request",
    },
    //LIMON
    Limon: {
      title: "Технология MPOS",
      subTitle:
        "Аренда кассовых аппаратов. Зачем покупать, если можно арендовать?",
      button: "Оставить заявку",

      firstBlock: {
        title: "mPOS + Android",
        pros: [
          "Простой и удобный интерфейс приема платежа",
          "Можно отправить банковского слип-чек клиенту по смс/email",
          "История всех совершенных операций всегда под рукой",
          "В описании платежа можно вводить дополнительную информацию",
          "Работает в связке по  bluetooth со смартфоном (версия андроид от 8.1)",
          "Банковские карты (с магнитной полосой, чипом и NFC-модулем)",
          "Интеграция с любыми программами и системами",
        ],
        tableTitle: "Технические характеристики",
        table: [
          [
            "Интеграция с ККМ:  да",
            "Тип терминала:  мобильный",
            "Беспроводное подключение: да",
          ],
          [
            "Печать слипа: нет",
            "Бесконтактные платежи: да",
            "Проводное подключение: нет",
          ],
          ["Магнитные карты: да", "Экран: монохромный", "Карты с чипом: да"],
        ],
        benefit: [
          "Бесплатное оборудование для приема карт",
          "Соответствует 54-ФЗ",
          "Кассовая программа работает на платформе Android",
          "Принимает платежи всеми доступными на рынке способами",
          "Интеграция с любыми программами и системами, автоматизация финансового учета",
          "Оптимально для такси, курьерских служб и стационарных точек продаж с небольшим оборотом",
        ],
      },

      secondBlock: {
        title: "Смарт-терминал 4 в 1 со встроенным эквайрингом",
        pros: [
          "Кассовая программа",
          "Кассовое оборудование",
          "Терминал для приема банковских карт",
          "Программа лояльности",
          "Облачный бэк офис для предпринимателей",
        ],
        tableTitle: "Технические характеристики",
        table: [
          ["Размеры: 185х75х65 мм", "Вес: 408 грамм "],
          ["Доступ к интернету: Wi-Fi/2G /3G/4G", "Основная камера: 8 МП "],
          ["Навигация: GPS", "Необходим РКО: Нет"],
          [
            "Аккумулятор: 8 часов без подзарядки",
            "Фронтальная камера: 0,3 МП ",
          ],
        ],
        benefit: [
          "Быстрый запуск",
          "Соответствует 54-ФЗ",
          "Бесплатная аренда при обороте свыше 500 000 руб/мес",
          "Не требует РКО",
          "Подходит для всех отраслей бизнеса",
          "Интегрируется с большим количеством учетных системам",
        ],
      },

      loyalty: {
        title: "Программа лояльности",
        kassa: "RC KASSA",
        aboutKassa: "Оформляйте скидки и принимайте оплату баллами",
        kassaOpportunities: "RC KASSA позволяет:",
        opportunitiesList: [
          "Формировать отчеты о транзакциях",
          "Принимать оплату наличными, банковской картой и баллами RC",
          "Отслеживать очки сотрудника",
        ],
      },

      documents: {
        title: "Документы",
        about: "Описание устройства",
        date: "18.04.2022",
      },

      modal: {
        warning: "Банком эквайером является ПАО Банк ВТБ",
        fullNameTitle: "ФИО",
        fullNamePlaceholder: "Введите ФИО",
        emailTitle: "Почта",
        emailPlaceholder: "Введите почту",
        phoneTitle: "Телефон",
        phonePlaceholder: "Введите телефон",
        commentTitle: "Комментарий",
        commentPlaceholder: "Введите текст сообщения",
        organization: "Выбор организации",
        terminal: "Выбор аппарата",
        success: "Заявка успешно создана",
      },
    },
    // Terminals About Order Page
    fromOrder: "About order from",
    recipient: "Recipient",
    cashRegister: "Cash register",
    fiscalAccumulator: "Fiscal Accumulator",
    courierDelivery: "Courier delivery",
    pickupDelivery: "Pickup from the point",
    deliveryError: "В выбранный город доставка не осуществляется",
    insuranceAndPackaging: "Insurance of goods and packaging",
    standardInsurance: "Standard Insurance",
    finalSum: "Final Sum",
    downloadInvoiceForPayment: "Download invoice for payment",
    // Buy-terminal page
    deleteFromClaim: "Remove from order",
    recoverToClaim: "Recover",
    driveExpirationDate: "Drive expiration date",
    descriptionFiscalSelect:
      "The choice depends on the specifics of the business (sale of excise goods, catering, etc.), organizational and legal form and tax regime. At the end of the validity period, the drive must be replaced.",
    countFiscal: "Number of drives",
    countFifteenMonth: "Number of drives for 15 months",
    contThirtySixMonth: "Number of drives for 36 months",
    amountOfGoods: "Amount for goods",
    proceedToCheckout: "Proceed to checkout",
    mixedOrder: "Mixed order",
    // Order-terminal Page
    orderTerminalTitle: "Ordering cash registers",
    toOrder: "Order",
    clickAgree: "By clicking, I agree to the terms",
    ifNeedHelp:
      "If you need help registering a cash register with the Federal Tax Service, please inform the manager about it",
    outcome: "Outcome",
    issueCEP: "Регистрация в налоговой",
    issueOFD: "Регистрация ОФД",
    nalogReg: "Настройка кассы",
    issueCEP_description: "Создание квалифицированной электронной подписи",
    issueOFD_description:
      "Регистрация вашей кассы в операторе фискальных данных",
    nalogReg_description: "Настройка кассы для работы сразу после получения",
    serviceProvision: "Предоставление услуги",
  },

  // FAQ Page
  faq: {
    title: "FAQ",
    cabinetPartner: "RC CITY",
    rcKassa: "RC KASSA",

    sections: {
      analytics: {
        title: "Аналитика",

        subsections: {
          organization: {
            title: "Юр.лица",
            description:
              "В разделе <b>«Аналитика»</b> представлена аналитика по всем вашим заведениям и клиентам, которые используют в RC PAY. Информация представлена на пяти вкладках с акцентом на разные данные." +
              "<br><br>" +
              "На вкладке <b>«Юр лица»</b> можно посмотреть общие данные по клиентам, в том числе новым, а также узнать средний чек, количество покупок и общую сумму продаж. Кроме того, предусмотрена возможность получить подробную информацию по каждой зарегистрированной организации и выбрать интересующий Вас период, что позволит узнать количество продаж за день, неделю, месяц, год.",
            video: [],
          },
          shops: {
            title: "Торговые точки",
            description:
              "На вкладке <b>«Торговые точки»</b> расположена расширенная аналитика предыдущего подраздела: " +
              "<ul class='doted_list'>" +
              "<li>данные по клиентам;</li>" +
              "<li>продажи;</li>" +
              "<li>сумма продаж за необходимый период;</li>" +
              "<li>данные по каждой организации;</li>" +
              "<li>информация об активности сотрудников;</li>" +
              "<li>сумма продаж по каждому сотруднику;</li>" +
              "<li>очки кассирам.</li>" +
              "</ul> Выбрав <b>«Все торговые точки»</b>, можно просмотреть общую информацию по всем имеющимся торговым точкам.",
            video: ["//video.platformcraft.ru/embed/61f795da0e47cf171e558af5"],
          },
          employee: {
            title: "Сотрудники",
            description:
              "На вкладке <b>«Сотрудники»</b> Вы можете отслеживать активность Ваших сотрудников и оценивать их продуктивность за тот или иной период по следующим критериям:" +
              "<ul class='doted_list'>" +
              "<li>имя сотрудника, проводившего транзакцию;</li>" +
              "<li>торговую точку;</li>" +
              "<li>количество продаж;</li>" +
              "<li>сумму продаж;</li>" +
              "<li>очки кассира;</li>" +
              "<li>количество списанных баллов;</li>" +
              "<li>количество начисленных баллов.</li>" +
              "</ul>",
            video: ["//video.platformcraft.ru/embed/61f795ed0e47cf171e558af6"],
          },
          RFM: {
            title: "RFM-анализ",
            description:
              "На вкладке <b>«RFM-анализ»</b> представлен анализ сегментации клиентов по частоте и сумме покупок. Он помогает выявлять тех клиентов, которые больше всего покупают у Вас." +
              "<br> <br>" +
              "Для получения информации выберите <b>«Юр лицо»</b> из всплывающего меню и период в <b>«Календаре»</b>. По итогу отобразится таблица с результатами анализа групп, которые вы сформировали в <b>«Начислении баллов»</b>.",
            video: ["//video.platformcraft.ru/embed/63aeb61c0e47cf7b45610124"],
          },
          clients: {
            title: "Портрет клиента",
            description:
              "На вкладке <b>«Портрет клиента»</b> Вы можете детально просмотреть информацию обо всех клиентах выбранной организации, выявить постоянных клиентов. На графике возраста можно ознакомиться с дополнительными данными по возрастным категориям:" +
              "<ul class='doted_list'>" +
              "<li>количеством клиентов данной возрастной категории;</li>" +
              "<li>их средним чеком;</li>" +
              "<li>количеством покупок;</li>" +
              "<li>суммой покупок.</li>" +
              "</ul>",
            video: ["//video.platformcraft.ru/embed/63aeb91c0e47cf7b45610131"],
          },
        },
      },

      loyaltyProgram: {
        title: "Программа лояльности",

        subsections: {
          addPoints: {
            title: "Начисление баллов",
            description:
              "В разделе «Начисление баллов» Вы можете настроить размер начисляемых баллов. Минимальный процент начисления – 4%, а максимальный – 25%. Чтобы усилить лояльность клиентов, группируйте их в зависимости от суммы покупок и назначайте каждой группе свой процент вознаграждения." +
              "<br>" +
              "Для создания группы клиентов выполните следующие действия:" +
              "<br>" +
              "<ol class='number_list'>" +
              "<li>Выберите торговые точки, для которых Вы хотите создать группу клиентов. Например:" +
              "<ul class='doted_list circle'>" +
              "<li>Новый клиент с покупками на сумму до 10 000 рублей – вознаграждение составит 5%.</li>" +
              "<li>Постоянный клиент с покупками от 10 000 рублей – вознаграждение составит 10%.</li>" +
              "<li>Любимый клиент с покупками от 50 000 рублей – вознаграждение составит 20%.</li>" +
              "</ul></li>" +
              "<li>Нажмите кнопку «Создать группу».</li>" +
              "</ol>" +
              "<br>На этой странице также есть возможность установить <b>«Текущую задержку торговой точки»</b> – это количество дней, спустя которое клиенту будет начислено вознаграждение за покупку. Значение может быть от 0 до 14 дней. Нажмите кнопку <b>«Изменить задержку»</b>, чтобы указать желаемый срок, а затем – <b>«Сохранить»</b>. Обратите внимание: функция будет работать корректно только при положительном балансе в RC CITY.",
            video: ["//video.platformcraft.ru/embed/658b5dd1ef3db51ca61a4ee1"],
          },
          promotions: {
            title: "Акции",
            description:
              "В подразделе <b>«Акции»</b> Вы можете создавать и отслеживать акции для своих заведений сразу или по отдельности. Информация представлена в виде таблицы со столбцами следующего содержания: название акции, торговая точка, где она проходит, процент начисления баллов (не суммируется с процентами начисления баллов из предыдущего раздела), процент скидки. Также напротив каждой акции предусмотрена возможность скрыть из <b>RC Кассы</b> у сотрудника редактировать и удалить." +
              "<br><br>" +
              "Для того, чтобы создать акцию в всплывшем окне укажите:" +
              "<ul class='doted_list'>" +
              "<li>торговую точку,</li>" +
              "<li>название акции,</li>" +
              "<li>процент начисления баллов,</li>" +
              "<li>процент скидки.</li>" +
              "</ul>" +
              "После успешного создания акции Вы можете зайти, как сотрудник в <b>RC KASSA</b>, и при нажатии кнопки <b>«Акции»</b>, отобразиться созданная в кабинете акция. ",
            video: ["//video.platformcraft.ru/embed/63aebbf9ef3db55eb798f8f2"],
          },
          historyPayment: {
            title: "История операций",
            description:
              "На вкладке <b>«Все операции»</b> в подразделе <b>«История операций»</b> будет отображена таблица со всеми пополнениями и списаниями средств с баланса в RC CITY. Чтобы получить более подробную информацию, Вы можете указать нужный вам период в календаре." +
              "<br> <br>" +
              "Также на этой странице Вы можете скачать историю операций за определенный период или за все время использования кабинета. Чтобы сделать это, нажмите кнопку <b>«Скачать»</b>, выберите формат скачивания и, по желанию, установите флажок <b>«Отправить копию на электронную почту»</b>. В этом случае файл будет отправлен на Вашу электронную почту, указанную при регистрации в RC CITY. После этого снова нажмите кнопку <b>«Скачать»</b>." +
              "<br> <br>" +
              "На вкладке <b>RC Processing</b> Вы можете увидеть информацию по всем проведенным операциям на кассовых аппаратах.",
            video: ["//video.platformcraft.ru/embed/658aeba50e47cf2ffec366a7"],
          },
        },
      },

      clients: {
        title: "Клиенты",

        subsections: {
          clients: {
            title: "Список клиентов",
            description:
              "В разделе <b>«Список клиентов»</b> доступна информация о Ваших покупателях. Чтобы просмотреть данные, выберите организацию из выпадающего списка или отметьте флажком пункт «Все организации»." +
              "<br> <br>" +
              "На вкладке <b>«Все клиенты»</b> вы увидите информацию обо всех клиентах Вашего заведения. <br>" +
              "Вкладка <b>«Внутренние клиенты»</b> покажет данные о клиентах, оцифрованных в Вашем заведении. <br>" +
              "На вкладке <b>«Внешние клиенты»</b> представлена информация о клиентах, которые посещали Ваше заведение и сделали в нем хотя бы одну покупку." +
              "<br> <br>" +
              "Внизу страницы Вы также найдете кнопку <b>«Пригласить»</b>, нажав на которую можно скопировать ссылку или скачать QR-код для регистрации клиента.",
            video: ["//video.platformcraft.ru/embed/658b5e700e47cf2ffec3675c"],
          },
          feedback: {
            title: "Отзывы",
            description:
              "В подразделе <b>«Отзывы»</b> отображаются отзывы всех клиентов, которые посещали Ваше заведение и оставили отзыв на сайте rcpay или в приложении RC PAY. <br> Для удобной работы с отзывами доступно несколько фильтров:  " +
              "<ol class='number_list'>" +
              "<li><ul class='doted_list circle'>" +
              "<li>При нажатии кнопки <b>«Выберите торговую точку»</b> отобразятся все отзывы, оставленные клиентом о данной точке</li>" +
              "<li>При выборе пункта <b>«Все торговые точки»</b> Вы сможете увидеть отзывы на все торговые точки.</li>" +
              "</ul></li>" +
              "<li>Предусмотрена возможность посмотреть отзывы за определенный период или дату. Для этого откройте календарь, выберете из списка нужный период или задайте его в календаре</li>" +
              "<li>Отфильтровать отзывы можно по 3 характеристикам: <ul class='doted_list circle'>" +
              "<li>по новизне;</li>" +
              "<li>только положительные;</li>" +
              "<li>только отрицательные. </li>" +
              "</ul></li>" +
              "</ol>" +
              "<br><br>" +
              "Также Вы можете выбрать конкретные отзывы, которые будут отображены на сайте rcpay и в приложении RC PAY. Для этого нажмите на знак просмотра у тех отзывов, которые хотите, чтобы отображались на сайте и в приложении. Обратите внимание, что скрытые отзывы не будут влиять на оценку заведения, которая будет отображаться на сайте.",
            video: ["//video.platformcraft.ru/embed/658b5e01ef3db51ca61a4ee2"],
          },
          pushNotifications: {
            title: "Push-уведомления",
            description:
              "В подразделе <b>«Push-уведомления»</b> можно настроить рассылку для Ваших клиентов, чтобы они всегда были в курсе Ваших акций и мероприятий." +
              "<ol class='number_list'>" +
              "<li>Для этого нажмите плюс напротив «Создать Push-рассылку» и заполните соответствующие поля;</li>" +
              "<li>Выберите Торговую точку, на клиентов которой планируете делать рассылку;</li>" +
              "<li>Укажите текст заголовка (например: «праздничные скидки всем»);</li>" +
              "<li>Введите текст сообщения push-уведомление в течение 15 минут. Обратите внимание на то, что после создания уведомления изменить его будет невозможно. Все разосланные оповещения отображаются в виде таблицы на этой же странице. По каждому можно нажать «Подробнее» и посмотреть Точку продаж, на которую сделали рассылку, дату отправки и текст.</li>" +
              "</ol>" +
              "<br>" +
              "Ваши клиенты получат push-уведомление в течение 15 минут. Обратите внимание на то, что после создания уведомления изменить его будет невозможно. Все разосланные оповещения отображаются в виде таблицы на этой же странице. По каждому можно нажать «Подробнее» и посмотреть Точку продаж, на которую сделали рассылку, дату отправки и текст.\n",
            video: ["//video.platformcraft.ru/embed/63aeb56e0e47cf7b45610121"],
          },
        },
      },

      wallet: {
        title: "Карты Wallet",

        subsections: {
          spread: {
            title: "Распространение карт Wallet",
            description:
              "Карта Wallet — это электронный эквивалент карт магазинов и заведений. В подразделе <b>«Распространение карты Wallet»</b> можно получить QR-код для использования его в оцифровке клиентов, а также ссылку для скачивания карты, которую можно разместить в социальных сетях, на сайте и любых других источниках, что позволит оцифровывать клиентов в любой точке мира.",
            video: ["//video.platformcraft.ru/embed/61f796daef3db553eb6a2312"],
          },
          designWallet: {
            title: "Дизайн карты Wallet",
            description:
              "В разделе <b>«Дизайн карты Wallet»</b> предусмотрена возможность создания уникального дизайн ваших карт. Для этого необходимо:" +
              "<ol class='number_list'>" +
              "<li>Выбрать вариант из выпадающего списка в разделе <b>«Выбрать организацию»</b>, для которого Вы хотите создать карты Wallet.</li>" +
              "<li>Выбрать фон: перетащите или загрузите файл с изображением в окно <b>«Вставьте картинку для фона вашей Wallet-карты»</b>. Обратите внимание, что вес файла не должен превышать 2 МБ, соотношение сторон 16:9.</li>" +
              "<li>Выбрать логотип. Перетащите или загрузите файл с изображением в окно Вставьте логотип вашей Wallet-карты. Обратите внимание, что вес файла не должен превышать 2 МБ, соотношение сторон 1:1.</li>" +
              "<li>Выберите цвет QR-кода, заголовка и номера карты.</li>" +
              "</ol>" +
              "Чтобы сохранить настройки, нажмите <b>«Изменить дизайн»</b>, а для сброса параметров — <b>«Вернуть стандартный дизайн»</b>.",
            video: ["//video.platformcraft.ru/embed/61f796e7ef3db553eb6a2313"],
          },
        },
      },

      employeesAndManagers: {
        title: "Мои сотрудники",

        subsections: {
          managingManagers: {
            title: "Менеджеры",
            description:
              "В разделе <b>«Менеджеры»</b> отображены все менеджеры, которых зарегистрировал владелец ТСП:" +
              "<ul class='doted_list'>" +
              "<li>Ф.И.О сотрудника;</li>" +
              "<li>должность;</li>" +
              "<li>номер телефона;</li>" +
              "<li>логин.</li>" +
              "</ul>" +
              "Также можно добавить, изменить, заблокировать и удалить менеджера. Для того, чтобы добавить менеджера нужно нажать плюс, указать фамилию, имя, отчество, должность, номер телефона и настроить доступ к страницам личного кабинета. Логин и пароль для входа будут сформированы программой автоматически. Помните, что пароль, после добавления менеджера, показывается единожды. Рекомендуем его сохранить в удобном для Вас месте, так как он не будет отображен в программе." +
              "<br><br>" +
              "Обратите внимание, что менеджер не имеет доступа к проведению транзакций в приложении RC.Kassa.",
            video: ["//video.platformcraft.ru/embed/61f796ad0e47cf171e558b08"],
          },
          managingEmployee: {
            title: "Кассиры",
            description:
              "В разделе <b>«Кассиры»</b> представлены данные по всем созданным сотрудникам. Для создания сотрудника необходимо нажать плюс и ввести фамилию, имя, а также выбрать торговую точку сотрудника. В таблице вы найдете логин, пароль, которые необходимы для входа в RC.Kassa, и статус сотрудника." +
              "<br><br>" +
              "Кроме того, в этой вкладке можно посмотреть информацию по набранным баллам, заблокировать, либо удалить сотрудника.",
            video: ["//video.platformcraft.ru/embed/61f796c10e47cf171e558b09"],
          },
          addPoints: {
            title: "Очки кассирам",
            description:
              "В разделе <b>«Очки кассирам»</b> можно настроить количество выдаваемых сотруднику очков за проведение операции и проведение операции с новым клиентом.\n",
            video: ["//video.platformcraft.ru/embed/61f796ccef3db553eb6a2311"],
          },
        },
      },

      enterprises: {
        title: "Мои предприятия",

        subsections: {
          organization: {
            title: "Юр лица",
            description:
              "В подразделе <b>«Юр лица»</b> Вы можете добавить имеющуюся у Вас организацию, чтобы впоследствии на ее заведения распространялась программа лояльности. Для этого нажмите знак плюса напротив «Юр лица» и заполните все имеющиеся поля: " +
              "<ul class='doted_list'>" +
              "<li>ИНН организации;</li>" +
              "<li>юридическое наименование;</li>" +
              "<li>юридический адрес;</li>" +
              "<li>банк, обслуживающий организацию; </li>" +
              "<li>БИК;</li>" +
              "<li>корреспондентский счет;</li>" +
              "<li>расчетный счет;</li>" +
              "<li>Ф.И.О руководителя.</li>" +
              "</ul>" +
              "Обратите внимание особое внимание на коррекспондентский и расчетный счета. В них должно быть одинаковое колличество цифр и отсутствовать лишние знаки (пробелы и точки). Также при выборе типа организации «Халяль», необходимо загрузить подтверждающий документ в формате pdf, jpeg или png. " +
              "<br><br>" +
              "Кроме того, возможность добавить вторую и последующие организации присутствует только на платных тарифах. Если у Вас несколько организаций, то Вы можете выбрать, какая из них станет основной и будет действовать даже в случае понижения тарифа. После создании организации Вы сможете отслеживать статус проверки внесенной вами информации со стороны модерации:" +
              "<ul class='doted_list'>" +
              "<li>«Подтверждено», если все данные заполнены верно и проверка пройдена успешно;</li>" +
              "<li>«Отклонено», если информация заполнена неверно или требует уточнения. Пожалуйста, обращайте внимание на комментарии модератора, они подскажут Вам, какую информацию нужно скорректировать;</li>" +
              "<li>«На модерации», если организация ожидает проверки или уже проверяется модератором.</li>" +
              "</ul>" +
              "Если модерация будет отклонена, то в поле «Ответ модерации» появится поясняющее причину сообщение. Внимательно изучите его и выполните рекомендации модератора." +
              "<br><br>" +
              "Также Вы всегда можете посмотреть информацию о выбранной организации, нажав на кнопку «Подробнее» напротив наименования:" +
              "<ul class='doted_list'>" +
              "<li>наименование организации;</li>" +
              "<li>ИНН;</li>" +
              "<li>юридический адрес;</li>" +
              "<li>БИК банка;</li>" +
              "<li>расчетный счет;</li>" +
              "<li>корреспондентский счет;</li>" +
              "<li>сумма депозита.</li>" +
              "</ul>" +
              "На этой странице Вы сможете поменять данные и задать сумму депозита, нажав на кнопку «Изменить реквизиты организации»." +
              "<br><br>" +
              "Сумма депозита — это размер минимального остатка на счете, т.е. при превышении выбранного вами значения со стороны RC Group будет осуществлена выплата вашей компании, а при недостижении — оплата не происходит.",
            video: ["//video.platformcraft.ru/embed/61f795f9ef3db553eb6a2303"],
          },
          shops: {
            title: "Торговые точки",
            description:
              "В разделе <b>«Торговые точки»</b> Вы найдете список всех зарегистрированных точек, историю чеков. Также здесь есть возможность при необходимости удалить точку ." +
              "<br><br>" +
              "Для добавления новой торговой точки перейдите на вкладку <b>«Торговые точки»</b> и нажмите кнопку <b>«Создать»</b> в правом верхнем углу. Затем заполните все обязательные поля. Для Вашего удобства рядом с полями есть подсказки. Постарайтесь максимально подробно и точно описать деятельность Вашей торговой точки и подчеркнуть ее уникальность с помощью описания и фотографий. " +
              "<br><br>" +
              "Важно указать юрлицо, с которым будут производиться взаиморасчеты. Для этого выберите соответствующее юридическое лицо из выпадающего списка." +
              "<br>" +
              "Заполненная информация будет отправлена на модерацию." +
              "<br>" +
              "Вы сможете отслеживать статус проверки после того, как данные торговой точки будут отправлены на модерацию:" +
              "<ul class='doted_list'>" +
              "<li><i>«Подтверждено»</i>, если все данные заполнены корректно и проверка пройдена успешно. В этом случае Ваша точка будет размещена на сайте rcpay и в приложении RC PAY.</li>" +
              "<li><i>«Отклонено»</i>, если недостаточно информации или требуются уточнения. Вы увидите пояснительное сообщение от модератора с рекомендациями по исправлению.</li>" +
              "<li><i>«На модерации»</i>, если точка ожидает проверки или уже проверяется модератором.\n</li>" +
              "</ul>" +
              "<br><br>" +
              "<b>«История чеков»</b><br>" +
              "Чтобы просмотреть историю чеков, нажмите на кнопку <b>«История чеков»</b> в строке соответствующей торговой точки. Вы можете отфильтровать и отсортировать таблицы или просмотреть конкретную транзакцию более детально, нажав кнопку <b>«Подробнее»</b>.",
            video: ["//video.platformcraft.ru/embed/658b5ee80e47cf2ffec3675e"],
          },
          // paymentMethods: {
          //   title: "Способы оплаты",
          //   description:
          //     "Теперь Вы можете подключить электронный тип оплаты (банковская карта, СБП, Yandex Pay и др.) <br>" +
          //     "На странице <b>«Способы оплаты»</b> выберите юрлицо и перейдите на вкладку <b>«Документы»</b>. В этом разделе Вы найдете документы для подписания: <b>«Заявление о присоединении»</b>, <b>«Ставки банковского эквайринга»</b>, а также <b>«Инструкцию по активации ключей»</b>. В разделе <b>«Статус»</b> Вы можете отследить процесс подключения." +
          //     "<br><br>" +
          //     "После того как документы будут подписаны и пройдут модерацию, Вы увидите сообщение модератора о ставке, которая вам одобрена. <br>" +
          //     "Далее ожидайте ключи от нашего партнера «Рокет Ворк» на электронную почту, которую Вы указали при регистрации. <br>" +
          //     "С помощью <b>«Инструкции по активации ключей»</b> Вы сможете самостоятельно активировать кассу и начать принимать платежи в этот же день.",
          //   video: ["//video.platformcraft.ru/embed/658b5e9a0e47cf2ffec3675d"],
          // },
          documents: {
            title: "Документы",
            description:
              "В разделе <b>«Документы»</b> на вкладках «Документы» и «Маркетинговые материалы» Вы сможете ознакомиться с инструкциями и материалами, относящимся к продукции RC GROUP.",
            video: ["//video.platformcraft.ru/embed/61f79688ef3db553eb6a230f"],
          },
          actsAndAccounts: {
            title: "Акты и счета",
            description:
              "В подразделе <b>«Акты и счета»</b> можно просмотреть счета и акты по вашим организациям, выбрав нужную. На вкладке «Счета» можно ознакомиться с наименованием, суммой, датой и временем, статусом платежа, также скачать нужный Вам счет в любое время. На вкладке «Акты» представлены все выставленные акты, а также есть возможность сформировать по ним счет, скачать или просмотреть детальную информацию. Кроме того, в разделе «Акты» Вы можете указать период, за который Вы бы хотели сформировать акт." +
              "<br><br>" +
              "Для удобства клиентов с 01.04.2022 в отображающуюся информацию в актах сверки внесены изменения. В старой версии документа (сформированной до 31.03.2022) отражена информация только в баллах, а в новой версии (с 01.04.2022) — информация о фактическом пополнении и списании средств с баланса в RC CITY. Чтобы скачать документы, достаточно выбрать необходимый вам вид документа и нажать кнопку «Сформировать».",
            video: ["//video.platformcraft.ru/embed/61f796680e47cf171e558af9"],
          },
          tariffs: {
            title: "Тарифы",
            description:
              "В разделе <b>«Тарифы»</b> показаны все имеющиеся тарифы. Чтобы приобрести тариф, выберите интересующее Вас предложение и нажмите «Купить тариф», выберите «Юрлицо» и нажмите кнопку «Оплатить». <br>" +
              "Для оплаты тарифа вы должны пополнить баланс, для этого сформируйте счет в разделе «Баланс» на сумму стоимости тарифа и произведите оплату с реквизитов, принадлежащих выбранной организации.",
            video: ["//video.platformcraft.ru/embed/658b5e43ef3db51ca61a4ee3"],
          },
        },
      },

      services: {
        title: "Сервисы",

        subsections: {
          checkingCounterparties: {
            title: "Проверка контрагентов",
            description:
              "В подразделе <b>«Проверка контрагентов»</b> Вы можете с помощью бота проверить контрагентов. Это необходимо для оперативного выявления финансовых и налоговых рисков, которые могут возникнуть при работе с контрагентом, и принятия обоснованных решений: продолжить или прекратить сотрудничество." +
              "<br><br>" +
              "Бот автоматически проверяет новых и текущих контрагентов по разным источникам и сообщает вам о критических факторах и сведениях, требующих внимания." +
              "<ol class='number_list'>" +
              "<li>Статус юридического лица: «действующая», «в процессе ликвидации», «ликвидирована».</li>" +
              "<li>Руководители.</li>" +
              "<li>Уставной капитал.</li>" +
              "<li>Состояние счетов (если они заблокированы, то вряд ли контрагент сможет расплатиться).</li>" +
              "</ol>" +
              "Кроме того, с помощью бота вы можете проводить массовую проверку и мониторинг изменений по всей базе контрагентов или по конкретно выделенной. Это позволяет вовремя реагировать на существенные изменения у клиентов и партнеров, снижая риск невозврата денежных средств.",
            video: ["//video.platformcraft.ru/embed/63aec1bb0e47cf7b45610151"],
          },
          eMenu: {
            title: "Электронное меню",
            description:
              "На странице <b>«Электронное меню»</b> Вы можете добавить свои товары или услуги, чтобы клиенты могли ознакомиться с ассортиментом Вашего заведения или магазина на сайте rcpay или в приложении RC PAY." +
              "<br>" +
              "Также внизу страницы Вы можете сформировать и скачать QR-код, который позволит перейти к меню Вашей торговой точки в несколько кликов.",
            video: ["//video.platformcraft.ru/embed/658aedae0e47cf2ffec366af"],
          },
        },
      },

      contacts: {
        title: "Контакты",

        subsections: {
          contacts: {
            title: "Способы связи",
            description:
              "По всем интересующим вопросам обращайтесь по телефону <a href='tel:88005005762' class='phone'>8 (800) 500-57-62</a> или по электронной почте  <a href='mailto:hello@rc.city' class='email'>hello@rc.city</a>. Либо напишите свой вопрос в форму обратной связи, которая расположена в правом нижнем углу, и мы свяжемся с Вами удобным для Вас способом.",
            // video: ["//video.platformcraft.ru/embed/658aecadef3db51ca61a4e4a"],
          },
        },
      },
    },
  },

  // Corporate Communication Page
  corporate: {
    title: "Corporate Communication",
    view: "View",
    createClaim: "Create a request",
    suitableFor: "Suitable for what?",
    suitableParagraph:
      "Unique offering in the mobile market - value for money and exclusive features set RC Mobile customers apart from other mobile operators",
    suitable: "Suitable",
    forTraveling: "For travel",
    forCorporateTasks: "For corporate tasks",
    forBusinessTrips: "For business trips",
    benefits: "What are the benefits?",
    easyConnection: "Easy Connection",
    easyConnectionDescription:
      "Automatic detection of the optimal mobile operator allows users to pay at the rates of local telecom operators where RC Mobile operates",
    favorableRates: "Favorable Rates",
    favorableRatesDescription:
      "After paying the tariff, regardless of the region of residence of the SIM card holder, he can use the tariff without additional costs without changing the mobile operator",
    technicalSupport: "Technical support",
    technicalSupportDescription:
      "Prompt and convenient support from RC service team advises RC Mobile users on all emerging issues directly from the application in the item  'Support'",
    ourTariffs: "Our Tariffs",
    modernApplication: "Modern Application",
    countSimCard: "Number of SIM cards",
    purposesPurchaseSIMCards: "For what purposes do you purchase SIM cards",
    thanksForClaim:
      "Thank you for your application. <br> A manager will contact you shortly to clarify the details.",
    forUseAbroad: "For use abroad",
    foUseYourCountry: "For use in your country",
    newGenerationMobileCommunication: "New generation mobile communication!",
    joinUs:
      "Join us now by downloading the application from our technology partner Glonass",
    countSim: "Number of SIM cards",
    enterFullName: "Enter your full name",
    enterEmail: "Enter E-mail",
    contactPhone: "Contact phone",
    enterPhone: "Enter Phone",
    writeCountOfSIM:
      "Specify how many SIM cards you would like to order for corporate use",
    different: "Other",
    comment: "Comment",
    commentDescription: "Enter acquisition purpose",
  },

  // Answer moderation
  status: {
    moderation: "Under moderation",
    confirmation: "Confirmed",
    reject: "Declined",
    paid: "Paid",
    active: "Active",
    locked: "Blocked",
    activateProcess: "In process of activation",
    awaitingPayment: "Pending payment",
    sent: "Sent",
    received: "Received",
    canceled: "Cancelled",
    unknown: "Неизвестно",
    confirm: "Подтвержден",
    base: "Базовая",
  },
  // Table
  table: {
    status: "Status",
    nameStatus: "Status name",
    percentageAccrual: "Percent of reward",
    fullName: "Name",
    position: "Position",
    phone: "Phone number",
    login: "Login",
    number: "Number",
    sum: "Amount",
    customer: "Buyer",
    cashback: "Cashback",
    currency: "Currency",
    dateTime: "Date and time",
    date: "Date",
    paid: "Paid",
    purchase: "On purchase for",
    transaction: "Transaction",
    type: "Type",
    points: "Points",
    name: "Name",
    visits: "Frequency of visits",
    averageCheck: "Amount of purchases",
    birthday: "Birthday",
    age: "Age",
    nameAndSurname: "Surname and name",
    store: "Outlet",
    entityName: "Organization name",
    operationType: "Operation Type",
  },
  // Common text
  common: {
    organizationSelect: "Select entity",
    allOrganizationCheckbox: "",
    tradingPointsSelect: "Select outlet",
    allTradingPointsCheckbox: "All outlets",
    from: "from",
    util: "to",
    to: "till",
    fillField: "Fill in this field",
    invalidEmail: "Wrong email",
    minLengthPass: "Password should contain at least 8 symbols",
    fillTime: "Fill in the time",
    calendar: "Calendar",
    filter: "Filter",
    back: "Back",
    loadDescription: "Please upload the product halalness certificate: ",
    loadFormat: "Certificates in .PDF .JPG .PNG are supported",
    loadSize: "The file size may not exceed 10 MB",
    dropFiles: "Drag the file here or",
    uploadFiles: "upload",
    chooseFile: "Select file",
    fileName: "File name",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
    dayAndNightTime: "The outlet is open round-the-clock",
    notWorkTime: "The outlet is closed",
    month: "month",
    mo: "mo",
    year: "year",
    organizations: "Entities",
    shops: "Outlets",
    employees: "Employees",
    managers: "Managers",
    manager: "Manager",
    owner: "Owner",
    business: "Business",
    tariffs: "Tariffs",
    historyOperations: "Transaction history",
    actsAndAccounts: "Statements and invoices",
    cashback: "Points accrual",
    documents: "Documents",
    addBalance: "Advance payment",
    addPoints: "Scoring",
    application: "Payment at checkout terminal",
    claims: "Requests",
    analytics: "Insights",
    sortNew: "By novelty",
    sortAscStars: "By star reduction",
    sortDscStars: "By star addition",
    email: "Email",
    lastName: "Surname",
    firstName: "Name",
    error404: "Error 404",
    noPage: "Page not found",
    errorTypeFile: "Wrong file type!",
    tryTypeFile: "The file should be jpg or png",
    clients: "Customers",
    exitAccount: "Log out",
    accountsForAct: "Invoice against statement",
    downloadOn: "available in",
    downloadOnRuStore: "download from",
    phone: "Phone",
    comment: "Comment",
    pcs: "PCS",
    gb: "GB",
    min: "min",
    group: "Группа",
    emptyField: "Заполните все поля",
    correctField: "Все поля должны быть корректны!",
    bigFile: "Большой размер файла",
    "e-menu": "Электронное меню",
  },
  // Buttons
  button: {
    invite: "Invite",
    download: "Download",
    edit: "Edit",
    save: "Save",
    saveChange: "Save changes",
    accept: "Accept",
    change: "Change",
    login: "Log in",
    create: "Create",
    confirm: "Confirm",
    details: "More",
    copy: "Copy",
    topUp: "Top up",
    back: "Back",
    send: "Send",
    sendAgain: "Resend",
    sendCode: "Send code",
    next: "Next",
    proceed: "Continue",
    buy: "Buy",
    goto: "Go to",
    open: "Open",
    delete: "Delete",
    cancel: "Cancel",
    pay: "Pay",
    ok: "OK!",
    cancelClaim: "Cancel claim",
    generate: "Сгенерировать",
    apply: "Применить",
    reset: "Сбросить",
    clearAll: "Очистить все",
    calendarButton: {
      today: "Сегодня",
      yesterday: "Вчера",
      lastWeek: "Последние 7 дней",
      last30Days: "Последние 30 дней",
      lastMonth: "Прошлый месяц",
      thisMonth: "Этот месяц",
      lastYear: "Последний год",
      thisYear: "Текущий год",
      allPeriod: "Весь период",
      selectRange: "Свой период",
    },
    preview: "Предпросмотр",
    resetAll: "Сбросить все",
    showResult: "Показать результаты",
    upload: "Загрузить",
    expand: "Развернуть",
    hide: "Скрыть",
    try: "Попробовать",
    add: "Добавить",
    reveal: "Показать",
    readAll: "Читать полностью",
    collapse: "Свернуть",
    logout: "Logout",
    show: "Показать",
    moreDetails: "Подробнее",
    settings: "Настройки",
  },
  month: {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  },
  charts: {
    pointsAccrued: "Points accrued",
    pointsWritten: "Points debited",
  },

  tips: {
    instruction: {
      title: "Инструкция для работы чаевых",
      tipsTitle: "Безналичные чаевые",
      tipsDescription:
        "Помощь ресторанному бизнесу и салонам красоты <br> 80% посетителей заведений оплачивают товары и услуги картами, а не наличными средствами. Это приводит к падению мотивации сотрудников и качества обслуживания: ведь персонал перестает получать чаевые. Безналичные чаевые — сервис, позволяющий мастерам и официантам получать чаевые от клиентов. Бизнес ничего не теряет — все комиссии лежат на мастерах и клиентах, к тому же чаевые не облагаются налогом.",
      howTitle: "Как это работает?",
      howDescription:
        "Персонал регистрируется в системе сервиса <br> Каждый зарегистрированный участник получает QR-код <br> QR-код можно разместить на чеке, визитке или любой другой печатной продукции <br> Клиент переводит чаевые по QR-коду <br> Персонал получает деньги <br> Успех! <br> Клиент также может взять на себя комиссию: тогда сотрудник получит полную сумму чаевых.",
    },
    presentation: "Презентация",
    forRestaurants: "Для ресторанов",
    forShops: "Для салонов",
    arrange: "Оформить заявку",
  },

  eMenu: {
    title: "Электронное меню",
    hint: '<p>С подробной информацией о работе функционала «Электронное меню» Вы можете ознакомиться в разделе Мои предприятия -  <a href={link} target="_blank">Документы</a> - Маркетинговые материалы - Инструкция по использованию RC CITY</p>',
    searchPlaceholder: "Поиск по товарам",
    productsStatuses: {
      all: "Все товары",
      moderation: "Модерация",
      reject: "Отклоненные",
    },
    categoriesStatuses: {
      all: "Все категории",
      moderation: "Модерация",
      reject: "Отклоненные",
    },
    noData: {
      title: "Данных по электронному меню нет",
      noTradingPoints: "У вас пока нет Торговых точек ",
      noProducts: "У вас пока не создано ни одного товара",
    },
    category: "Категория",

    table: {
      productName: "Название товара",
      category: "Категория",
      price: "Цена",
      status: "Статус",
      noCategory: "Без категории",
    },

    qr: "QR - код",

    QRModalTitle: "QR-код на меню",
    QRModalSubTitle:
      "Выберите торговую точку, QR-код на меню которой хотите скачать",

    productCard: {
      createTitle: "Создание карточки товара",
      editTitle: "Информация о товаре",
      images: "Изображения",
      productName: "Название товара",
      price: "Цена, {currency}",
      discount_price: "Цена со скидкой, {currency}",
      category: "Категория",
      description: "Описание",
      descriptionMax: "Макс. 1000 символов",
      image: {
        cover: "Обложка",
        coverTitle: "Загрузить обложку",
        cropPhoto: "Обрежьте фото",
        imageTitle: "Загрузить изображение",
        tooltip:
          "Добавьте обложку и изображения к товару. Максимальное количество изображений — 5.",
      },
      moderationMessageTitle: "Ответ модерации",
      dimensions: "Единицы измерения",
      dimensionsPlaceholder: "Выберите единицу измерения",
      noDimension: "Без единиц измерения",
    },

    pointsListTitle: "Отображение в торговой точке",

    deleteProduct: "Вы уверены, что хотите удалить “{label}”?",
    hideProduct: "Вы уверены, что хотите скрыть “{label}”?",
    revealProduct: "Вы уверены, что хотите показать “{label}”?",
    categoryPlaceholder: "Выберите категорию",

    limits: {
      lite: "Вы создали 20 товаров, ваш лимит исчерпан. <br> Чтобы добавить больше позиций, повысьте тариф до Promo.",
      promo:
        "Вы создали 150 товаров, ваш лимит исчерпан. <br> Для добавления товаров без ограничений повысьте тариф до Pro+",
      tariffs: "Тарифы",
    },
  },

  validationErrors: {
    emptyField: "Это поле не может быть пустым",
    invalidEmailFormat: "Введите email вида {email}",
    invalidSiteFormat: "URL должен содержать http:// или https://",
    invalidFormat: "Неверный формат",
    incorrectValueStart: "Необходимо указать значение от",
    incorrectValueEnd: "до",
    invalidValueMaxLength: "Максимальное количество символов ",
    invalidValueMinLength: "Минимальное количество символов ",
    invalidHouseNumber: "Уточните номер дома",
    invalidAddress: "Уточните адрес",
    invalidWorkDays: "Необходимо выбрать хотя бы один рабочий день",
    invalidWorkTime: "Неверный формат времени",
    errorWorkTime:
      "Время открытия не может быть позже времени закрытия или совпадать с ним",
    notEnoughElement: "Недостаточное количество элементов",
    manyElement: "Вы превысили максимальное количество элементов",
    maxLessMin: "Меньше минимального",
    invalidAge: "Некоректный возраст",
    minDate: "Дата должна быть не позднее {date}",
    maxDate: "Дата должна быть не ранее {date}",
    requiredPhone: "Enter the phone number",
    reqiredPromocode: "You have entered invalid promo code",
    requiredCountry: "Select a country",
    havePhone: "This phone has been already registered",
    haveMail: "This email has been already registered",
    invalidEmail: "Invalid email",
    email: "Enter email",
    first_name: "Enter name",
    last_name: "Enter surname",
    password: "Enter password",
    corresp_account: "Enter correspondent account",
    number_account: "Enter transaction account number",
    bik_of_bank: "Enter bank BIC",
    international_bank: "Enter international bank account number",
    vat_code: "Enter VAT code",
    inn: "Enter taxpayer identification number",
    entityAddress: "Enter registered office",
    fullName: "Enter your name",
    ogrn: "Enter OGRN",
    monthly_turnover: "Enter monthly turnover",
    deposit_sum: "Enter deposit amount",
    tax_system: "Enter taxation type",
    legal_type: "Enter legal entity’s incorporation form",
    okved_code: "Enter key activity type",
    minFillingPhotos: "Minimal number of files: {amount}",
    notValidPromocode: "Promo code does not exist",
    cyrillicOnly: "Cyrillic and Latin only ",
  },

  //  marketplaces Page
  marketplaces: {
    title: "Маркетплейсы",
    inn: "ИНН",
    phone: "Номер телефона",
    link: "Ссылка на сайт (не обязательно)",
    error: "Поля заполнены неверно",
  },

  promotions: {
    title: "Акции",
    createNewPromo: "Создать новую акцию",
    headersList: {
      name: "Название акции",
      tradingPoint: "Торговая точка",
      cashback: "Процент начисления",
      discount: "Процент скидки",
    },
    name: "Название акции",
    cashback: "Процент начисления",
    discount: "Процент скидки",
    createTitle: "Создание новой акции",
    aboutPageModal: {
      title: "Функционал подраздела “Акции”",
      body:
        'Подраздел "Акции" помогает проводить товары с разным значением скидки и разным значением начисления' +
        "вознаграждения. Для того чтобы создать акцию, нажмите соответствующую кнопку в подразделе “Акции“, затем," +
        " в всплывающем окне укажите торговую точку, название акции, процент начисления и процент скидки.\n" +
        "После успешного создания акции, зайдите как сотрудник в RC Кассу и нажмите на иконку всплывающего " +
        "меню в правом верхнем углу смартфона. Нажав на кнопку “Акции“, выберите созданную в кабинете акцию и " +
        "проведите стандартную операцию списания или начисления баллов.",
    },
    delete: "Вы уверены, что хотите удалить ",
    editTitle: "Редактор акций",
    deleteTitle: "Удалить акцию",
    activate: "Активировать акцию",
    deactivate: "Деактивировать акцию",
    noPromotions: "У вас еще нет акций",
    createWarning: "*До трёх активированных акций на одну торговую точку",
  },

  tooltips: {
    tradingPoints: {
      name: "Данное название будет отображаться у пользователей в клиентском сервисе.",
      tags: "Будут использоваться для поиска Вашего заведения в клиентском сервисе. Правила написания: начало с букв русского/латинского алфавита, цифр,  до 50 символов, возможны с нижним подчеркиванием. Максимальное количество тегов - 20 шт.",
      description: "Будет отображаться у пользователей в клиентском сервисе.",
      businessType:
        "Служит для фильтрации и поиска Вашего заведения в клиентском сервисе.",
      isBonusPayable:
        "Укажите процент скидки, который будет предоставляться пользователям экосистемы RC Group, при покупке любого товара.",
      cashbackPercent: "Укажите общий процент вознаграждения. ",
      addressComment:
        "Вы можете указать уточнения к адресу, а именно: как пройти, предварительно позвонить и другую сопутствующую информацию.",
      links:
        'В "Название ссылки" укажите как бы Вы хотели, чтоб отображалась ссылка в клиентском сервисе. Например, https://vk.com/id123456  -  petrivanov.',
      logo: "Изображение с фирменным знаком, которое будет использоваться в качестве логотипа вашей торговой точки.",
      filling:
        "Должны быть в максимально хорошем качестве, не содержать надписи, коллажи и водяные знаки",
      banner:
        "Обложка вашего заведения в каталоге торговых точек клиентского сервиса. Не должна быть логотипом, коллажом, содержать надписи и водяные знаки",
      activeWait:
        "Количество дней, через которые будет начислено вознаграждение",
    },
  },

  globalMessagesButton: {
    newTradingPointPage: "Перейти в новый подраздел “Торговые точки”",
    "clients-update": "Попробовать",
    "product-release": "Попробовать",
    "review-update-release": "Попробовать",
  },

  declinationHelperWords: {
    years: [" лет", " год", " года"],
    days: [" дней", " день", " дня"],
    grades: [" оценок", " оценка", " оценки"],
    symbols: [" символов", " символа", " символов"],
    result: [" рузальтатов", " результат", " результата"],
    review: [" отзывов", " отзыв", " отзыва"],
    star: [" звезд", " звезда", " звезды"],
    clients: [" клиентов", " клиент", " клиента"],
    product: [" товаров", " товар", " товара"],
  },

  noData: {
    title: "Данных нет",
    emptyHistory: "Ваша история пуста",
  },

  mobileFiltersName: {
    "trading - points": "Торговые точки",
    category: "Категория",
    "product-status": "Статус товара",
    "review-status": "Тип отзыва",
    sort: "Сортировка",
    date: "Период",
  },

  debt: {
    title: "Задолженность",
    text:
      "Оплатите ее с помощью счета, отправленного на электронную почту, или создайте его самостоятельно\n" +
      "в разделе “Баланс”",
    link: "Перейти к погашению",
    message:
      "Вам необходимо оплатить задолженность в размере {debt}&nbsp;{currency} <br>" +
      "Оплату можно произвести одним из способов: создать счет в личном кабинете или скачать счет, отправленный на электронную почту.",
  },

  paymentMethod: {
    title: "Способы оплаты",
    hint: "Быстрое и простое увеличение вариантов оплаты в вашей торговой точке с помощью инновационного платежного решения для бизнеса RC POS",
    nodataTitle: "Данных по способам оплаты нет",
    nodataDescription: "У вас пока нет торговых точек ",
    noModerationDataDescription:
      "У вас пока нет торговых точек, прошедших модерацию",
    haveAccountTitle: "Ваша почта уже зарегистрирована в RC POS",
    haveAccountText:
      "Если у вас ранее не были подключены способы оплаты, перейдите в свой кабинет RC POS",

    create: {
      firstStep: {
        asideTitle: "Несколько шагов и инновационное решение у вас в кармане!",
        asideText:
          "<p>Эта разработка позволяет не использовать дополнительное оборудование — нет необходимости приобретать кассу, терминал \n" +
          "и фискальный накопитель, а чековую ленту успешно заменили электронные чеки. </p>" +
          "<p>Подключение дополнительных способов оплаты\n" +
          "доступно на одну торговую точку.</p>" +
          "<p>Требования законодательства и партнерских договоров не позволяют нам обслуживать некоторые категории бизнеса:</p>",

        asideText_open:
          "<ul>" +
          "<li>— продающие алкоголь любого вида;</li>" +
          "<li>— продающие табачные изделия, наркосодержащие препараты, курительные смеси и их аналоги, включая электронные сигареты и их наполнители;</li>" +
          "<li>— продающие лекарственные средства, включая БАДы;</li>" +
          "<li>— продающие товары и контент сексуальной направленности («товары для взрослых», секс-шопы и т.п.);</li>" +
          "<li>— продающие б/у запчасти для автомобилей;</li>" +
          "<li>— продающие огнестрельное и холодное оружие, запасные части и боеприпасы к нему, а также любые товары и услуги военного назначения;</li>" +
          "<li>— организующие куплю-продажу валюты, включая криптовалюты, ценных бумаг, финансовых инструментов и их аналогов;</li>" +
          "<li>— связанные с азартными играми;</li>" +
          "<li>— букмекерские конторы;</li>" +
          "<li>— продающие товары или оказывающие услуги, нарушающие действующее законодательство или способные ввести в заблуждение или обмануть покупателя.</li>" +
          "</ul>" +
          '<p>С полным <a target="_blank" href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/ограничения.pdf">списком ограничений</a> можно ознакомиться в документе ограничений </p>',
        asideSubText:
          "На этом этапе происходит создание кабинета в RS POS. <br> Логин и пароль для входа в RC POS будут отправлены на указанную при регистрации почту.",
        //статичные данные
        title: "Юридическое лицо",
        tradingPoint: "Торговая точка",
        inn: "ИНН:",
        bic: "Бик банка:",
        headName: "Ф.И.О. руководителя:",
        bankName: "Название банка:",
        organizationType: "Тип организации:",
        currentAccountNumber: "Номер расчетного счета",
        address: "Юридический адрес:",
        correspondentAccount: "Корсчет:",
        // изменяемые даннык
        entityType: "Форма юрлица",
        taxType: "Тип налогообложения",
        monthlyTurnover: "Размер ежемесячного оборота",
        ogrn: "ОГРН",
        okved: "Основной вид деятельности",
        phone: "Телефон",
        oferta:
          'Я принимаю условия <a href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/Договор%20о%20присоединении%20к%20RC%20POS.pdf">договора</a> сервиса RC POS',
      },
      secondStep: {
        title1: "Выберите подключаемые способы оплаты",
        online: "Онлайн",
        sbp: "СБП",
        // блок 2
        title2: "Личные данные",
        passportSeries: "Серия паспорта",
        passportNumber: "Номер паспорта",
        issuedPlace: "Кем выдан",
        dateOfIssue: "Дата выдачи",
        departmentCode: "Код подразделения",
        dateOfBirth: "Дата рождения",
        placeOfBirth: "Место рождения",
        // блок 3
        title3: "Данные о компании",
        headPosition: "Должность руководителя",
        VATPayer: "Плательщик НДС",
        procents: "Процентная ставка",
        procentsHint:
          "Будет внесена автоматически после прохождения проверки данных",
        preferredCommunicationChanel: "Канал для связи",
        factAddress: "Фактический адрес",
        processSellingGoodsAndServices: "Процесс реализации товаров/услуг",
        processSellingGoodsAndServicesHint:
          "Как это происходит и где, если у вас нет торговой точки/офиса",
        descriptionOfActivity: "Описание деятельности",
        descriptionOfActivityHint: "За что будет приниматься оплата",
        note: "Примечание",
      },
      thirdStep: {
        title: "Подписание документа",
      },
    },

    card: {
      tabs: {
        entities: "Юрлицо",
        "payment-methods": "Способы оплаты",
        documents: "Документы",
      },
    },
  },

  registration: {
    loyalty:
      'I accept the terms and conditions of RC Group partner  <a target="_blank" href="{link}">loyalty program</a>',
    posOferta:
      'I accept the terms and conditions of RC POS <a target="_blank" href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/Договор%20о%20присоединении%20к%20RC%20POS.pdf">service offer</a>',
    1: "Basic details",
    2: "Personal details",
    3: "Legal entity",
    haveAcc: "Do you have an account?",
    login: "Log in",
    mainInfo: {
      title: "Basic details",
      promocode: "Promo code",
      country: "Country",
      phone: "Phone number",
      checkbox:
        " I hereby agree to processing of my personal data in accordance with the <a download href='{link}'>Privacy Policy</a>",
    },

    profileInfo: {
      title: "Personal details",
      firstName: "Name",
      lastName: "Surname",
      middleName: "Patronymic name",
      email: "Email",
      password: "Password",
      confPassword: "Repeat password",
      registerCode: "Code from email message",
      notSendCode: "No code received?",
      sendAgain: "Please send again",
    },

    emailCode: {
      title: "Confirmation code has been sent to your email",
      subtitle:
        'If you haven\'t received email at "{mail}", please check your Spam folder',
      emailCode: "Code from email message",
      sendAgainTime: "Send email again after",
      dontSend: "No email received?",
      sendAgain: "Please send again",
      haveInn:
        "A legal entity with this taxpayer identification number already exists",
      addressWithoutNumber:
        "Необходимо указать номер здания/сооружения, помещения",
      login: "Введите логин",
    },

    entity: {
      title: "Legal entity",
      subTitle: "Please check the entered data before you click Next",
      entityType: "Legal entity type",
      halalCertificate: "The legal entity is a holder of Halal certificate ",
      fileType: "PNG, JPG, PDF - 1 file, max 10 Mb",
      commercial: "Commercial",
    },

    payment: {
      title: "Thank you for registration!",
      subtitle:
        "Now you can use your RC Group loyalty program account. Moderation is underway. Normally, verification takes 24 to 48 hours",
      examination:
        "To get verified, you need to pay the invoice from the specified legal entity by downloading the invoice below. You can also find the invoice in your account: in My Companies - Documents - Test Payment.",
      pos: "You will get access to RC POS service after verification completion. Login data will be sent to email specified in process of registration.",
      toCabinet: "Go to account",
    },
  },

  permissions: {
    analytics: "Аналитика",
    "legal-entity": "Юрлица",
    "trading-point": "Торговые точки",
    employee: "Кассиры",
    document: "Документы",
    tariff: "Тарифы",
    operation: "История операций",
    "act-and-receipt": "Акты и счета",
    manager: "Менеджеры",
    cashback: "Начисление баллов",
    balance: "Баланс",
    points: "Очки кассирам",
    // 'claim': 'claim',
    client: "Список клиентов",
    spread: "Распространение  карт Wallet",
    "wallet-pass": "Дизайн карты Wallet",
    review: "Отзывы",
    // 'application': 'application',
    "noncash-tips": "Безналичные чаевые",
    "check-counterparties": "Проверка контрагентов",
    "push-notifications": "Push-уведомления",
    // 'group': 'Начисление баллов (группы клиентов)',
    "client-portrait": "Портрет клиента",
    "active-wait": "Начисление баллов (Активное ожидание)",
    "e-menu": "Электронное меню",
    faq: "FAQ",
    terminal: "Processing",
    promotion: "Акции",
    cashback_group: "Начисление баллов (Группы клиентов)",
    payment_info: "Способы оплаты",
    orders: "Предзаказы",
    "trading-point-promotion": "Продвижение",
    "gift-card": "Подарочные карты",
  },

  promotionServices: {
    servicesList: {
      "xxl-tag": {
        name: "XXL-размер карточки",
        description:
          "Увеличение размера карточки вашего заведения и размещение на ней бейджа «Топ место»",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет увеличена в размере, а также выделена бейджем «Топ место» на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Срок размещения – 1 неделя, 1 месяц или 3 месяца с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>Выбрать срок размещения можно на этапе оформления заказа.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      tag: {
        name: "Бейдж «Топ место»",
        description:
          "Размещение на карточке вашего заведения бейджа «Топ место» и перемещение её выше в общем списке",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет выделена бейджем «Топ место» и перемещена выше в списке на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Срок размещения –  1 неделя, 2 недели, 1 месяц или 3 месяца с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>Выбрать срок размещения можно на этапе оформления заказа.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      top: {
        name: "Топ 1 или 2 место",
        description:
          "Размещение карточки вашего заведения на 1 или 2 местах в списке (без изменения её внешнего вида и размера)",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет размещена на 1 или 2 месте на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Размещение на 1 или 2 месте определяется очередностью оформления заказа и оплаты данной услуги.</p></li>" +
          "<li><span>●</span><p>Срок размещения – 2 недели с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      hint: "В настоящий момент все доступные слоты по данной услуге в городе вашей торговой точки заняты. Заказ будет возможен с указанной даты.",
    },
    periodsList: {
      "7 days": "1 неделя",
      "14 days": "2 недели",
      "30 days": "1 месяц (30 дн)",
      "90 days": "3 месяца (90 дн)",
    },
  },

  //   pre-orders page
  preOrders: {
    title: "Предзаказы",
    notAvailablePreOrders: "Предзаказы пока недоступны",
    noOrders: "Заказов пока нет",
    noTradingPoints:
      "<p>У вас пока нет торговых точек, которые прошли модерацию и размещены на сайте <a class='blue-link' href='https://rcpay.ru/places/' target='_blank'>rcpay.ru</a> и в мобильном приложении RC PAY</p>",
    noProducts:
      "Чтобы принимать заказы необходимо добавить товары или услуги в электронное меню",
    noOrdersText:
      "<p>Чтобы ваши товары или услуги были доступны для заказа на сайте <a class='blue-link' href='https://rcpay.ru/places/' target='_blank'>rcpay.ru</a> и в мобильном приложении RC PAY, необходимо добавить их в электронное меню и включить прием предзаказов в настройках</p>",
  },
};
