<script>
import vClickOutside from "click-outside-vue3";
import ButtonIcon from "@/components/common/ButtonIcon.vue";
import ButtonWrapper from "@/components/common/ButtonWrapper.vue";

export default {
  name: "NavSwitch",
  components: { ButtonWrapper, ButtonIcon },

  directives: {
    ClickOutside: vClickOutside.directive,
  },

  emits: ["update:model-value"],

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    listPosition: {
      type: String,
      default: "top-right",
    },

    useForLabel: {
      type: String,
      default: "name",
    },

    withoutMobileStyle: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      langListShow: false,
      languages: [],
    };
  },

  watch: {
    modelValue: {
      handler(newValue) {
        this.languages = this.options.filter(
          (option) => option.value !== newValue
        );
      },
    },
  },

  computed: {
    selectedLang() {
      if (this.modelValue) {
        return (
          this.options.find((lang) => lang.value.includes(this.modelValue))[
            this.useForLabel
          ] ?? ""
        );
      }

      return "ru";
    },

    arrowRotateAngle() {
      if (
        this.listPosition.split("-")[0] === "top" ||
        this.listPosition.split("-")[1] === "top"
      ) {
        return !this.langListShow;
      } else {
        return this.langListShow;
      }
    },
  },

  methods: {
    selectLanguage(lang) {
      this.$emit("update:model-value", lang);
      this.langListShow = false;
    },

    clickOutside() {
      this.langListShow = false;
    },
  },

  mounted() {
    this.languages = this.options.filter(
      (option) => option.value !== this.modelValue
    );
  },
};
</script>

<template>
  <div v-click-outside="clickOutside" class="language-switch">
    <div class="language-switch__selector selector">
      <ButtonWrapper
        class="selector__lang"
        :class="`selector__lang_${useForLabel}`"
        @click="langListShow = !langListShow"
      >
        {{ selectedLang }}
        <span
          class="arrow icon-chevron-thin-20"
          :class="{ arrow_up: arrowRotateAngle }"
        />
      </ButtonWrapper>

      <div
        v-if="langListShow"
        class="selector__lang-list lang-list"
        :class="[
          `lang-list_${listPosition.split('-')[0]}`,
          `lang-list_${listPosition.split('-')[1]}`,
          { 'lang-list__mobile': !withoutMobileStyle },
        ]"
      >
        <ButtonIcon
          v-for="lang in languages"
          :key="lang.value"
          class="lang-list__item"
          @click="selectLanguage(lang.value)"
        >
          <span class="flag" :class="lang.name" />

          <p>{{ lang.label }}</p>
        </ButtonIcon>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.language-switch {
  color: var(--color-semantic-content-normal-primary);

  &__selector {
    position: relative;
  }
}

.selector {
  &__lang {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    padding: 0;
    @include body;

    &_name {
      text-transform: uppercase;
    }
  }
}

.lang-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.35);
  border-radius: 8px;
  background: var(--color-semantic-background-normal-primary);
  @include body;
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  padding: 8px 0;

  &_top {
    bottom: calc(0px + 100% + 8px);
  }

  &_bottom {
    top: calc(0px + 100% + 8px);
  }

  &_right {
    right: 0;
  }

  &_left {
    left: 0;
  }

  &__item {
    padding: 10px 24px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;

    &:hover {
      cursor: pointer;
    }

    > p {
      color: var(--color-semantic-content-normal-primary);
    }
  }
}

.arrow {
  width: 16px;
  height: 16px;
  background: var(--color-semantic-content-normal-primary);
  transition: 0.3s;

  &_up {
    transform: rotate(-180deg);
  }
}

@media (max-width: 1279px) {
  .selector {
    &__lang {
      width: 100%;
      justify-content: space-between;
    }
  }

  .lang-list__mobile {
    width: 100%;
    position: relative;
    background: none;
    box-shadow: none;

    &__item {
      padding: 10px 0;
    }
  }
}
</style>
