import { API } from "../boot/api";

const state = {
  system_country: localStorage.country || "ru",
  system_countries: [],
};

const getters = {};

const mutations = {
  SET_SYSTEM_COUNTRY(state, payload) {
    state.system_country = payload;
  },

  SET_SYSTEM_COUNTRIES(state, payload) {
    state.system_countries = payload;
  },
};

const actions = {
  getSystemCountries({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("supported_countries")
        .then((response) => {
          commit("SET_SYSTEM_COUNTRIES", response.data);
          const searchParams = new URLSearchParams(window.location.search);
          if (
            window.location.search.length > 0 &&
            searchParams.has("country")
          ) {
            localStorage.country = searchParams.get("country");
          } else if (localStorage.country) {
            commit("SET_SYSTEM_COUNTRY", localStorage.country);
          } else {
            localStorage.country = window.location.href.includes("kz")
              ? "kz"
              : "ru";
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
