<script>
import { mapActions } from "vuex";
import { switchRedirect } from "@/package/global-helpers/messageModalHelper";
import UiModal from "@/ui/UiModal/UiModal.vue";
import UiButton from "@/ui/UiButton.vue";

export default {
  name: "SectionUpdateMessageModal",

  components: { UiButton, UiModal },

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    modalData: {
      type: [Object, null],
      required: true,
    },
  },

  computed: {
    _modelValue: {
      get() {
        return this.modelValue;
      },

      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },

  methods: {
    ...mapActions({
      notificationMarkAsReady: "auth/notificationMarkAsReady",
    }),

    goToPage(id, pageType) {
      this.notificationMarkAsReady(id).then(() => {
        this._modelValue = false;

        this.$router.push(switchRedirect(pageType));
      });
    },
  },
};
</script>

<template>
  <UiModal v-model="_modelValue">
    <template #title>
      <h4 class="title" v-html="modalData.header" />
    </template>

    <template #body>
      <p v-html="modalData.message" />
    </template>

    <template #footer>
      <UiButton
        :label="$t(`globalMessagesButton.${modalData.subtype}`)"
        color="accent"
        @click="goToPage(modalData.id, modalData.subtype)"
      />
    </template>
  </UiModal>
</template>

<style lang="scss" scoped>
.title {
  @include title-3;
  color: var(--color-semantic-content-normal-primary);
}
</style>
