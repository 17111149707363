import { createStore } from "vuex";
import auth from "./auth";
import user from "./user";
import entity from "./entity";
import shop from "./shop";
import employee from "./employees";
import tariff from "./tariff";
import actsAndAccounts from "./actsAndAccounts";
import managers from "./managers";
import analytics from "./analytics";
import claim from "./claim";
import wallet from "./wallet";
import terminal from "./terminals";
import portfolio from "./portfolio";
import system from "./system.store";
import groups from "./groups";
import reviews from "./reviews";
import promotions from "./promotions";
import main from "./main.store";
import products from "./products";
import paymentMethods from "./paymentMethods";
import registration from "./registration";
import promotionServices from "./promotionServices";

export default createStore({
  state: {
    notification: null,
    innerWidth: window.innerWidth,
  },

  getters: {
    checkPermissions: () => (permissions) => {
      return permissions.some((permission) => {
        return auth.state.permissions.includes(permission);
      });
    },
  },

  mutations: {
    SET_NOTIFICATION(state, data) {
      state.notification = data;

      const defaultTimer = 2500;
      const timer = data?.timer;
      const isValidTimer =
        !Number.isNaN(Number(timer)) && timer !== undefined && timer !== null;

      const finalTimer = isValidTimer ? Number(timer) : defaultTimer;

      setTimeout(() => {
        state.notification = null;
      }, finalTimer);
    },

    SET_INNER_WIDTH(state, data) {
      state.innerWidth = data;
    },
  },

  actions: {
    createNotification({ commit }, data) {
      commit("SET_NOTIFICATION", null);
      commit("SET_NOTIFICATION", data);
    },

    clearNotification({ commit }) {
      commit("SET_NOTIFICATION", null);
    },
  },

  modules: {
    auth,
    user,
    entity,
    shop,
    employee,
    tariff,
    actsAndAccounts,
    managers,
    analytics,
    claim,
    wallet,
    terminal,
    portfolio,
    system,
    groups,
    reviews,
    promotions,
    main,
    products,
    paymentMethods,
    registration,
    promotionServices,
  },
});
