import fileDownload from "js-file-download";
import { API } from "@/boot/api";

const state = {
  paymentMethodsList: null,
  percentCompleted: 0,
  uploadingStatus: "",
  legalTypeOptions: [],
  taxationOptions: [],
  rcposAccountConnected: false,
  rcposAlreadyRegistered: false,
  accPaymentStatus: "",
  points: [],
  tradingPointsCount: 0,
  confirmedTradingPointsCount: 0,
  availableTradingPointsCount: 0,
  hasApprovedInn: false,
};

const getters = {};

const actions = {
  uploadLegalInfoDoc({ commit }, file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      commit("SET_STATUS_UPLOAD_LEGAL_INFO_DOC", "UPLOADING");

      API.post("entities/payments/legal-info/upload-doc", formData, {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          commit("SET_UPLOAD_PROGRESS", percentCompleted);
        },
      })
        .then((response) => {
          commit("SET_STATUS_UPLOAD_LEGAL_INFO_DOC", "SUCCESS");
          resolve(response);
        })
        .catch((error) => {
          commit("SET_STATUS_UPLOAD_LEGAL_INFO_DOC", "ERROR");
          reject(error);
        });
    });
  },

  createPaymentsMethods(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("entities/payments/legal-info", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPaymentMethodsMainIfo({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("entities/payments/legal-info/create-precondition")
        .then((response) => {
          commit("SET_MAIN_PAYMENT_METHODS_INFO", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPaymentMethodsList({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("entities/payments/legal-info")
        .then((response) => {
          commit("SET_ACC_PAYMENT_METHODS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadPaymentMethodsDoc(_, id) {
    return new Promise((resolve, reject) => {
      API.get(`entities/payments/legal-info/${id}/doc`, {
        responseType: "blob",
      })
        .then((response) => {
          fileDownload(response.data, "Заявление о присоединении.pdf");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createLegalData(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(
        `entities/${
          payload.entity_id ? payload.entity_id : payload.id
        }/legal-data`,
        payload
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTradingPointsForPaymentMethods({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("entities/payments/trading-points")
        .then((response) => {
          commit("SET_POINTS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  moderationPaymentMethods(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(`entities/payments/legal-info/${payload.id}/send-moderation`, {
        document_ids: payload.ids,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_STATUS_UPLOAD_LEGAL_INFO_DOC(state, status) {
    state.uploadingStatus = status;
  },

  SET_UPLOAD_PROGRESS(state, percent) {
    state.percentCompleted = percent;
  },

  SET_MAIN_PAYMENT_METHODS_INFO(state, data) {
    state.rcposAccountConnected = data.rcpos_account_connected;
    state.rcposAlreadyRegistered = data.rcpos_already_registered;
    state.legalTypeOptions = data.legal_type_options;
    state.taxationOptions = data.taxation_options;
    state.tradingPointsCount = data.trading_points_count;
    state.confirmedTradingPointsCount = data.confirmed_trading_points_count;
    state.availableTradingPointsCount = data.available_trading_points_count;
    state.hasApprovedInn = data.has_approved_inn;
  },

  SET_ACC_PAYMENT_METHODS(state, data) {
    state.accPaymentStatus = data.length > 0 ? data[0].moderation.status : null;
    state.paymentMethodsList = data[0];
  },

  SET_POINTS(state, points) {
    state.points = points;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
