import { API } from "@/boot/api";

const state = {
  statusActive: false,
  claims: [],
  params: null, // для логики покупки терминалов
};

const getters = {};

const mutations = {
  SET_STATUS: (state, status) => {
    state.statusActive = status;
  },
  SET_CLAIMS: (state, payload) => {
    state.claims = payload;
  },

  SET_PARAMS: (state, params) => {
    state.params = params;
  },
};

const actions = {
  getTerminalClaims({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("applications", {
        params: {
          select_all: data.all,
          entity_id: data.entity_id,
          page: data.page,
          types: [
            "cash_register_mspos_e_f",
            "fiscal_accumulator_15_months",
            "fiscal_accumulator_36_months",
          ],
        },
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            commit("SET_STATUS", true);
          } else {
            commit("SET_STATUS", false);
          }
          commit("SET_CLAIMS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  detailsOfDelivery(_, data) {
    return new Promise((resolve, reject) => {
      API.post("cdek/widget/details", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createTerminalClaim(_, data) {
    return new Promise((resolve, reject) => {
      API.post("applications", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  aboutTerminalOrder(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`applications/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadInvoiceTerminal(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`invoice/${data.id}/download`, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "invoice.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createOrder(_, data) {
    return new Promise((resolve, reject) => {
      API.post("/applications", {
        full_name: data.full_name,
        email: data.email,
        phone: data.phone,
        comment: data.comment,
        entity_id: data.id,
        products: [
          {
            quantity: 1,
            code: data.terminal_code,
            additional_data: [],
          },
        ],
        delivery: null,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
