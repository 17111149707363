import { API } from "../boot/api";

const state = {
  windowInnerWidth: window.innerWidth,
  isShowSidebar: true,
  isCollapsedSidebar: false,
};

const getters = {};

const mutations = {
  SET_INNER_WIDTH(state, width) {
    state.windowInnerWidth = width;
  },

  SET_IS_SHOW_SIDEBAR(state, value) {
    state.isShowSidebar = value;
  },

  SET_IS_COLLAPSED_SIDEBAR(state, value) {
    state.isCollapsedSidebar = value;
  },
};

const actions = {
  uploadingInImagesBuffer(_, file) {
    return new Promise((resolve, reject) => {
      API.post("images", file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
