import fileDownload from "js-file-download";
import axios from "axios";
import { API } from "@/boot/api";

let lang = localStorage.getItem("selected-language");

if (lang && lang.length > 2) {
  lang = lang.substring(0, 2);
}

const state = {
  user: null,
  commonDocs: [],

  operations: [],
  operationsMeta: null,

  profile: [],
  tariff: "",
  points: null,
  entityById: [],
  balance: null,
  permissions: [],
  clients: [],

  receiptInfo: null,
  totalTradingPoints: 0,
  tradingPointsList: [],

  rdoEntity: null,
  taxSystems: null,
  legalTypes: null,
};

const getters = {
  token: (state) => state.access_token,
  user: (state) => state.user,
  operations: (state) => state.operations,

  checkPermissions: () => (permissions) => {
    return permissions.some((permission) => {
      return state.permissions.includes(permission);
    });
  },
};

const actions = {
  loginManager(_, data) {
    return new Promise((resolve, reject) => {
      API.post("managers/login", data)
        .then((response) => {
          const access_token = response.data.access_token;
          API.defaults.headers.common["Authorization"] =
            "Bearer " + access_token;
          localStorage.setItem("access_token", access_token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  login(_, data) {
    return new Promise((resolve, reject) => {
      API.post("auth/login", data)
        .then((response) => {
          const access_token = response ? response.data.access_token : "";
          API.defaults.headers.common["Authorization"] =
            "Bearer " + access_token;
          localStorage.setItem("access_token", access_token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      API.post("managers/logout")
        .then((response) => {
          delete API.defaults.headers.common["Authorization"];
          localStorage.removeItem("access_token");
          resolve(response);
        })
        .catch((error) => {
          delete API.defaults.headers.common["Authorization"];
          localStorage.removeItem("access_token");
          reject(error);
        });
    });
  },

  logoutOwner() {
    return new Promise((resolve, reject) => {
      API.post("auth/logout")
        .then((response) => {
          delete API.defaults.headers.common["Authorization"];
          localStorage.removeItem("access_token");
          resolve(response);
        })
        .catch((error) => {
          delete API.defaults.headers.common["Authorization"];
          localStorage.removeItem("access_token");
          reject(error);
        });
    });
  },

  passwordReset(_, data) {
    return new Promise((resolve, reject) => {
      API.post("/password/forgot", {
        email: data.email,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  newPassword(_, data) {
    return new Promise((resolve, reject) => {
      API.post("password/reset", {
        token: data.token,
        email: data.email,
        password: data.password,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addCoin(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`entities/${data.id}/balance `, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadFile(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`/invoice/${data.invoice}/download`, {
        params: {},
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
        .then((response) => {
          fileDownload(response.data, "payment.pdf");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadOperations({ commit }, params) {
    return new Promise((resolve, reject) => {
      API.get("account/operations", params)
        .then((response) => {
          commit("SET_OPERATIONS", response.data.data);
          commit("SET_OPERATIONS_META", response.data.meta);

          resolve(response);
        })
        .catch((error) => {
          commit("SET_OPERATIONS", []);
          commit("SET_OPERATIONS_META", {
            per_page: 20,
            last_page: 1,
            current_page: 1,
          });
          reject(error);
        });
    });
  },

  loadReceiptInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`receipts/${payload.receiptId}`)
        .then((response) => {
          commit("SET_RECEIPT_INFO", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCommonDocuments({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("account/documents", {
        headers: {
          "Accept-Language": lang,
        },
      })
        .then((response) => {
          commit("SET_COMMON_DOCS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getProfileInformation({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("account/profile")
        .then((response) => {
          localStorage.country = response.data.account.country_code;

          commit("SET_PROFILE_PERMISSIONS", response.data.account.permissions);
          commit(
            "SET_PROFILE_BALANCE",
            response.data.account.main_entity.balance
          );
          commit("SET_PROFILE", response.data.account);
          commit("SET_PROFILE_TARIFF", response.data.account.tariff.code);
          commit(
            "SET_TOTAL_TRADING_POINTS",
            response.data.account.trading_points.total
          );
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getProfilePoints({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`account/${data.id}/points`)
        .then((response) => {
          commit("SET_PROFILE_POINTS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeProfilePoints({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.put(`account/${data.id}/points`, {
        point_operation: data.point_operation,
        point_new_customer: data.point_new_customer,
      })
        .then((response) => {
          commit("SET_PROFILE_POINTS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  agreementUser(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`account/${data.account}/agreement`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  findInDadata(_, data) {
    return new Promise((resolve, reject) => {
      API.post("entities/dadata", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  findBankInDadata(_, data) {
    return new Promise((resolve, reject) => {
      API.post("entities/dadata/banks-data", {
        bik: data.bik,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadDocument(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(data.link, {
          withCredentials: true,
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          fileDownload(response.data, `${data.name}`);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  openPDF(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(data.link, {
          withCredentials: true,
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const arrayBuffer = response.data;
          const blob = new Blob([arrayBuffer], { type: "application/pdf" });

          if (data.openImmediately) {
            const url = window.URL.createObjectURL(blob);
            const pdfWindow = window.open();
            pdfWindow.location.href = url;
          } else {
            const url = window.URL.createObjectURL(blob);
            resolve({ blobUrl: url, blob });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCabinetDocuments(_, options) {
    return new Promise((resolve, reject) => {
      API.post("documents/private", options, {
        headers: {
          "Accept-Language": lang,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPublicDocument(_, code) {
    return new Promise((resolve, reject) => {
      API.get("documents/public", {
        headers: {
          "Accept-Language": lang,
        },
        params: { country_code: code },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Список минимальной информации о ТТ, подходит для селекторов
  loadTradingPointsList({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("account/filters/points")
        .then((response) => {
          commit("SET_TRADING_POINTS_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadOperations(_, params) {
    return new Promise((resolve, reject) => {
      API.post("account/operations/export", params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getOperationDocStatus(_, process_uuid) {
    return new Promise((resolve, reject) => {
      API.post(
        "/process",
        { process_uuid: process_uuid },
        { responseType: "blob" }
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Проверка наличия инн из рдо
  getRdoEntity({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("account/entity-precondition")
        .then((response) => {
          commit("SET_RDO_ENTITY", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  findOKVED(_, okved) {
    if (typeof okved !== "string") {
      okved = toString(okved);
    }

    return new Promise((resolve, reject) => {
      API.post("entities/dadata/okved-suggest", {
        okved: okved,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //  закрытие сообщения модерации (любой)
  closeModeration(_, moderation_id) {
    return new Promise((resolve, reject) => {
      API.post(`moderation/${moderation_id}/hide`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteDocument(_, id) {
    return new Promise((resolve, reject) => {
      API.delete(`account/documents/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_COMMON_DOCS: (state, docs) => {
    state.commonDocs = docs;
  },

  SET_OPERATIONS: (state, payload) => {
    state.operations = payload;
  },

  SET_OPERATIONS_META(state, payload) {
    state.operationsMeta = payload;
  },

  SET_PROFILE: (state, profile) => {
    state.profile = profile;
  },

  SET_PROFILE_POINTS: (state, points) => {
    state.points = points;
  },

  SET_PROFILE_TARIFF: (state, payload) => {
    state.tariff = payload;
  },

  SET_PROFILE_BALANCE: (state, balance) => {
    state.balance = balance;
  },

  SET_PROFILE_PERMISSIONS: (state, payload) => {
    state.permissions = payload;
  },

  SET_RECEIPT_INFO(state, payload) {
    state.receiptInfo = payload;
  },

  SET_TOTAL_TRADING_POINTS(state, total) {
    state.totalTradingPoints = total;
  },

  SET_TRADING_POINTS_LIST(state, tradingPointsList) {
    state.tradingPointsList = tradingPointsList;
  },

  SET_RDO_ENTITY(state, rdoEntity) {
    state.rdoEntity = rdoEntity;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
