import { API } from "@/boot/api";

const state = {
  managers: [],
  manager: [],
  maxPageMangers: "",
  load: true,
  permissions: [],
};
const getters = {};
const mutations = {
  SET_MANAGERS: (state, payload) => {
    state.managers = payload;
  },
  SET_MANAGER: (state, manager) => {
    state.manager = manager;
  },
  SET_MAX_PAGE_MANAGERS: (state, payload) => {
    state.maxPageMangers = payload;
  },
  SET_LOADER: (state, payload) => {
    state.load = payload;
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions.filter(
      (permission) =>
        permission !== "application" &&
        permission !== "group" &&
        permission !== "claim"
    );
  },
};

const actions = {
  createManager(_, data) {
    return new Promise((resolve, reject) => {
      API.post("managers", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  newPassword(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`managers/${data.id}/set-password`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getManagers({ commit }, data) {
    commit("SET_LOADER", true);
    return new Promise((resolve, reject) => {
      API.get("account/managers", {
        params: {
          page: data.page,
        },
      })
        .then((response) => {
          commit("SET_MANAGERS", response.data.data);
          commit("SET_MAX_PAGE_MANAGERS", response.data.meta.last_page);
          commit("SET_LOADER", false);
          resolve(response);
        })
        .catch((error) => {
          commit("SET_LOADER", false);
          reject(error);
        });
    });
  },

  getManagersById({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`managers/${data.id}`)
        .then((response) => {
          commit("SET_MANAGER", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          commit("SET_LOADER", false);
          reject(error);
        });
    });
  },

  deleteManager(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`managers/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeManager({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.put(`managers/${data.id}`, {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        middle_name: data.middle_name,
        post: data.post,
        phone: data.phone,
        permissions: data.permissions,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          commit("SET_LOADER", false);
          reject(error);
        });
    });
  },

  changeStatus(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`managers/${data.manager_id}/blocked`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetManagerPassword(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`managers/${data.manager_id}/reset-password`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPermissions({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("managers/permissions")
        .then((response) => {
          commit("SET_PERMISSIONS", response.data.data.available);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
