import { API } from "@/boot/api";

const state = {
  allClientsGraph: [],
  allClients: "",
  newClientsGraph: [],
  newClients: "",
  organizationSales: [],
  salesCount: "",
  spentPointsGraph: [],
  organizationSalesAmount: [],
  organizationSalesAverage: [],
  employeeTopSale: [],
  employeeTopCount: [],
  employeeTopTable: [],
  pointsAwarded: [],
};

const getters = {};

const mutations = {
  SET_ALL_CLIENT_GRAPH: (state, graph) => {
    state.allClientsGraph = graph;
  },
  SET_ALL_CLIENTS: (state, clients) => {
    state.allClients = clients;
  },
  SET_NEW_CLIENT_GRAPH: (state, graph) => {
    state.newClientsGraph = graph;
  },
  SET_NEW_CLIENTS: (state, clients) => {
    state.newClients = clients;
  },
  SET_SALES_GRAPH: (state, sales) => {
    state.organizationSales = sales;
  },
  SET_SALES_COUNT: (state, sales) => {
    state.salesCount = sales;
  },
  SET_SALES_AMOUNT: (state, amount) => {
    state.organizationSalesAmount = amount;
  },
  SET_SALES_AVERAGE: (state, average) => {
    state.organizationSalesAverage = average;
  },
  SET_EMPLOYEE_TOP_SALE: (state, top) => {
    state.employeeTopSale = top;
  },
  SET_EMPLOYEE_TOP_COUNT: (state, top) => {
    state.employeeTopCount = top;
  },
  SET_EMPLOYEE_TOP_TABLE: (state, top) => {
    state.employeeTopTable = top;
  },
  SET_SENT_POINTS_GRAPH: (state, graph) => {
    state.spentPointsGraph = graph;
  },
  SET_POINTS_AWARDED: (state, points) => {
    state.pointsAwarded = points;
  },
};

const actions = {
  clientStatistics({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/client", {
        params: {
          ids: data.ids,
          date_start: data.date_start,
          date_finish: data.date_finish,
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          commit("SET_ALL_CLIENTS", response.data.all_client);
          commit("SET_NEW_CLIENTS", response.data.new_client);
          commit("SET_ALL_CLIENT_GRAPH", response.data.all_clients_graph);
          commit("SET_NEW_CLIENT_GRAPH", response.data.new_clients_graph);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  salesStatistics({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/sales", {
        params: {
          ids: data.ids,
          date_start: data.date_start,
          date_finish: data.date_finish,
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          commit("SET_SALES_COUNT", response.data.sales_count);
          commit("SET_SALES_GRAPH", response.data.sales_graph);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  salesStatisticsAmount({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/sales/amount", {
        params: {
          ids: data.ids,
          date_start: data.date_start,
          date_finish: data.date_finish,
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          commit("SET_SALES_AMOUNT", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  salesStatisticsAverage({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/sales/average", {
        params: {
          ids: data.ids,
          date_start: data.date_start,
          date_finish: data.date_finish,
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          commit("SET_SALES_AVERAGE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  spentPoints({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/points/spent", {
        params: {
          ids: data.ids,
          date_start: data.date_start,
          date_finish: data.date_finish,
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          commit("SET_SENT_POINTS_GRAPH", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  topEmployeesSales({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/employees/amount", {
        params: {
          ids: data.ids,
          date_start: data.date_start,
          date_finish: data.date_finish,
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          commit("SET_EMPLOYEE_TOP_SALE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  topEmployeesCount({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/employees/count", {
        params: {
          ids: data.ids,
          date_start: data.date_start,
          date_finish: data.date_finish,
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          commit("SET_EMPLOYEE_TOP_COUNT", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  pointsAwarded({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/points/awarded", {
        params: {
          ids: data.ids,
          date_start: data.date_start,
          date_finish: data.date_finish,
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          commit("SET_POINTS_AWARDED", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  employeeStats({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/employees/info", {
        params: {
          page: data.page,
          ids: data.ids,
          date_start: data.date_start,
          date_finish: data.date_finish,
          select_all: data.select_all,
          model_type: data.model_type,
          order_field: data.order_field,
          order_type: data.order_type,
        },
      })
        .then((response) => {
          commit("SET_EMPLOYEE_TOP_TABLE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadRFMAnalysis(_, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/rfm-analysis", {
        params: {
          ids: data.ids,
          date_start: data.date_start || "",
          date_finish: data.date_finish || "",
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadRFMAllAnalysis(_, data) {
    return new Promise((resolve, reject) => {
      API.get("statistics/rfm-analysis/detail", {
        params: {
          ids: data.ids,
          date_start: data.date_start || "",
          date_finish: data.date_finish || "",
          select_all: data.select_all,
          model_type: data.model_type,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
