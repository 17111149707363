<script>
import ButtonIcon from "@/components/common/ButtonIcon.vue";
import { mapActions } from "vuex";

export default {
  name: "MainNotify",

  components: { ButtonIcon },

  emits: ["close"],

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },

    notify: {
      type: [Object, null],
      required: true,
    },
  },

  computed: {
    nameLink() {
      if (this.notify.link.to.name) {
        return this.notify.link.to.name;
      }

      for (const key in this.$router.getRoutes()) {
        if (this.$router.getRoutes()[key].path !== `/${this.notify.link.to}`) {
          continue;
        }

        return this.$router.getRoutes()[key].name;
      }

      return "";
    },
  },

  methods: {
    ...mapActions({
      notificationMarkAsReady: "auth/notificationMarkAsReady",
    }),

    close() {
      if (this.notify.type !== "debt") {
        this.notificationMarkAsReady(this.notify.id);
      }

      this.$emit("close");
    },
  },
};
</script>

<template>
  <Transition name="notify">
    <section
      v-if="isShow"
      class="main-notify"
      :class="{
        'main-notify_warning': notify.status === 'error',
        'main-notify_success': notify.status === 'success',
        'main-notify_info': notify.status === 'info',
      }"
    >
      <span
        :class="{
          'icon-warning': notify.status === 'error',
          'icon-success': notify.status === 'success',
          'icon-link': notify.status === 'info',
        }"
      />

      <div class="main-notify__info">
        <h5 v-html="notify.title" />

        <p v-html="notify.text" />

        <RouterLink :to="{ name: nameLink }">
          {{ notify.link.label }}
        </RouterLink>
      </div>

      <ButtonIcon class="main-notify__close" @click="close">
        <span class="icon-close" />
      </ButtonIcon>
    </section>
  </Transition>
</template>

<style lang="scss" scoped>
.main-notify {
  position: absolute;
  top: 73px;
  right: 48px;
  max-width: 400px;
  height: fit-content;
  background: var(--color-semantic-background-normal-primary);
  border-radius: 4px;
  z-index: 1000003;
  display: flex;
  gap: 12px;
  padding: 16px 20px;
  overflow: hidden;
  color: var(--color-semantic-content-normal-primary);
  box-shadow: var(--box-shadow-notification);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 4px;
    height: 100%;
  }

  &_warning {
    &::before {
      background: var(--color-semantic-content-normal-negative);
    }
  }

  &_success {
    &::before {
      background: var(--color-semantic-content-normal-positive);
    }
  }

  &_info {
    &::before {
      background: var(--color-semantic-content-normal-info);
    }
  }

  &__info {
    > h5 {
      @include body;
      margin-bottom: 4px;
    }

    > p {
      @include text-2;
      color: var(--color-semantic-content-normal-tertiary);
      margin-bottom: 14px;
    }

    > a {
      text-decoration: none;
      color: var(--color-semantic-content-normal-link);
      @include text-2;
    }
  }

  &__close {
    padding: 0;
    height: fit-content;
  }
}

.icon-warning,
.icon-success,
.icon-link {
  min-width: 32px;
  height: 32px;
}

.icon-warning {
  background: var(--color-semantic-content-normal-negative);
}

.icon-success {
  background: var(--color-semantic-content-normal-positive);
}

.icon-link {
  background: var(--color-semantic-content-normal-info);
}

.icon-close {
  min-width: 24px;
  height: 24px;
  background: var(--color-semantic-content-normal-tertiary);
}

.notify-enter-active {
  animation: notify-show 0.5s ease-out;
}

.notify-leave-active {
  animation: notify-show 0.5s reverse ease-out;
}

@keyframes notify-show {
  0% {
    opacity: 0;
    right: -100%;
  }

  100% {
    opacity: 1;
    right: 48px;
  }
}

@media (max-width: 1535px) {
  .main-notify {
    right: 32px;
  }
}

@media (max-width: 767px) {
  .main-notify {
    width: 90%;
    right: 50%;
    transform: translate(50%, 5px);
  }

  @keyframes notify-show {
    0% {
      opacity: 0;
      top: -100%;
    }

    100% {
      opacity: 1;
      top: 73px;
    }
  }
}

@media (max-width: 411px) {
  .main-notify {
    width: 80%;
  }
}
</style>
