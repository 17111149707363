import rating from "@/assets/icons/rating.svg";
import desktop from "@/assets/icons/desktop.svg";
import chat from "@/assets/icons/chat.svg";
import monitoring_card_1_en from "@/assets/images/monitoring_card_1_en.png";
import monitoring_card_2_en from "@/assets/images/monitoring_card_2_en.png";
import monitoring_card_3_en from "@/assets/images/monitoring_card_3_en.png";
import monitoring_item_1_en from "@/assets/images/monitoring_item_1_en.png";
import monitoring_item_2_en from "@/assets/images/monitoring_item_3_en.png";
import monitoring_item_3_en from "@/assets/images/monitoring_item_2_en.png";
import index_header_notebook_en from "@/assets/images/index_header_notebook_en.png";

export default {
  HEADER: {
    moreButton: "Learn more",
    updates: "*The renewal of indicators occurs every 24 hours",
    title: "Complete loyalty program <br/> for your business",
    subTitle:
      "Personal account featuring full insights, <br/> mobile app and prompt support",
    panels: {
      clients: "customers",
      addRC: "passed RC for purchases",
      tp: "retail outlets",
      city: "covered cities",
      totalBuy: "the total number of purchases",
    },
  },
  clients: "For customer",
  franchising: "For franchisee",
  btnRegister: "Sign up",
  btnLogin: "Log in",
  title: "Complete loyalty program <br/> for your business",
  subTitle:
    "Personal account featuring full insights, <br/> mobile app and prompt support",
  mainImage: index_header_notebook_en,
  staticTitle: "Collect complete statistics of your LLC or ST",
  staticInfo: {
    title: "More than convenient",
    text: "The platform makes the modern-business processes automatic.  Select what you need. Customize the system and collected customer data with a few clicks. No more intricacies and bypasses — only reliable charts and realistic figures.",
  },
  statisticCards: [
    {
      imagePath: rating,
      title: "Customer activity <br/> in graphs and figures",
      text: "Detailed information on existing customers — <br/>retain the loyal customers and attract the new ones",
    },
    {
      imagePath: desktop,
      title:
        "User-friendly and affordable systems — <br/> the dream of every entrepreneur",
      text: "Feedback opportunities to increase income <br/> improve reputation and enhance the customer loyalty",
    },
    {
      imagePath: chat,
      title: "Communication with the customers",
      text: "Divide into segments and create <br/> PUSH notifications for target interaction with the customers",
    },
  ],
  sliderItems: [
    {
      icon: "icon-share",
      text: "Loyalty program",
    },
    {
      icon: "icon-people",
      text: "Customers",
    },
    {
      icon: "icon-wallet",
      text: "Wallet cards",
    },
    {
      icon: "icon-person",
      text: "My employees",
    },
    {
      icon: "icon-tsp",
      text: "My enterprises",
    },
    {
      icon: "icon-case",
      text: "Service products",
    },
    {
      icon: "icon-balance",
      text: "Processing",
    },
    {
      icon: "icon-share",
      text: "Loyalty program",
    },
    {
      icon: "icon-people",
      text: "Customers",
    },
    {
      icon: "icon-wallet",
      text: "Wallet cards",
    },
    {
      icon: "icon-person",
      text: "My employees",
    },
    {
      icon: "icon-tsp",
      text: "My enterprises",
    },
    {
      icon: "icon-case",
      text: "Service products",
    },
    {
      icon: "icon-balance",
      text: "Processing",
    },
  ],
  loyaltyList: {
    firstColumn: [
      {
        id: 1,
        icon: "icon-home",
        title: "Registration",
        text: "We value your time, and we are ready to ensure the effective beginning of your company's loyalty in the world",
      },

      {
        id: 2,
        icon: "icon-money-cents",
        title: "Take points",
        text: "The use of points as a form of payment helps to increase profit by 25% and above",
      },

      {
        id: 3,
        icon: "icon-plus",
        title: "Add a outlet",
        text: "A key step for the full use of the expanded functionality of the cabinet",
      },

      {
        id: 4,
        icon: "icon-wallet",
        title: "Create unique Wallet cards",
        text: "Tune your design card design for customers.Change the type and content without reassion and additional expenses",
      },

      {
        id: 5,
        icon: "icon-update",
        title: "Get repeated orders",
        text: "Transparent system: 1 point = 1 ruble.Clients who accumulate points more often make repeated purchases",
      },
    ],

    secondColumn: [
      {
        id: 6,
        icon: "icon-card",
        title: "Oscy",
        text: "Customer digitization - a strategic tool for increasing the profitability of the company",
      },

      {
        id: 7,
        icon: "icon-moneybox",
        title: "Charity reward",
        text: "The accrual of points stimulates to make larger purchases, as customers feel that they receive additional value",
      },

      {
        id: 8,
        icon: "icon-connection",
        title: "Place on rcpay.ru and RC PAY",
        text: "Your company joins the platform with a huge number of users who are looking for places to use points",
      },

      {
        id: 9,
        icon: "icon-send",
        title: "Send push notifications",
        text: "Report for free about promotions, new items and other events.Stimulate purchases during periods of low activity",
      },
    ],
  },

  monitoringGlobalTitle:
    "Loyalty program: why is it necessary and how to introduce",

  monitoringTitle: "Collect and analyze information easily",
  monitoringSubTitle:
    "Keep records of new and loyal customers. Follow up the sales level with a handy graph. Get up-to-date sales and average bill figures to stay informed of the most recent market trends and quickly implement the advanced solutions.  Make your life easier with RC CITY!",
  monitoringSecondTitle: "User-friendly interface for your company data",
  monitoringCards: [
    {
      title: "Number of customers",
      text: "Summing-up the total number of the referral customers for the given period",
      imagePath: monitoring_card_1_en,
    },
    {
      title: "Viewing the transactions ",
      text: "Total sales or average bill per each of your retail outlets",
      imagePath: monitoring_card_2_en,
    },
    {
      title: "Sales",
      text: "Number of unique sales per your retail outlet",
      imagePath: monitoring_card_3_en,
    },
  ],
  monitoringItems: [
    {
      title: "Two easy steps to start",
      text:
        "1. Enter the taxpayer identification number and select the company type.  <br/>" +
        "2. Enter the manager’s name, bank account and BIC — all data you need will be collected automatically. " +
        "Minimum effort — maximum benefit!",
      imagePath: monitoring_item_1_en,
    },
    {
      title: "Complete picture  <br/> for business efficiency and performance",
      text: "Customize the necessary parameters for your retail outlet, upload photos, inform about rewards and discounts. Only key parameters and user-friendly interface — nothing extra.",
      imagePath: monitoring_item_2_en,
    },
    {
      title: "All important documents are collected in a single folder.",
      text: "Anything you may need for your work — contract, tariff plans, privacy policy, marketing materials and more — is already here. Get an access to the necessary information with a few clicks. No red tape — everything is sorted out.",
      imagePath: monitoring_item_3_en,
    },
  ],
  tariffGlobalTitle: "Доступна регистрация коммерческих {nko} юридических лиц",
  tariffsTitle: "Which tariff plan is the best for you?",
  mostPopular: "The most popular",
  currency: "{currency} / year",
  tariffs: [
    {
      title: "Lite",
      subtitle: "Attraction of the new <br/> customer audience",
      buttonType: "black-new",
      buttonText: "Try it",
      additionalInfo: "",
      infoItems: [
        "Signing up 1 entity",
        "Creating 5 retail outlets",
        "Simplified statistics",
        "Promotional materials",
        "Access to RC KASSA",
        "Electronic document flow",
        "Account clearing twice a month",
        "FAQ",
      ],
    },
    {
      title: "Promo",
      subtitle: "Loyalty program <br/> adapted for your business",
      buttonType: "gold",
      buttonText: "Buy",
      additionalInfo: "All Lite items available...",
      infoItems: [
        "Signing up 15 entities",
        "Creating 15 retail outlets",
        "Expanded statistics",
        "Access to RC KASSA",
        "Push mailing for customers",
        "Feedback ",
        "1000 licenses for customer digitization",
        "Wallet card design",
        "Electronic menu",
      ],
    },
    {
      title: "Pro +",
      subtitle: "For large companies <br/> with branches",
      buttonType: "black-outline-new",
      buttonText: "Buy",
      additionalInfo: "All Lite, Promo items available...",
      infoItems: [
        "Signing up 30 entities",
        "Creating 30 retail outlets",
        "Expanded statistics",
        "Push mailing for customers",
        "Feedback ",
        "Wallet card design",
        "Unlimited number of licenses <br/> for customer digitization",
      ],
    },
  ],
  questionsTitle: "Questions and answers",
  questions: [
    {
      title:
        "Can the loyalty program help me with more customers coming to my barber shop?",
      text: "RC CITY loyalty program is a versatile solution suitable for any business spheres. Beauty segment including all kinds of beauty studios, hairdresser's and other venues are the spheres, like any other, where the loyalty program can be successfully implemented.",
    },
    {
      title: "What do I need to launch the loyalty program?",
      text: "PC with Internet connection for the loyalty program signup and use of RC CITY partner’s account. If you use iOS or Android smartphone, download RC KASSA app for your employees to scan QR code from RC PAY customer app for withdrawal and accrual of points.",
    },
    {
      title:
        "How do I know which reward percentage I should apply for RC Group customers?",
      text: "The offer has to be beneficial for the company’s customers. The optimal amount of bonuses is 4-25% of the goods or service price.",
    },
    {
      title: "Can I sign up 3 legal entities at RC CITY?",
      text: "Yes, you can. In general, you can sign up as many legal entities as you need. PROMO tariff plan allows adding up to 15 legal entities, PRO+ — up to 30 entities.",
    },
    {
      title:
        "I have 10 retail outlets. Can I get the statistics per each of them separately?",
      text: "Yes, certainly. The account functionality enables data sorting per each of your existing retail outlet.  If necessary, you can view the statistics of all retail outlets in a single go.",
    },
    {
      title: "Which parameters form the customer profile?",
      text: "Age, male-to-female percentage ratio, average bill, number of customers in the given segment, sales quantity and amount, percentage ratio of the customers who have not identified their gender. ",
    },
    {
      title: "Will it make sense to upgrade the tariff plan?",
      text: "Undoubtedly. If you upgrade the tariff plan, you get the expanded functionality with more thorough insight of the customers with the possibility to add more outlets and legal entities in one account, and other bonuses.  In some cases, for instance, if you have more than five retail outlets, you should start with PROMO tariff plan.",
    },
    {
      title: "What are the main advantages of RC KASSA app?",
      text: "In this app, you can withdraw and award points to the customers, use Wallet card (digitize the customers, collect the employee transactional statistics), follow up the transaction history, view information on campaigns of a specific venue in a separate tab.",
    },
    {
      title:
        "How will push notifications help to increase the income of a fast-food restaurant?",
      text: "Push notifications are the quickest way of today’s communication with the customer.  It doesn’t affect the sales directly, but it perfectly works indirectly, through enhancement of the loyalty and engagement.  A fast-food restaurant can use such notifications to inform on the current discounts, start of campaigns, attractive offers, etc.",
    },
  ],
  footer: {
    juristicDocs: "Legal documents",
    contacts: "Contacts",
    tariffs: "Tariff plans",
    support: "Support",
    privacy: "Privacy Policy",
    rewards: "Reward distribution scenarios",
    rcMark:
      "<p>" +
      "RC Group is a brand, DBA and trademark and/or service mark of RC TECHNOLOGIES Limited Liability Company (RC TECHNOLOGIES LLC), INN 7806578914; OGRN 1207800157256. <br/>Unauthorized reproduction or use without prior written consent <span>is prohibited</span>." +
      "</p>",
  },
};
