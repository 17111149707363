import { API } from "@/boot/api";

const state = {
  promotions: [],
};

const getters = {};

const mutations = {
  SET_PROMOTIONS: (state, promotions) => {
    state.promotions = promotions;
  },
};

const actions = {
  loadPromotions({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("points/groups/", {
        params: {
          type: "promotion",
          point_ids: data.point_ids,
          per_page: data.per_page,
          page: data.page,
        },
      })
        .then((response) => {
          commit("SET_PROMOTIONS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createPromotion(_, data) {
    return new Promise((resolve, reject) => {
      API.post("group/promotion", {
        name: data.name,
        points: data.points,
        discount_percent: data.discount_percent,
        cashback_percent: data.cashback_percent,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editPromotion(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`group/promotion/${data.promotion_id}`, {
        name: data.name,
        status: data.status,
        discount_percent: data.discount_percent,
        cashback_percent: data.cashback_percent,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deletePromotion(_, promotion_id) {
    return new Promise((resolve, reject) => {
      API.delete(`group/promotion/${promotion_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
