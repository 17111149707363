//Юрлица Entities
const keyStringFields = [
  "inn",
  "okved_code",
  "ogrn",
  "bik_of_bank",
  "corresp_account",
  "number_account",
  "vat_code",
  "international_bank",
  "iik",
];
export function EntityPayloadProcessing(payload) {
  const data = Object.assign({}, payload);

  for (const key in data) {
    if (keyStringFields.includes(key)) {
      data[key] = convertingToString(data[key]);
    }

    if (key === "meta") {
      for (const metaKey in data.meta) {
        if (keyStringFields.includes(metaKey)) {
          data.meta[metaKey] = convertingToString(data.meta[metaKey]);
        }
      }
    }

    if (key === "is_halal") {
      data.is_halal = !!data.is_halal;
    }
  }

  return data;
}

function convertingToString(value) {
  switch (typeof value) {
    case "string":
      return value;
    case "number":
      return value.toString();
    default:
      return value;
  }
}
