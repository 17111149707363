export const sortTypes = {
  asc: "asc",
  desc: "desc",
};

export const RATING_FILTER = [
  { rating: 5, value: false, total: 0 },
  { rating: 4, value: false, total: 0 },
  { rating: 3, value: false, total: 0 },
  { rating: 2, value: false, total: 0 },
  { rating: 1, value: false, total: 0 },
];

export const TARIFF_SORT = ["lite_p", "promo_p", "pluspro_p", "corporate"];
