<script>
export default {
  name: "ButtonWrapper",

  emits: ["click"],

  props: {
    type: {
      type: String,
      default: "button",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <button
    class="button-wrapper"
    :type="type"
    :disabled="isDisabled || disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<style scoped>
.button-wrapper {
  background: none;
  border: none;
  width: auto;
  height: auto;
  cursor: pointer;
  color: inherit;
  text-align: left;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}
</style>
