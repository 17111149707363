import { API } from "@/boot/api";

const state = {
  employees: [],
  pointsEmployee: [],
  aboutEmployee: [],
};

const getters = {};

const mutations = {
  SET_EMPLOYEES: (state, employees) => {
    state.employees = employees;
  },
  SET_EMPLOYEE_PINTS: (state, payload) => {
    state.pointsEmployee = payload;
  },
  SET_ABOUT_EMPLOYEE: (state, payload) => {
    state.aboutEmployee = payload;
  },
};

const actions = {
  getEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("account/employees", {
        params: {
          page: data.page,
        },
      })
        .then((response) => {
          commit("SET_EMPLOYEES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendEmployee(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        "employees/store",
        data
        /*{
        first_name: data.first_name,
        last_name: data.last_name,
        point_id: data.point_id,
      }*/
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  statusEmployee(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`employees/${data.id}/status`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  pointsEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`employees/${data.id}/points`, {
        params: {
          page: data.page,
          date_start: data.date_start,
          date_finish: data.date_finish,
        },
      })
        .then((response) => {
          commit("SET_EMPLOYEE_PINTS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  aboutEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`employees/${data.id}/show`)
        .then((response) => {
          commit("SET_ABOUT_EMPLOYEE", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteEmployee(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`employees/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
