// This is just an example,
// so you can safely delete all default props below

import landing from "./landing";
import { balanceRo } from "@/modules/balance/i18n/ro";

export default {
  balance: {
    ...balanceRo,
  },

  //SYSTEM
  sys: {
    error: "Eroare: ",
    descError: "Descriere: ",
    wrongLogin: "Login greșit",
    wrongPass: "Parolă greșită",
    unknown: "Necunoscut",
    unauthenticated: "Nu este autorizat",
    iikAlready: "Persoana juridică cu acest CII deja există",
    noMoney: "Sold insuficient",
    wrongPeriod: "ProPlus doar pe un an",
    noPermissions: "Utilizatorul nu are accesurile necesare.",
    firstLoginManager: "Conectat pentru prima dată, creați o parolă",
    limitShops: "Crearea unui punct de vânzare este limitată de tarif",
    limitLegalEntity: "Crearea unei persoane juridice este limitată de tarif",
    wrongTariff: "Acest lucru nu este disponibil cu tariful dvs.",
    wrongTariffPromo: "Codul promoțional nu se potrivește pentru înregistrare",
    tagExist: "Acest tag există deja",
    503: "Serverul nu este disponibil",
    blockManager: "Managerul este blocat",
    wrongCode: "Codul greșit",
    codeExists: "Codul promoțional al partenerului este introdus greșit",
    noExam: "Examenul nu a fost susținut",
    noActivateAccount: "Contul nu este activat",
    emailExist: "Proprietarul cu această adresă de e-mail există deja",
    userExist: "Acest e-mail este deja înregistrat în alte servicii",
    noAccepted: "Noul acord nu a fost acceptat",
    balanceNotEnough: "Mijloace bănești insuficiente pe cont",
    innExist: "Persoana juridică cu acest NFI deja există",
    noTodayChangeTariff:
      "Puteți modifica tariful doar cu o zi înainte de sfârșitul tarifului actual",
    badToken: "Linkul dat nu este valabil",
    wrongEmail: "E-mail greșit",
    emailTaken: "E-mailul este ocupat deja",
    notAvailableCurrentTariff: "Inaccesibil cu tariful dat",
    dataNoCorrect: "Datele sunt greșite",
    accountNotFound: "Contul nu este găsit",
    TPExist: "Punctul e vânzare există deja",
    serverError: "Eroarea serverului",
    ownerExist: "Contul nu este găsit",
    unauthorizedAction: "Aveți drepturi limitate",
    legalEntityExist: "Persoana juridică nu a fost găsită",
    tariffChangeOneDay:
      "Tariful poate fi modificat cu o zi înainte de sfârșitul tarifului actual",
    blockedByTariff: "Blocată de tarif",
    documentInProcessOfCreation: "Documentul este în proces de formare",
    emailAlreadyVerified: "E-mailul dvs. este deja confirmat!",
    emailConfirmed: "Ваша почта успешно подтверждена!",
    codeDoesNotExist: "Введенный код недействителен",
    defaultCashbackCanNotBeDeleted:
      "Вознаграждение для клиентов без брендированной карты не может быть удалено",
    cashbackUpdateSuccessful: "Процент вознаграждения был успешно обновлен",
    cashbackDeleteSuccessful: "Вознаграждение удалено",
    cashbackCreateSuccessful: "Вознаграждение создано",
    fullCashback: "Клиент без брендированной карты",
    clientGroupAlreadyInUse:
      "Группа клиентов уже используется в группе кешбека",
    enteredAmountIsAlreadyInUse:
      "Введенная сумма вознаграждения уже выставлена",
    dateStartIsInvalid: "Дата начала периода введена некорректно",
    dateEndIsInvalid: "Дата конца периода введена некорректно",
    organizationNotConfirmed: "Юр.лицо не подтверждено",
    periodNotAvailable: "Выбранный период недоступен для покупки",
    notAvailableInYourCountry: "Выбранный период недоступен для покупки",
    exceededActivatedPromotionLimit:
      "Только 3 активированных акции на одну торговую точку",
    tradingPointLimitOnProPlus:
      "На тарифе ProPlus возможно создание не более 30 торговых точек",
    tradingPointLimitOnPromo:
      "На тарифе ProPlus возможно создание не более 15 торговых точек",
    tradingPointLimitOnLight:
      "На тарифе ProPlus возможно создание не более 5 торговых точек",
    wrongDateOrder:
      "Дата начала периода должна быть раньше, чем дата конца периода",
    descriptionFormatInvalid: "Описание не должно содержать хештегов и emoji",
    descriptionMustNotContainEmoji: "Описание не должно содержать emoji",
    descriptionMinLength:
      "Описание должно быть длинее 600 символов без учета пробелов",
    descriptionMaxLength: "Описание не должно быть длинее 3000 символов",
    maxFileSizeError: "Размер файла превышает лимит в 10 Мбайт",
    addFile:
      "Перетащите или <span style='color: #447ECB; cursor: pointer'>загрузите файлы</span>",
    balance: "Баланс",
    changeMainOrganizationError:
      "Изменить главную организацию возможно только через 30 дней после предыдущего изменения",
    resetWallet: "Не удалось сбросить дизайн wallet pass",
    updateWallet: "Не удалось обновить дизайн wallet pass",
    userUnauthorized: "Логин или пароль введены неверно",
    noData: "Нет данных",
    kb: "КБ",
    dateMask: "дд.мм.гггг",
    sort: "Сортировка",
    noDataTitle: "Данных пока нет",
    syncError: "Проблема с синхронизацией. Попробуйте еще раз позже.",

    enterName: "Введите название",
    countries: {
      ru: "Rusia",
      kz: "Kazahstan",
      md: "Moldova",
    },
    select_country: "Alege o tara",
    country: "Țară",
    q_date: {
      days: "Duminică_Luni_Marți_Miercuri_Joi_Vineri_Sâmbătă",
      daysShort: "Dum_Lun_Mar_Mie_Joi_Vin_Sâm",
      months:
        "Ianuarie_Februarie_Martie_Aprilie_Mai_Iunie_Iulie_August_Septembrie_Octombrie_Noiembrie_Decembrie",
      monthsShort: "Ian_Feb_Mar_Apr_Mai_Iun_Iul_Aug_Sep_Oct_Noi_Dec",
    },
    errorTitle: "Что-то пошло не так",
    dateFrom: "Данные доступны с ",
    dateToToday: " по сегодняшний день",
    dateFormat: "Формат дд.мм.гггг или дд.мм.гггг - дд.мм.гггг",
    showBy: "Показывать по: ",
    showByMobile: "По: ",
    searchResponseEmpty: "По вашему запросу ничего не найдено",
    search: "Поиск",
    tagSearchResponseEmpty:
      "Тег не найден. Для его добавления нажмите Enter или Пробел.",
    from: "От:",
    to: "До:",
    gender: {
      m: "Мужской",
      f: "Женский",
    },
    500: "Что-то пошло не так. Что-то пошло не так.",
  },
  entity: {
    title: "Adăugați datele organizației dvs.",
    thirdStepTitle:
      "Verificați, vă rog, corectitudinea datelor. Acum trebuie să așteptați între 24 și 48 de ore pentru ca noi să vă confirmăm persoana juridică.",
    accept: "Accept condițiile contractului",
    offers: "oferte",
    titleReject: "Cererea este respinsă!",
    rejectDescription:
      "Din păcate, datele pe care le-ați furnizat nu au fost corecte. Citiți cu atenție răspunsul moderatorilor și modificați informațiile despre persoana juridică.",
    message: "Mesaj de la moderatori:",
    change: "Modificați datele",
    // titleWaiting: "Спасибо за ожидание", - изменено от (NK-589)
    titleWaiting: "Vă mulțumim pentru înregistrare!",
    descriptionWaiting:
      "În acest moment, organizația dvs. trece moderarea. Puteți intra în contul dvs. personal după ce ne asigurăm că datele dvs. sunt corecte. De obicei, verificarea durează de la 24 la 48 de ore.",
    descriptionWaitingRu:
      "<p>В данный момент ваша организация проходит модерацию. </p> <p>После того, как мы удостоверимся в корректности ваших данных \n" +
      "вы сможете войти в кабинет партнёра RC Group. </p><p> Как правило, проверка занимает от 24 до 48 часов.</p>",
    descriptionWaitingRuWithoutPos:
      "<p>В данный момент ваша организация проходит модерацию. </p> <p>После того, как мы удостоверимся в корректности ваших данных \n" +
      "вы сможете войти в кабинет партнёра RC Group. </p> <p> Как правило, проверка занимает от 24 до 48 часов.</p>",
    // downloadAccept: "Скачать счет по акцептному платежу", - изменено от (NK-589)
    downloadAccept:
      "Descărcați o factură pentru efectuarea unei plăți de testare",
    addDone: "Поздравляем с созданием юрлица!",
    waitingApprove:
      "<p>В данный момент ваше юрлицо проходит проверку. Она занимает от 24 до 48 часов.</p><p>Для прохождения проверки вам необходимо оплатить счет с указанного юрлица, скачав его по кнопке ниже. Вы также можете найти счет в личном кабинете: в разделе «Мои  предприятия» - «Документы» под названием «Тестовый платеж».</p>",
  },

  landing,

  contacts: {
    title: "Контакты",
    subtitle:
      'Общество с ограниченной ответственностью "РК-ТЕХНОЛОГИИ" ( ООО "РК-ТЕХНОЛОГИИ" ) Юридический адрес: 191124, <br/>' +
      "Санкт-Петербург, вн.тер.г. муниципальный округ Смольнинское, ул. Красного Текстильщика, д.10-12, литера О, помещ. 1-Н<br/> ИНН/КПП 7806578914/780601001 ОГРН 1207800157256",
    mailAddress: "Адрес электронной почты: ",
  },

  failed: "Eroare. Acțiunea nu a fost îndeplinită",
  success: "Acțiunea a fost îndeplinită",
  navbar: {
    page1: "Principală",
    page2: "Înregistrare",
    page3: "Autorizare",
    page4: "CP",
  },
  registerCode: {
    ttl: "Introdu codul promoțional al partenerului",
    promocode: "Cod promoțional",
    invalidLength: "Lungimea codului promoțional este greșită",
    hasAccount: "Există cont?",
  },
  newPassword: {
    passwordChange: "Смена пароля",
    enterNewPassword:
      "Введите новый пароль, который будете использовать для входа в личный кабинет",
  },
  registerEmail: {
    ttl: "Introdu emailul tău",
    hasEmail1: "Acest e-mail există deja. Dorești",
    hasEmail2: "să te autorizezi",
    enterEmailCode: "Vă rugăm să introduceți codul trimis prin e-mail",
    noEmailCode: "Nu a venit codul?",
    sendCodeAgain: "Se poate de trimis repetat codul prin",
    spamTitle: "Dacă nu ați primit scrisoarea, verificați mapa spam",
  },
  resetPage: {
    title: "Restabilirea parolei",
    titleOk: "Parola a fost modificată cu succes!",
    backToAuth: "Reveniți la autorizare",
    messageToMail: "Pe adresa indicată va veni un link!",
    enterEmail: "Introduceți poșta nouă",
    descriptionNewEmail:
      "Din motive de securitate, e-mailul contului partenerului trebuie să fie unic. Vă rugăm, să introduceți poșta nouă.",
    oldEmail: "E-mailul vechi",
    newEmail: "E-mailul nou",
    descriptionPassword: "Parola nu va fi schimbată",
    notice: "Не переживайте, мы вышлем вам ссылку восстановления на почту",
    loginInformation: "Информация для входа отправлена на вашу почту",
    resetPasswordLink: "Мы отправили ссылку для изменения пароля на почту",
    messageNotReceived: "Не пришло письмо?",
    resendMail: "Отправить письмо повторно через 00:%{variable}",
  },
  //cabinet layout
  leftPanel: {
    counterparties: "Проверка контрагентов",
    entities: "Юрлица",
    tradingPoint: "Puncte de vânzare",
    employees: "Gestiunea colaboratorilor",
    documents: "Documente",
    businesses: "Afacere",
    tariffs: "Tarife",
    historyPayment: "Istoricul operațiunilor",
    promotions: "Акции",
    actsAndAccounts: "Acte și facturi",
    accrualCashback: "Calcularea punctelor",
    managers: "Gestiunea managerilor",
    spread: "Распространение карт RC",
    analytics: "Analitică",
    points: "Очки кассирам",
    enterprises: "Мои предприятия",
    paymentDecision: "Платежное решение",
    portfolio: "Portofoliu",
    employees_and_managers: "Мои сотрудники",
    loyalty: "Program de loialitate",
    clients: "Clienți",
    claims: "Cereri",
    claims_history: "Istoricul cererilor",
    reviews: "Recenzii",
    extendTariff: "Prelungiți acțiunea tarifului",
    changeTo: "tariful se va modifica în",
    wallet: "Designul cartelei Wallet",
    accountIs: "Ați intrat ca",
    manager: "manager",
    owner: "proprietar",
    terminals: "Aparate de casă",
    terminals_MSPOS: "MSPOS 4 в 1",
    terminals_LIMON: "Технология MPOS",
    FAQ: "FAQ",
    corporate: "Связь",
    push: "Push-уведомления",
    tips: "Безналичные чаевые",
    eMenu: "Электронное меню",
    giftCard: "Подарочные карты",
    processing: "Processing",
    services: "Сервисы",
    wallets: "Карты лояльности",
    clientsList: "Список клиентов",
    marketplaces: "Маркетплейсы",
    PromotionServices: "Продвижение",
  },
  headerButtons: {
    addCoin: "Suplinire",
    profile: "Profil",
    exit: "Ieșire",
  },
  //organization page
  organization: {
    title: "Organizațiile mele",
    tooltip:
      "Главное Юр лицо используется для выставления счета при пополнении баланса. Изменять главное Юр лицо можно не чаще чем раз в 2 недели",
    name: "Denumirea organizației juridice",
    iik: "CII",
    inn: "Tax payer code",
    bin: "CIB",
    bic: "Cod bancar",
    codeFiscal: "Cod Fiscal",
    status: "Statut",
    answear: "Răspunsul moderării",
    button: "Detalii",
    bill: "Istoric",
    deposit_sum: "Сумма депозита",
    head_name: "Numele complet al capului",
    ogrn: "ОГРН",
    head_name_description:
      "Introduceți numele complet al responsabilului, pe care se vor crea automat actele și conturile",
    mainOrganization: "Organizația dvs. de bază",
    mainOrganizationHint:
      "Основное юрлицо используется для покупки тарифа и остается активным при его деактивации. Изменить основное юрлицо можно не чаще чем раз в 14 дней",
    chooseMainOrganization: "Выберите Юр лицо",
    maxOrganizationError: "A fost creat un număr maxim de organizații",
    moderOrganizationError:
      "Nu puteți adăuga o nouă organizație în timp ce procesul de moderare este în desfășurare",
    // about organization
    aboutTitle: "Informație despre organizația dvs.",
    typeOrganization: "Tipul organizației",
    bankName: "Denumirea băncii",
    legalAddress: "Adresa juridică",
    currentAccountNumber: "Cont bancar",
    correspondentAccount: "Cont corespondent",
    buttonChangeOrganization: "Modificați datele organizației",
    buttonChangeRequisites: "Изменить данные",
    dataModeration: "Datele la moderare",
    organizationNotExist: "Nu există o astfel de organizație",
    vatCode: "Cod TVA",
    internationalBank: "Cod IBAN",
    prompt: "Минимальный остаток на счете",
    deposit_min: "Минимальное значение ",
    addressFieldRequired: "Не введен адрес юр.лица",
    metaIsRequired: "Не введены банковские данные",
    //add organization
    add: {
      title: "Adăugați datele organizației dvs.",
      enterData: "Introduceți datele organizației dvs.",
      commercial: "Organizație comercială",
      halal: "Organizație Halal",
      button: "Создать организацию",
    },
    // popup Aliexpress
    link: "Linkul la site (nu este obligatoriu)",
    addAliexpress: "De adăugat mărfuri pe AliExpress",
    entities: "ЮР лица",
    modalTitle: "Изменение основного юрлица",
    modalText:
      "Основное юрлицо используется для выставление счета\n" +
      "        при пополнении баланса. Ваше основное юрлицо “{old}”,\n" +
      "        будет изменено на “{new}”",
    modalSubtext: "Изменить главное Юрлицо можно не чаще чем раз в 14 дней.",
  },
  //Trading Points page
  shop: {
    title: "Puncte de vânzare",
    name: "Denumirea punctului de vânzare",
    organization: "Organizația",
    status: "Statut",
    answear: "Răspunsul moderării",
    button: "Detalii",
    hashtag: "Теги для торговой точки",
    searchTags: "Искать по тегам",
    //about Trading point
    sale: "Reduceri pentru clienții RC",
    acceptPoints: "Acest punct primește puncte RC",
    noAcceptPoints: "Acest punct nu primește puncte RC",
    phoneAdmin: "Telefonul administratorului",
    mailAdmin: "E-mailul administratorului",
    address: "Адрес",
    addressHint: "Dacă harta nu are adresa priză",
    addressComment: "Comentarii privind adresa punctului de vânzare",
    workTime: "Orar de muncă",
    buttonChangeShop: "Modificarea datelor punctului de vânzare",
    closeShop: "Închiderea punctului de vânzare",
    checkboxFeedback:
      "Poate fi utilizat pentru înregistrarea și comunicarea inversă",
    checkboxPublish: "Publicarea pe site",
    changePoint: "Modificarea punctului de vânzare",
    maxShops:
      "Вы создали максимальное количество торговых точек для Вашего тарифа",
    noShops: "Nu aveți încă puncte de vânzare",
    addShopButton: "Adăugarea punctului de vânzare",
    confirmationCloseShop:
      "Sunteți sigur că doriți să eliminați punctul de vânzare?",
    //add trading point
    add: {
      emenu: "Товары и услуги",
      reviews: "Отзывы",
      title: "Crearea punctului de vânzare",
      checkboxInternational: "Completați singur versiunea internațională",
      descriptionInternational:
        "Dacă nu veți completa versiunea internațională, ea va fi tradusă automat",
      name: "Introduceți denumirea, conform cărei se poate găsi punctul de vânzare",
      description: "Описание",
      hashtag:
        "Introduceți hashtagurile în baza căror se poate găsi punctul de vânzare",
      internationalName:
        "Introduceți denumirea internațională (numai în engleză)",
      sale: "Introduceți reducerea pentru participanții programului RC",
      additionalOptions: "Opțiuni suplimentare",
      address: "Introduceți adresa și comentariile la punctul de vânzare",
      internationalAddress:
        "Introduceți adresa internațională și comentariile la punctul de vânzare (doar în limba engleză)",
      phone: "Номер телефона",
      phoneInternational:
        "Introduceți numărul de telefon internațional al biroului și adresa de e-mail a administratorului",
      image: "Adăugați fotografii care vor fi afișate utilizatorilor",
      workTime: "Indicați orarul de muncă",
      button: "Adăugarea punctului de vânzare",
      options: {
        baby_chair: "Scaun pentru copii",
        children_room: "Coltar/cameră pentru copii",
        parking: "Parcare",
        wifi: "WiFi",
        animals: "Animale",
        round_the_clock: "Deschis non-stop",
        over_sixteen: "16+",
        yandex_program: "Программа Яндекс",
        no_smoking: "Курение запрещено",
      },
      yandexTooltip: "Ваше заведение есть в сервисах Яндекс.Еда, Яндекс Лавка",
      errorNoTime: "Вы не можете создать торговую точку без времени работы",
      addNewTag: "Новый тэг создан",
      mainInfo: "Основная информация",
      subInfo: "Дополнительная информация",
      bonusProgram: "Бонусная программа",
      isBonusPayable: "Скидка участникам программы RC",
      activeWait: "Задержка начислений",
      cashbackPercent: "Начисление вознаграждения",
      addressAndWorkTime: "Адрес и время работы",
      addressAndWorkTimeWithoutSpace: "Адрес и время&nbsp;работы",
      contactInfo: "Контакты",
      siteLink: "Ссылка на сайт",
      addLinks: "Добавить другие социальные сети",
      addAddressAndTime: "Добавить адрес и время работы",
      step: "Шаг",
      of: "из",
      comment: "Комментарий",
      email: "Почта",
      time: "Часы работы",
      weekend: "Только по выходным",
      weekdays: "Только по будням",
      another: "Работает в указанные часы работы",
      contacts: "Контакты",
      links: "Ссылки",
      thatPointUseRC: "Эта точка принимает баллы RC",
      logoAndPhotos: "Логотип и фотографии",
      logo: "Логотип торговой точки",
      filling: "Фотографии торговой точки",
      banner: "Баннер торговой точки",
      logoFormat: "Соотношение сторон для логотипа — 1:1,",
      anotherImagesFormat: "Соотношение сторон для фото — 16:9",
      bannerImagesFormat: "Соотношение сторон для баннера — 2:1",
      imagesFormat: "в формате PNG, JPG и JPEG и весом не более 10 Мб",
      onlyOnline: "Торговая точка работает онлайн",
      useForRegistration: "Использовать для записи клиентов",
      addMorePhone: "Добавить резервный номер телефона",
      addMoreEmail: "Добавить резервный адрес электронной почты",
      linkTo: "Ссылка на ",
      linkName: "Название ссылки",
      cropLogo: "Обрежьте логотип",
      cropBanner: "Обрежьте баннер",
      cropPhoto: "Обрежьте фото",
      publicTradingPoint: "Опубликовать торговую точку на сайте rcpay.ru",
      maxLengthDescription: "> 600 символов",
      //edit
      editTitle: "Просмотр торговой точки",
      noImage:
        "Если Вы не видите изображение для обрезки, значит загружаемый Вами файл не соответствует формату изображений. Пожалуйста, попробуйте загрузить другой файл.",
      noCanvas:
        "Не удалось обрезать изображение. Пожалуйста, попробуйте загрузить другой файл. ",
      newTag: "(Новый тег)",
      saveEditChanges:
        "Информация о Вашей торговой точке обновится после прохождения модерации",
      noCheck: "У Вас еще нет ни одного чека",
    },
    // history
    historyOperation: "История чеков",
    search: "Începeți introducerea cumpărătorului",
    noHistory: "Istoricul dvs. este gol",
    employee: "Colaborator",
    customer: "Cumpărător",
    source: "Sursă",
    typeOperation: "Tipul operațiunii",
    writing: "Radiere",
    accrual: "Calculare",
    sumBuy: "Suma cumpărăturii",
    sumWrite: "Suma de radiere",
    toPay: "Spre plată",
    cashback: "Cashback",
    totalCashback: "Общее вознаграждение",
    salePopup: "Reducere",
    types: {
      balance_up: "Reumplerea soldului",
      balance_down: "Retrage fonduri",
      tsp_approve: "Decontări reciproce",
      balance_refill: "Umplerea soldului",
      tariff_change: "Tarif de achizitie",
      cashback_approve: "Calatoria clientului",
      cashback_charge: "Chitanta. Ștergeți din cashback",
      certificate_charge: "Angajamente. Utilizarea unui card cadou",
      certificate_debt: "Scrie-downs. Emiterea unui card cadou",
      certificate_refund: "Rambursare pentru emiterea unui card cadou",
      rc_withdraw: "Chitanta. Acceptarea punctelor",
      tsp_commission_fee: "Chitanta. Comision",
      tsp_commission_revert:
        "Acumularea suplimentară a unei sume debitate incorect pentru perioada de la 1 aprilie până la data acumulării",
      tsp_commission_correct:
        "Ajustarea decontărilor reciproce în cuantum de 1% din punctele acceptate",
      manual_commission_approve: "Chitanta. Взаиморасчет по коммиссии",
      manual_withdraw: "Retragere",
      cashback_decline: "Anulează cecul",
      rc_processing_receipts: "Chitanța casei de marcat",
      cashback_approve_decline: "Anularea taxelor de călătorie ale clienților",
      partner_manual_withdraw: "Retragerea fondurilor din cont",
      inner_cashback_charge: "Чек. Списание вознаграждения",
      inner_cashback_approve: "Decontări reciproce",
      tsp_approve_payment: "Чек. Списание средств",
      rc_withdraw_approve: "Чек. Начисление за оплату баллами",
      withdraw_payment: "Чек. Списание средств",
      prodazha_top_mesta: "Покупка топ-места в rcpay",
      reserving_funds_withdrawal: "Зарезервировано к выплате",
      refund_reserved_funds_withdrawal: "Возврат резерва к выплате",
    },
    organizations: "Юр лицо",
    organizationsSelector: "Юрлица",
    moderationModalTitle: "Сообщение от модерации",
    tableSearch: "Поиск по таблице",
    customersSearch: "Поиск по покупателю",
    delete: "Вы уверены, что хотите удалить торговую точку?",
    nameSearch: "Поиск по названию",
    //edit
    editTitle: "Просмотр торговой точки",
    dataTooltip:
      "Для круглосуточно работающего заведения часы работы устанавливаются следующим образом: начало рабочего дня 00:00, конец рабочего дня 00:00.",
    preview: {
      title: "Режим предпросмотра торговой точки",
      points: "Баллы от заведения",
      discount: "Скидка",
      payPoints: "Оплата баллами",
      fromCost: "от стоимости",
      enroll: "Связаться",
      onMap: "На карте",
      aroundTheClock: "Круглосуточно",
      yandex: "Программа Яндекс",
      online: "Онлайн",
      comment: "Комментарий к адресу: ",
      flags: {
        wifi: "WiFi",
        baby_chair: "Детское кресло",
        children_room: "Детская комната",
        animals: "Можно с животными",
        parking: "Парковка",
        no_smoking: "Курение запрещено",
        over_sixteen: "",
      },
    },
    clients: "клиенту: ",
    other: "комиссия: ",
    total: "Итого: ",
  },
  //Employee page
  employee: {
    title: "Colaboratori",
    personName: "Nume, prenume",
    shop: "Punct de vânzare",
    login: "Login",
    password: "Parolă",
    last_name: "Introduceți numele",
    first_name: "Introduceți prenumele",
    createEmployee: "Crearea",
    noShops:
      "Pentru a crea un colaborator, aveți nevoie de cel puțin un punct de vânzare",
    createShop: "Treceți la crearea punctului de vânzare",
    noEmployees: "Nu aveți încă colaboratori",
    addEmployee: "Adăugare colaborator",
    deleteEmployee: "Sunteți sigur că doriți să eliminați colaboratorul?",
    downloadRCPay: "Descarcă RC KASSA",
    //score
    titleScore: "Punctele colaboratorului",
    points: "puncte",
    manageEmployee: "Кассиры",
  },
  // Managing managers page
  managers: {
    title: "Gestiunea managerilor",
    noManagersError: "Nu aveți încă manageri",
    addManager: "Adăugare manager",
    managerCreated: "Managerul a fost creat",
    managerReset: "Parola managerului a fost resetată!",
    oneTimePass: "Parola de unică folosință a managerului",
    messageManagerCreated:
      "Atenție! Notați această parolă, la prima autorizare a managerului, el va trebui să creeze o nouă parolă.",
    changePass:
      "Внимание! Запишите данный пароль, при первой авторизации менеджера, ему необходимо будет создать новый пароль",
    add: {
      title: "Adăugare manager",
      second_name: "Numele managerului",
      first_name: "Prenumele managerului",
      patronymic: "Patronimicul managerului",
      position: "Funcția managerului",
      phone: "Numărul de telefon al managerului",
      permissions: "Accesul la paginile de cont",
      button: "Creare manager",
    },
    //change
    changeTitle: "Modificare manager",
    buttonChange: "Modificați datele managerului",
    buttonResetPassword: "Setare parolă",
    //reset password
    newPassword: "Introducerea parolei noi",
    newPassRules1: "Trebuie să conțină nu mai puțin de 8 simboluri",
    newPassRules2: "Să conțină litere și cifre ale alfabetului latin",
    //delete manager
    deleteManager: "Sunteți sigur că doriți să eliminați managerul?",
  },
  //Documents page
  documents: {
    title: "Documente",
    descriptionAcceptance: "Plată de probă",
    descriptionCertificate: "Certificat",
    instruction: "Instrucțiune",
    shortInstruction: "Краткая инструкция",
    instructionDescription: "de utilizare a aplicației RC KASSA",
    instructionCabinetDescription: "de lucru cu contul personal al ÎMS",
    shortInstructionRCKassa: "по работе с приложением RC KASSA",
    marketingMaterials: "Materiale de marketing",
    marketingMaterialsDescription1:
      "Fișiere pentru aranjarea punctelor și șabloane de pliante",
    agreementMerchantOffer: "Contract cu ÎMS ofertă",
    agreementMerchantOfferDescription: "Contract de muncă cu ÎMS",
    instructionCashier:
      "Instrucțiunea de utilizare a aparatului de casă pentru casier",
    commercialOffer: "Ofertă comercială",
    instructionRCKassa: "de utilizare a aplicației rc.kassa",
    dateOfSigningContract: "Дата подписания договора ",
  },

  //check counterparties page
  counterparties: {
    title: "Проверка контрагентов",
    buttonCheck: "Проверить",
    question:
      "Дата регистрации, ИНН, КПП, ОГРН,  Основной вид деятельности, Статус юрлица,ИФНС, Юридический адрес, Ген. директор, Учредители, Уставный капитал",
    instructionButton: "Инструкция для проверки контрагентов",
    listTitle: "Как использовать Бот для проверки контрагентов?",
    listItemFirst:
      "Введите ИНН или ОГРН организации, которую собираетесь проверять.",
    listItemSecond: "Telegram-Бот покажет вам всю необходимую информацию.",
    listItemThird:
      "Для проверки блокированных счетов нужно нажать на кнопку «Проверить блокировку счетов».",
    listItemFourth:
      "После нажатия на кнопку «Проверить блокировку счетов» Бот покажет все заблокированные счета (при их наличии), ФНС, дату блокировки, № постановления, основание  в соответствии со статьей. Данную информацию можно скачать в PDF-формате для подробного изучения: нажмите кнопку «Скачать файл отчетом».",
    instructionCardTitleFirst: "Зачем проверять контрагентов?",
    instructionCardTitleSecond: "Своевременно узнавайте о фактах.",
    instructionCardTextFirst:
      "<p>Проверка контрагентов необходима для оперативного выявления финансовых и налоговых рисков, которые могут возникнуть при работе с контрагентом, и принятия обоснованных решений — продолжить или прекратить сотрудничество. <br> Бот автоматически проверяет новых и текущих контрагентов по разным источникам и сообщает вам о критических факторах и сведениях, требующих внимания.</p>",
    instructionCardTextSecond:
      "<p>С помощью бота вы можете проводить массовую проверку и мониторинг изменений по всей базе контрагентов или по конкретной выделенной. Это позволяет вовремя реагировать на существенные изменения у клиентов и партнеров, снижая риск невозврата денежных средств.</p>",
  },

  //business page
  business: {
    title: "Afacere",
    name: "Denumirea afacerii",
    type: "Tipul de activitate",
    status: "Statut",
    answear: "Răspunsul moderării",
    noBusiness: "Nu aveți încă afacere",
    addBusiness: "Adăugare afacere",
    createBusiness: "Создать бизнес",
    //about
    logoAndBanner: "Logoul și bannerul societății",
    shops: "Puncte de vânzare",
    socials: "Linkurile la rețelele sociale",
    description: "Descrierea afacerii",
    changeBusiness: "Modificarea datelor afacerii",
    fileSizeLogo: "Raportul părților logoului 1 la 1",
    fileSizeBanner: "Raportul părților bannerului 2 la 1",
    website: "Site-ul de web",
    inst: "Instagram",
    vk: "VK",
    fb: "Facebook",
    //add
    add: {
      title: "Crearea afacerii",
      name: "Introduceți denumirea afacerii",
      nameInternational:
        "Introduceți denumirea internațională a afacerii (numai în engleză)",
      logo: "Încărcați logoul societății",
      banner: "Încărcați bannerul pentru catalog",
      type: "Indicați tipul de activitate",
      typeInternational: "Indicați tipul internațional de activitate",
      shops: "Conectați punctele de vânzare",
      socials: "Adăugați linkurile la rețelele sociale",
      description: "Adăugați descrierea afacerii",
      descriptionInternational:
        "Introduceți descrierea internațională a afacerii (numai în engleză)",
      button: "Adăugare afacere",
      activity_type: "Tip de activitate",
      filePrompt:
        "<li>Файл не может быть больше 10МБ</li><li>Соотношение сторон логотипа 1 к 1</li>",
    },
    //change
    titleChange: "Modificarea afacerii",
    categories: {
      titles: {
        food: "Alimente",
        shopping: "Cumpărături",
        health_and_beauty: "Sanatate si frumusete",
        technique: "Tehnică",
        house: "Casa",
        for_children: "Pentru copii",
        entertainment: "Divertisment",
        sport: "Sport",
        hobby: "Hobby",
        services: "Servicii",
        other: "Другой",
        "arenda-i-bronirovanie": "Аренда и бронирование",
      },
      activities: {
        cafe: "O cafenea",
        restaurants: "Restaurante",
        shops: "Magazinele",
        bars_and_clubs: "Baruri și cluburi",
        fast_food: "Fast food",
        coffee_shops: "Cafele",
        canteens: "Cantine",
        special: "Special",
        clothing: "Îmbrăcăminte",
        shoes: "Pantofi",
        accessories: "Accesorii",
        cosmetics: "Produse cosmetice",
        care: "Îngrijire",
        spa: "Spa",
        medicine: "Medicament",
        pharmacy: "Farmacii",
        electronics: "Electronică",
        appliances: "Aparate",
        office_equipment: "Echipamente de birou",
        communication_salons: "Saloane de comunicare",
        household_goods: "Bunuri gospodărești",
        repair: "Reparație",
        furniture: "Mobila",
        garden: "Grădină",
        clothes_and_footwear: "Haine și pantofi",
        toys: "Jucării",
        school: "Şcoală",
        education: "Educaţie",
        parks: "Parcuri",
        cinema: "Cinema",
        spaces: "Spatii",
        tourism: "Turism",
        special_form: "Specialist. forma",
        movement: "Circulaţie",
        gyms: "Salle de sport",
        sections: "Secțiuni",
        goods_for_creativity: "Bunuri pentru creativitate",
        specialty_stores: "Magazine de specialitate",
        studios: "Studiouri",
        renovation_and_construction: "Reparații și construcție",
        health_and_beauty: "Sanatate si frumusete",
        computer_help: "Ajutor de calculator",
        photo_and_video: "Fotografie și video",
        gifts_holidays: "Cadouri, sărbători",
        supermarkets: "Supermarketuri",
        leisure: "Agrement",
        auto: "Auto",
        pet_supplies: "Accesorii pentru animale",
        renovations_and_interiors: "Renovare și interioare",
        rent_and_hire: "Închiriază și închiriază",
        books_and_stationery: "Cărți și articole de papetărie",
        jewelry: "Bijuterii",
        trips: "Excursii",
        gas_station: "Benzinărie",
        halal: "Halal",
        children_clothes_and_footwear: "Haine și pantofi",
        sport_clothes_and_footwear: "Haine și pantofi",
        other: "Другое",
      },
    },
    messages: {
      message: "Не верный тип файла!",
      caption: "Файл должен быть jpg или png",
      error_message: "Загрузите логотип и баннер!",
    },
  },
  //Tariffs page
  tariff: {
    title: "Tarife",
    yourTariff: "Tariful tău",
    lite_p: {
      title: "Лайт",
      list: [
        "Регистрация 1 организации",
        "Создание 5 торговых точек",
        "Упрощенная статистика",
        "Доступ к RC KASSA",
        "Электронный документооборот",
        "Взаиморасчеты 2 раза в месяц",
        "Раздел вопросов и ответов",
      ],
    },

    lite_p_nko: {
      title: "Базовый",
      list: [
        "Первоначальный тариф",
        "Доступен ограниченный функционал кабинета",
      ],
    },

    promo_p: {
      title: "Промо",
      beforeTariff: "Доступно всё из тарифа Лайт...",
      list: [
        "Регистрация 15 организаций",
        "Создание 15 торговых точек",
        "Добавление 150 товаров",
        "Push-рассылка для клиентов",
        "Оцифровка клиентов",
        "Распространение карт RC",
        "Дизайн Wallet-карты",
        "Электронное меню",
        "Функция обратной связи",
        "Приоритетная поддержка",
      ],
    },

    pluspro_p: {
      title: "Про+",
      beforeTariff: "Доступно всё из тарифов Лайт и Промо...",
      list: [
        "Регистрация 30 организаций",
        "Создание 30 торговых точек",
        "Расширенная статистика",
        "Добавление бесконечного числа товаров",
        "Раздел электронного меню и категории",
        "Приоритетная поддержка",
      ],
    },

    corporate: {
      title: "Корпоративный",
      list: [
        "Оцифровка клиентов",
        "Функционал «Пригласить»",
        "Подраздел «Список клиентов»",
        "Дизайн собственной Wallet-карты",
        "Push-рассылка для клиентов",
        "Функция обратной связи",
        "Акция «-50% на продление тарифа»",
        "<p>Монетизация до 30% от начисленного \n" +
          'вознаграждения клиента <span style="color: #CD5959">*</span></p>',
      ],
    },

    faq: [
      {
        title: "Как происходит оцифровка клиента?",
        text:
          "<p>Для оцифровки клиента вам необходимо предоставить ему промокод. Он может быть в виде реферальной ссылки, QR-кода или непосредственно самого кода оцифровки. С его использованием клиенту необходимо пройти регистрацию в клиентском сервисе rcpay.ru.</p>" +
          "<p>Обратите внимание: чтобы считать QR-код, клиенту понадобится камера с соответствующей функцией или специальное приложение для считывания QR-кодов.</p>",
      },
      {
        title: "Что значит «активный» клиент?",
        text:
          "<p>Это оцифрованный вами клиент, который совершил покупку в онлайн- или офлайн-заведении экосистемы RC Group. Онлайн-покупки подразумевают шопинг в RC Market, приобретение сертификатов, активацию сервисов Яндекс, покупку пользовательского тарифа. Операции в офлайн — это покупки в заведениях в городе с использованием RC.Kassa.</p>" +
          "<p>При покупке тарифа «Корпоративный» каждый партнер участвует в акции  «скидка 50%» на продление тарифа. Основное условие этой акции — оцифровать от 500 «активных» клиентов.</p>",
      },
      {
        title: "Что будет, если не продлить тариф?",
        text: "<p>В таком случае функционал кабинета сократится до уровня тарифа «Базовый». Вам останется доступен подраздел «Список клиентов», но вы лишитесь возможности выпускать карты Wallet, отправлять Push-уведомления и доступа к сервису «Безналичные чаевые».</p>",
      },
      {
        title: "Как привлечь и оцифровать больше клиентов?",
        text: "<p>Используйте функционал «Поделиться» для размещения в соцсетях реферальной ссылки на оцифровку. А также размещайте тейбл-тенты, наклейки-стикеры, листовки, воблеры в вашем заведении.</p>",
      },
      {
        title: "Моего вопроса нет, как получить ответ?",
        text:
          "<p>Задайте вопрос в техподдержку. Для этого нажмите на значок RC Group в правом нижнем углу, выберите «Telegram» и опишите в сообщении свою проблему, по необходимости приложите скрины. С вами обязательно свяжется наш специалист, чтобы решить вопрос в кратчайшие сроки. </p>" +
          "<p>Также можно отправить письмо на email: <b>hello@rc.company</b> </br>" +
          "или позвонить по телефону: <b>8 (800) 500-57-62.</b></p>",
      },
    ],
    // developmentTariff: "Tarif la elaborare",
    // lite: {
    //   ttl1: "Înregistrarea 1 organizații",
    //   descr1: "Crearea a 5 puncte de vânzare",
    //   product1: "Возможность создать 20 товаров",
    //   ttl2: "Statistică simplificată",
    //   descr2: "Lucrul cu organizațiile și punctele de vânzare",
    //   ttl3: "Acces la RC KASSA",
    //   descr3: "Facturi și acte online",
    //   ttl4: "Materiale promoționale",
    //   descr4: "FAQ"
    // },
    // promo: {
    //   ttl1: "Toate avantajele tarifului Lite",
    //   descr1: "Înregistrarea a 15 organizații",
    //   ttl2: "Crearea a 15 puncte de vânzare",
    //   descr2: "Statistici suplimentare",
    //   product2: "Возможность создать 150 товаров",
    //   ttl3: "Profilul clientului",
    //   descr3: "Push-mailing pentru clienți",
    //   ttl4: "Funcția de conexiune inversă",
    //   descr4: "Designul propriei cartele Wallet"
    // },
    // proplus: {
    //   ttl1: "Toate avantajele tarifului Promo",
    //   descr1: "Înregistrarea a 30 organizații",
    //   ttl2: "Crearea a 30 puncte de vânzare",
    //   descr2: "Statistici detaliate",
    //   product2: "Безлимит на создание товаров",
    //   ttl3: "Lista clienților",
    // },
    // popup: {
    //   chosePeriod: "Alegeți perioada",
    //   description: "Poate fi prelungită oricând",
    //   organization: "Organizația de radiere",
    //   fullPrice: "Costul total",
    // },
  },
  //History of payment page
  history: {
    title: "Istoricul operațiunilor",
    number: "Număr",
    entityName: "Denumirea organizației juridice",
    type: "Tipul operațiunii",
    typeBuyTariff: "Achiziționarea tarifului",
    typeAddBalance: "Suplinirea soldului",
    typePaymentAct: "Plata facturii conform actului",
    typePaymentAcceptance: "Efectuarea plății de probă",
    amount: "Sumă",
    currency: "Valuta",
    date: "Data și ora",
    noHistory: "Istoricul Dvs. este nul",
    operationsTab: "Toate operațiunile",
    processingTab: "RC Processing",
    reward: "Вознаграждение",
    paidFromPurchase:
      "{cash} {currency} оплачено <span>(с покупки на {amount} {currency})</span>",
    downloadModal: {
      title: "Скачать файл",
      fileType: "Тип файла",
      sendOnEmail: "Отправить копию на почту",
    },
  },
  //actsAndAccounts page
  actsAndAccounts: {
    title: "Acte și facturi",
    number: "Număr",
    name: "Denumire",
    amount: "Sumă",
    date: "Data și ora",
    status: "Statut",
    button: "Descărcare",
    accounts: "Facturi",
    acts: "Acte",
    actsNew: "Акты (новые)",
    noActs: "Nu aveți încă acte",
    buttonDetail: "Vizualizare detaliată",
    creteAccount: "Formare factură",
    accountsTypeText: "Factură spre plată",
    actsTypeText: "Actul operațiunilor efectuate pentru perioada",
    noAccounts: "Lipsa facturii",
    arbitraryAct: "Formarea unui act aleatoriu",
    choseDate: "Selectați data",
    downloadAct: "Descărcați actul",
    viewAct: "Vizualizați actul",
    downloadArbitraryAct: "Descărcarea actului aleatoriu",
    noCustomActs: "Pentru perioada selectată nu sunt bonuri de casă",
    hideTitle: "«Акты и счета» временно находятся на обновлении",
    hideBody:
      "<div>" +
      "<p>Обращаем Ваше внимание, что подраздел <b>«Акты и счета»</b> <br> в разделе <b>«Мои предприятия»</b> временно находится на обновлении.</p>" +
      "<p> Сформировать счет на оплату Вы сможете в разделе <b>«Баланс»</b>. <br> Выберите опцию <b>«Пополнить»</b> и <b>«Ввести сумму»</b>, введите сумму <br> и нажмите <b>«Выставить счет»</b>, после чего — <b>«Скачать счет»</b>. </p>" +
      "<p> Акт сверки можно запросить через тех. поддержку, написав на почту <a href='mailto:{link}'>{link}</a>.<br> Для обращения укажите юр. наименование и период,<br> за который Вам необходим акт сверки. </p>" +
      "<p>Приносим извинения за доставленные неудобства.</p>" +
      "<p><b>С уважением, RC Group.</b></p>" +
      "</div>",
  },
  // Claims and history claims page
  claims: {
    title: "Cereri",
    historytitle: "Istoricul cererilor",
    noClients: "Până acum nu ați avut un singur client",
    oneClient: "Ai avut un singur client",
    group_name: "Название группы",
    group_nomination: "Наименование группы",
    group_sum: "Сумма покупок (без учета скидок)",
    create_group: "Создать новую группу",
    edit_group: "Редактировать группу",
    delete_group: "Вы уверены что хотите удалить группу?",
    sum: "Сумма",
    percent: "Процент начисления",
  },
  // Reviews page
  review: {
    title: "Отзывы в RC PAY",
    pageHint:
      "Данный подраздел предназначен для сбора обратной связи от посетителей ваших заведений, которая будет размещена на сайте rcpay.ru. Вы можете сами управлять какие отзывы отображать, кликнув на значок «глаз»",
    goodFirst: "Сначала положительные",
    badFirst: "Сначала отрицательные",
    newFirst: "По новизне",
    noTradingPointsData:
      "Для просмотра отзывов, необходимо создать торговую точку",
    hint: "Отзыв зависит от фильтра по датам",
    noReviews: "У вас еще нет отзывов",
    all: "Все отзывы",
    shown: "Отображаемые",
    hidden: "Скрытые",
    hideStatus: "Отзыв будет скрыт со страницы отзывов на сайте rcpay.ru",
    showStatus: "Показать отзыв на странице Вашей торговой точки на rcpay.ru?",
    noDataLabel: "Данных по отзывам нет",
    noTradingPointText: "У вас пока нет Торговых точек ",
    noReview: "Пользователи еще не оставляли отзывы о Вашей торговой точке",
  },
  //login manager an login page
  login: {
    title: "Bună ziua!",
    subTitle: "Vă rugăm să vă autorizați.",
    loginInput: "Login",
    email: "Email",
    passwordInput: "Parolă",
    approvePasswordInput: "Confirmarea parolei",
    passwordMismatch: "Parolele nu coincid",
    backArrow: "Revenire la pagina principală",
    forgotPassword: "Ați uitat parola?",
    noAccount: "Nu aveți cont?",
    register: "Înregistrează-te",
    backToService: "Отменить и вернуться к сервису",
    repeatPassword: "Повторите пароль",
  },
  //analytic page
  analytics: {
    title: "Analitică",
    organizationSelect: "Выберите Юр лицо",
    organizationButton: "Юр лица",
    organizationCheckbox: "Toate organizațiile",
    newOrganizationCheckbox: "Все Юр лица",
    calendar: "Calendar",
    organizations: {
      newClients: "Clienți noi",
      allClients: "Toți clienții",
      sales: "Vânzări",
      amount: "Suma vânzărilor",
      amountCashback: "Din ele eliberat în baza punctelor",
      average: "Bon de casă mediu",
      averageCashback: "Remunerație medie",
    },
    countClients: "Cantitatea de clienți",
    topSumEmployee: "Topul colaboratorilor după suma de vânzări",
    topSaleEmployee: " Topul colaboratorilor după vânzări",
    points: "Puncte",
    addPoints: "Calculare puncte",
    writePoints: "Radiere: puncte",
    rfmAnalysis: "RFM Анализ",
    rfmGroupSizes: "Размер RFM групп",
    clientPortrait: {
      btn: "Портрет клиента",
      title: "Клиенты",
      customerCount: "Количество клиентов:",
      receiptAvg: "Средний чек:",
      receiptCount: "Количество продаж:",
      receiptSum: "Сумма продаж:",
      maleFemale: "М/Ж:",
      noGender: "Пол не указан:",
    },
  },
  // Add points page
  points: {
    title: "Calcularea punctelor",
    subTitle:
      "Introduceți numărul de puncte care vor fi acordate angajaților pentru operațiune.",
    pointsForPoints: "Puncte pentru calcularea punctelor",
    pointsForNewClient: "Puncte pentru calcularea punctelor clientului nou",
    employeePoints: "Очки кассирам",
  },
  // Cashback page
  cashback: {
    title: "Calcularea punctelor",
    subTitle:
      "Specificați procentul de remunerație pe care îl vor primi clienții RC",
    statusName: "Clienții fără card de brand",
    delay: "Задержка начислений (В днях)",
    setDelay: "Изменить задержку",
    editDelay: "Изменить задержку начислений",
    maxDelay: "Максимальная задержка 14 дней",
    setReward: "Установить вознаграждение",
    selectGroup: "Выберите группу",
    rewardPercent: "Введите процент начисления",
    minReward: "Минимальный процент начисления - 4",
    maxReward: "Максимальный процент начисления - 25",
    delayTitle: "Текущая задержка торговой точки:",
    withoutActiveWait:
      "Ваш пользователь получит вознаграждение в течении 30 минут с момента проведения операции",
  },
  // Spread page
  spread: {
    title: "Распространение карт RC",
    p1: "Scanând acest cod, clienții dvs. vor putea primi cartela.",
    p2: "Vă recomandăm insistent să amplasați table-tent cu un cod QR și inscripția „Descărcați cartela de fidelitate!” Acumulați puncte de fidelitate și obțineți condiții speciale pentru achiziții” în apropiere de casă sau de la locul unde clienții dvs. fac achitarea. De exemplu, la mesele din cafenele, în zona de așteptare de lângă casieria dintr-un magazin sau în altă parte, în așa fel încât clienții dvs. să poată vedea aceste informații.",
    getCard: "Recepționarea cartelei în baza linkului",
    ttl1: "Recepționarea cartelei prin RC KASSA",
    p4: "Utilizați aplicația RC KASSA pentru a afișa codul QR accesând elementul de meniu ”Emitere cartelă Wallet”. Propuneți clientului să scaneze codul cu telefonul său mobil. În ciuda simplității obținerii unei cartele în acest fel, vă recomandăm să imprimați și să plasați codul QR cu un îndemn la acțiune lângă casierie.",
    ttl2: "Lucrul cu colaboratorii",
    p5: "Experiența mondială arată că angajații boicotează adesea introducerea programelor de loialitate. Acest lucru se referă nu numai plasticului vechi, ci și soluțiilor digitale, cum ar fi RC Group. Multor angajați li se poate părea că, spunând clienților despre programul de loialitate, reduc veniturile organizației. Această afirmație este greșită. Cu cât distribuția programului de loialitate este mai mare, cu atât este mai mare cecul mediu, frecvența achizițiilor și loialitatea față de brand. Mai mult, distribuția ridicată a programului vă permite să reduceți costurile de afaceri pentru SMS și alte mesaje de email și să vă cunoașteți mai bine propriii clienți.",
    coped: "Copiere",
  },

  // Wallet page
  wallet: {
    title: "Designul cartelei Wallet",
    viewCard: "Aspectul cartelei Wallet",
    orderCard: "Comandare design al cartelei",
    imageFont: "Introduceți o imagine pentru fundalul cartelei dvs. Wallet",
    imageLogo: "Introduceți logoul cartelei dvs. wallet",
    choseTitle:
      "Alegeți culoarea codului QR, a titlului și a numărului cartelei",
    colorQR: "Culoarea codului QR",
    colorTitle: "Culoarea titlului",
    colorNumberCard: "Culoarea numărului cartelei",
    confirmDesign: "Confirmare design",
    clearDesign: "Revenire la design standard",
    titleNumber: "Numărul cartelei",
    claimSend: "Cererea a fost expediată!",
    expectCall: "Așteptați apelul managerului nostru.",
    orderConfirmDesign: "De expediat cererea dvs. pentru crearea de design?",
    //new
    selectShop: "Выберите торговую точку",
    settingWalletColor: "Настройте цвет карты",
    colorBackground: "- Цвет фона",
    colorText: "- Цвет надписей",
    colorSubstrate: "- Цвет подложки",
    addLogo: "Добавьте логотип",
    withoutLogo: "Нет логотипа",
    haveLogo: "Есть логотип",
    addSubstrate: "Выберите цвет подложки",
    defaultColor: "Залить основным цветом",
    customColor: "Залить собственным цветом",
    customImage: "Установить изображение",
    logoPrompt:
      "<li>Файл не может быть больше 5МБ</li><li>Соотношение сторон логотипа 1 к 1</li>",
    substratePrompt:
      "<li>Файл не может быть больше 5МБ</li><li>Соотношение сторон подложки 3 к 1</li>",
    saveCustomDesign: "Сохранить дизайн",
    backDefaultDesign: "Вернуть стандартный дизайн",
    saveModal: "Отправить ваш запрос на создание дизайна?",

    // wallet-card
    walletCard: {
      title: "Вид Wallet карты",
      discount: "Скидка",
      balance: "Баланс",
    },
  },

  // Push notifications page
  notifications: {
    title: "Push-уведомления",
    modal:
      "Рассылка находится в обработке, Ваши пользователи получат push-уведомление в течение 15 минут",
    description:
      "Внимательно прочитайте текст. После создания уведомления, изменить его будет <span>невозможно.</span>",
    notificationText: "Текст уведомления",
    sendError: "Не удалось отправить Push-уведомление",
    sendTime: "По московскому времени",
    noNotifications: "У вас нет созданных push-уведомлений",
    buttonNoData: "Создать PUSH-сообщение",
    table: {
      shopName: "Название торговой точки",
      header: "Заголовок",
      date: "Дата",
      time: "Время",
    },

    view: {
      shop: "Торговая точка",
      header: "Заголовок",
      message: "Текст сообщения",
      date: "Дата",
      time: "Время",
    },

    create: {
      warning:
        "Внимательно прочитайте текст. После создания уведомления, изменить его будет <span>невозможно.</span>",
      shop: "Выбор торговой точки",
      header: "Текст заголовка",
      message: "Текст сообщения",
      button: "Создать рассылку",
      messageAfterCreate:
        "Рассылка находится в обработке, Ваши пользователи получат push-уведомление в течение 15 минут",
    },
  },

  //Clients Page
  clients: {
    title: "Список клиентов",
    allClients: "Все клиенты",
    internalClients: "Внутренние",
    externalClients: "Внешние",
    clientsType: "Тип клиента",
    noTradingPoints: {
      label: "Данных по клиентам нет",
      text: "У вас пока нет Торговых точек",
    },
    noClients: {
      label: "Данных по клиентам нет",
      text: "Оцифровывайте клиентов, чтобы они отображались в вашем списке",
    },
    table: {
      inviteDate: "Дата регистрации",
      fullName: "ФИО",
      phone: "Номер телефона",
      receiptsCount: "Количество покупок",
      receiptsSum: "Сумма покупок",
    },

    clientCard: {
      title: "Информация о клиенте",
      client: {
        title: "Клиент",
        fields: {
          fullName: "ФИО",
          gender: "Пол",
          birthDate: "Дата рождения",
          phone: "Номер телефона",
          clientType: "Тип клиента",
          inviteDate: "Дата регистрации",
          rcCard: "Номер карты RC",
          note: "Заметка",
          noteMax: "Макс. 1000 символов",
        },
      },
      statistics: {
        title: "Статистика",
        titles: {
          avgReceiptsSum: "Средний чек",
          receiptsCount: "Кол-во покупок",
          receiptsSum: "Оборот",
          writeOffPointsSum: "Опл. баллами",
        },
        totalNumberOfVisit: "Общее кол-во посещений",
        firstVisit: "Первое посещение",
        lastVisit: "Последнее посещение",
      },
      receipts: {
        title: "Чеки",
        status: {
          approved: "Проведен",
          new: "В ожидании",
          declined: "Отменен",
        },
        noReceipts: "Данных по чекам нет",
      },
      reviews: {
        title: "Отзывы",
        noReviews: "Данных по отзывам нет",
      },
    },
  },

  // Client Portrait Page
  clintPortrait: {
    title: "Portretul clientului",
  },

  // Terminals Page
  terminal: {
    listServiceCenters: "Lista centrelor de deservire",
    mspos: "Инструкция mspos",
    instructions1C: "Instrucțiune 1C",
    title: "Comandarea aparatelor de casă",
    typeTerminal: "Produs",
    terminalName: "Aparatul de casă MSPOS E-Ф",
    fullNameTitle: "Datele beneficiarului",
    fullName: "NPP ale beneficiarului",
    postAddress: "Adresa poștală",
    acquirerBank: "Selectarea băncii cumpărătorului",
    countTerminal: "Cantitatea de aparate",
    fullPrice: "Cost",
    delivery:
      "Vă rugăm să rețineți că livrarea se achită separat. În ceea ce privește livrarea, managerul vă va contacta suplimentar.",
    buy: "Cumpără",
    documents: "Documente",
    status: "Statut",
    // Documents
    specifications:
      "Instrucțiunea de utilizare a aparatului de casă pentru casier",
    specificationsDescription: "Descrierea dispozitivului",
    instruction: "Instrucțiune",
    instructionDescription: "Descrierea dispozitivului",
    manual: "Instrucțiunea de utilizare a aparatului",
    manualDescription: "Descrierea dispozitivului",
    commercialOffer: "Ofertă comercială",
    commercialOfferDescription: "Descrierea dispozitivului",
    // Status
    buttonAddClaim: "Lăsați încă o cerere",
    device: "Dispozitiv",
    deviceName: "Denumire",
    count: "Cantitate",
    aboutOrder: "Despre comandă",
    // Buy
    firstBlock: {
      title:
        "RC Group propune partenerilor săi o soluție complexă MSPOS 4 în 1",
    },
    secondBlock: {
      title: "Aparatul de casă MSPOS E-Ф",
      circle1: "Fără plăți ascunse",
      circle2: "Corespunde 54-LF",
      circle3: "Licența 1C cadou",
      circle4: "Nu vindem datele clienților",
      characteristics: "Caracteristici",
      OS: "Sistemul operațional",
      dimensions: "Dimensiuni",
      cm: "cm",
      weight: "Greutate",
      g: "gr",
      memory: "Memorie",
      screen: "Afișaj",
      screenDescription:
        "5,5 inci, 1280x720 px (pixeli), 320 ppi (pixeli pe inch)",
    },
    thirdBlock: {
      title: "Sistem operațional de plată",
      description:
        "Platforma de plată universală pentru automatizarea oricărei sarcini de cumpărare și achiziție prin Internet",
    },
    fourthBlock: {
      title: "SO de casă",
      description:
        "<ul> <li>Stație de lucru pentru casierie ușor de învățat</li><li>Vânzarea mărfurilor etichetate</li><li> Introducerea și editarea de noi produse, gestionarea obiectivelor</li><li> Vânzările de alcool (necesită conectarea la un UTM extern) </li><li>Trimiterea directă a rapoartelor de vânzări către e-mailul specificat</li><li> Sincronizare simplă cu serviciul Internet 1C: Casa </li><li> Integrare prin Enterprise Data și protocoale offline KKM</li> <li>Este posibil să-l utilizați în modul auto-checkout</li> </ul>",
    },
    fifthBlock: {
      title: "Program de loialitate",
      subtitle1: "Solicitați reduceri și primiți plată cu puncte",
      kassaAllows: "RC KASSA permite",
      description:
        "<li>Să generați rapoarte la tranzacții </li><li> Să acceptați numerar, cartele bancare și puncte RC </li><li> Să urmăriți punctele colaboratorilor</li><li> Să urmăriți punctele colegilor colaboratorilor</li>",
      stayClaim: "Lăsați o cerere",
    },
    //LIMON
    Limon: {
      title: "Технология MPOS",
      subTitle:
        "Аренда кассовых аппаратов. Зачем покупать, если можно арендовать?",
      button: "Оставить заявку",

      firstBlock: {
        title: "mPOS + Android",
        pros: [
          "Простой и удобный интерфейс приема платежа",
          "Можно отправить банковского слип-чек клиенту по смс/email",
          "История всех совершенных операций всегда под рукой",
          "В описании платежа можно вводить дополнительную информацию",
          "Работает в связке по  bluetooth со смартфоном (версия андроид от 8.1)",
          "Банковские карты (с магнитной полосой, чипом и NFC-модулем)",
          "Интеграция с любыми программами и системами",
        ],
        tableTitle: "Технические характеристики",
        table: [
          [
            "Интеграция с ККМ:  да",
            "Тип терминала:  мобильный",
            "Беспроводное подключение: да",
          ],
          [
            "Печать слипа: нет",
            "Бесконтактные платежи: да",
            "Проводное подключение: нет",
          ],
          ["Магнитные карты: да", "Экран: монохромный", "Карты с чипом: да"],
        ],
        benefit: [
          "Бесплатное оборудование для приема карт",
          "Соответствует 54-ФЗ",
          "Кассовая программа работает на платформе Android",
          "Принимает платежи всеми доступными на рынке способами",
          "Интеграция с любыми программами и системами, автоматизация финансового учета",
          "Оптимально для такси, курьерских служб и стационарных точек продаж с небольшим оборотом",
        ],
      },

      secondBlock: {
        title: "Смарт-терминал 4 в 1 со встроенным эквайрингом",
        pros: [
          "Кассовая программа",
          "Кассовое оборудование",
          "Терминал для приема банковских карт",
          "Программа лояльности",
          "Облачный бэк офис для предпринимателей",
        ],
        tableTitle: "Технические характеристики",
        table: [
          ["Размеры: 185х75х65 мм", "Вес: 408 грамм "],
          ["Доступ к интернету: Wi-Fi/2G /3G/4G", "Основная камера: 8 МП "],
          ["Навигация: GPS", "Необходим РКО: Нет"],
          [
            "Аккумулятор: 8 часов без подзарядки",
            "Фронтальная камера: 0,3 МП ",
          ],
        ],
        benefit: [
          "Быстрый запуск",
          "Соответствует 54-ФЗ",
          "Бесплатная аренда при обороте свыше 500 000 руб/мес",
          "Не требует РКО",
          "Подходит для всех отраслей бизнеса",
          "Интегрируется с большим количеством учетных системам",
        ],
      },

      loyalty: {
        title: "Программа лояльности",
        kassa: "RC KASSA",
        aboutKassa: "Оформляйте скидки и принимайте оплату баллами",
        kassaOpportunities: "RC KASSA позволяет:",
        opportunitiesList: [
          "Формировать отчеты о транзакциях",
          "Принимать оплату наличными, банковской картой и баллами RC",
          "Отслеживать очки сотрудника",
        ],
      },

      documents: {
        title: "Документы",
        about: "Описание устройства",
        date: "18.04.2022",
      },

      modal: {
        warning: "Банком эквайером является ПАО Банк ВТБ",
        fullNameTitle: "ФИО",
        fullNamePlaceholder: "Введите ФИО",
        emailTitle: "Почта",
        emailPlaceholder: "Введите почту",
        phoneTitle: "Телефон",
        phonePlaceholder: "Введите телефон",
        commentTitle: "Комментарий",
        commentPlaceholder: "Введите текст сообщения",
        organization: "Выбор организации",
        terminal: "Выбор аппарата",
        success: "Заявка успешно создана",
      },
    },
    // Terminals About Order Page
    fromOrder: "Despre refuzul de la",
    recipient: "Beneficiar",
    cashRegister: "Aparatul de casă",
    fiscalAccumulator: "Acumulator fiscal",
    courierDelivery: "Livrare cu curier",
    pickupDelivery: "Ridicare personală din punctul de vânzare",
    deliveryError: "В выбранный город доставка не осуществляется",
    insuranceAndPackaging: "Asigurarea mărfii și ambalajului",
    standardInsurance: "Asigurare standard",
    finalSum: "Suma totală",
    downloadInvoiceForPayment: "Descărcați o factură pentru efectuarea plății",
    // Buy-terminal page
    deleteFromClaim: "Anulare din cerere",
    recoverToClaim: "Restabilire",
    driveExpirationDate: "Termenul de valabilitate a acumulatorului",
    descriptionFiscalSelect:
      "Alegerea depinde de specificul afacerii (vânzarea de produse cu accize, catering etc.), forma organizațională și juridică și regimul fiscal. La sfârșitul perioadei sale de valabilitate, acumulatorul trebuie înlocuit.",
    countFiscal: "Cantitatea de acumulatoare",
    countFifteenMonth: "Cantitatea de acumulatoare pentru 15 luni",
    contThirtySixMonth: "Cantitatea de acumulatoare pentru 36 luni",
    amountOfGoods: "Suma pentru bunuri",
    proceedToCheckout: "Treceți la perfectarea comenzii",
    mixedOrder: "Comandă mixtă",
    // Order-terminal Page
    orderTerminalTitle: "Comandarea aparatelor de casă",
    toOrder: "Comandă",
    clickAgree: "Apăsând, eu sunt e acord cu condițiile",
    ifNeedHelp:
      "Dacă aveți nevoie de ajutor pentru înregistrarea aparatului de casă la Serviciul Federal Fiscal, vă rugăm să informați managerul",
    outcome: "Total",
    issueCEP: "Регистрация в налоговой",
    issueOFD: "Регистрация ОФД",
    nalogReg: "Настройка кассы",
    issueCEP_description: "Создание квалифицированной электронной подписи",
    issueOFD_description:
      "Регистрация вашей кассы в операторе фискальных данных",
    nalogReg_description: "Настройка кассы для работы сразу после получения",
    serviceProvision: "Предоставление услуги",
  },

  // FAQ Page
  faq: {
    title: "FAQ",
    cabinetPartner: "RC CITY",
    rcKassa: "RC KASSA",

    sections: {
      analytics: {
        title: "Аналитика",

        subsections: {
          organization: {
            title: "Юр.лица",
            description:
              "В разделе <b>«Аналитика»</b> представлена аналитика по всем вашим заведениям и клиентам, которые используют в RC PAY. Информация представлена на пяти вкладках с акцентом на разные данные." +
              "<br><br>" +
              "На вкладке <b>«Юр лица»</b> можно посмотреть общие данные по клиентам, в том числе новым, а также узнать средний чек, количество покупок и общую сумму продаж. Кроме того, предусмотрена возможность получить подробную информацию по каждой зарегистрированной организации и выбрать интересующий Вас период, что позволит узнать количество продаж за день, неделю, месяц, год.",
            video: [],
          },
          shops: {
            title: "Торговые точки",
            description:
              "На вкладке <b>«Торговые точки»</b> расположена расширенная аналитика предыдущего подраздела: " +
              "<ul class='doted_list'>" +
              "<li>данные по клиентам;</li>" +
              "<li>продажи;</li>" +
              "<li>сумма продаж за необходимый период;</li>" +
              "<li>данные по каждой организации;</li>" +
              "<li>информация об активности сотрудников;</li>" +
              "<li>сумма продаж по каждому сотруднику;</li>" +
              "<li>очки кассирам.</li>" +
              "</ul> Выбрав <b>«Все торговые точки»</b>, можно просмотреть общую информацию по всем имеющимся торговым точкам.",
            video: ["//video.platformcraft.ru/embed/61f795da0e47cf171e558af5"],
          },
          employee: {
            title: "Сотрудники",
            description:
              "На вкладке <b>«Сотрудники»</b> Вы можете отслеживать активность Ваших сотрудников и оценивать их продуктивность за тот или иной период по следующим критериям:" +
              "<ul class='doted_list'>" +
              "<li>имя сотрудника, проводившего транзакцию;</li>" +
              "<li>торговую точку;</li>" +
              "<li>количество продаж;</li>" +
              "<li>сумму продаж;</li>" +
              "<li>очки кассира;</li>" +
              "<li>количество списанных баллов;</li>" +
              "<li>количество начисленных баллов.</li>" +
              "</ul>",
            video: ["//video.platformcraft.ru/embed/61f795ed0e47cf171e558af6"],
          },
          RFM: {
            title: "RFM-анализ",
            description:
              "На вкладке <b>«RFM-анализ»</b> представлен анализ сегментации клиентов по частоте и сумме покупок. Он помогает выявлять тех клиентов, которые больше всего покупают у Вас." +
              "<br> <br>" +
              "Для получения информации выберите <b>«Юр лицо»</b> из всплывающего меню и период в <b>«Календаре»</b>. По итогу отобразится таблица с результатами анализа групп, которые вы сформировали в <b>«Начислении баллов»</b>.",
            video: ["//video.platformcraft.ru/embed/63aeb61c0e47cf7b45610124"],
          },
          clients: {
            title: "Портрет клиента",
            description:
              "На вкладке <b>«Портрет клиента»</b> Вы можете детально просмотреть информацию обо всех клиентах выбранной организации, выявить постоянных клиентов. На графике возраста можно ознакомиться с дополнительными данными по возрастным категориям:" +
              "<ul class='doted_list'>" +
              "<li>количеством клиентов данной возрастной категории;</li>" +
              "<li>их средним чеком;</li>" +
              "<li>количеством покупок;</li>" +
              "<li>суммой покупок.</li>" +
              "</ul>",
            video: ["//video.platformcraft.ru/embed/63aeb91c0e47cf7b45610131"],
          },
        },
      },

      loyaltyProgram: {
        title: "Программа лояльности",

        subsections: {
          addPoints: {
            title: "Начисление баллов",
            description:
              "В разделе «Начисление баллов» Вы можете настроить размер начисляемых баллов. Минимальный процент начисления – 4%, а максимальный – 25%. Чтобы усилить лояльность клиентов, группируйте их в зависимости от суммы покупок и назначайте каждой группе свой процент вознаграждения." +
              "<br>" +
              "Для создания группы клиентов выполните следующие действия:" +
              "<br>" +
              "<ol class='number_list'>" +
              "<li>Выберите торговые точки, для которых Вы хотите создать группу клиентов. Например:" +
              "<ul class='doted_list circle'>" +
              "<li>Новый клиент с покупками на сумму до 10 000 рублей – вознаграждение составит 5%.</li>" +
              "<li>Постоянный клиент с покупками от 10 000 рублей – вознаграждение составит 10%.</li>" +
              "<li>Любимый клиент с покупками от 50 000 рублей – вознаграждение составит 20%.</li>" +
              "</ul></li>" +
              "<li>Нажмите кнопку «Создать группу».</li>" +
              "</ol>" +
              "<br>На этой странице также есть возможность установить <b>«Текущую задержку торговой точки»</b> – это количество дней, спустя которое клиенту будет начислено вознаграждение за покупку. Значение может быть от 0 до 14 дней. Нажмите кнопку <b>«Изменить задержку»</b>, чтобы указать желаемый срок, а затем – <b>«Сохранить»</b>. Обратите внимание: функция будет работать корректно только при положительном балансе в RC CITY.",
            video: ["//video.platformcraft.ru/embed/658b5dd1ef3db51ca61a4ee1"],
          },
          promotions: {
            title: "Акции",
            description:
              "В подразделе <b>«Акции»</b> Вы можете создавать и отслеживать акции для своих заведений сразу или по отдельности. Информация представлена в виде таблицы со столбцами следующего содержания: название акции, торговая точка, где она проходит, процент начисления баллов (не суммируется с процентами начисления баллов из предыдущего раздела), процент скидки. Также напротив каждой акции предусмотрена возможность скрыть из <b>RC Кассы</b> у сотрудника редактировать и удалить." +
              "<br><br>" +
              "Для того, чтобы создать акцию в всплывшем окне укажите:" +
              "<ul class='doted_list'>" +
              "<li>торговую точку,</li>" +
              "<li>название акции,</li>" +
              "<li>процент начисления баллов,</li>" +
              "<li>процент скидки.</li>" +
              "</ul>" +
              "После успешного создания акции Вы можете зайти, как сотрудник в <b>RC KASSA</b>, и при нажатии кнопки <b>«Акции»</b>, отобразиться созданная в кабинете акция. ",
            video: ["//video.platformcraft.ru/embed/63aebbf9ef3db55eb798f8f2"],
          },
          historyPayment: {
            title: "История операций",
            description:
              "На вкладке <b>«Все операции»</b> в подразделе <b>«История операций»</b> будет отображена таблица со всеми пополнениями и списаниями средств с баланса в RC CITY. Чтобы получить более подробную информацию, Вы можете указать нужный вам период в календаре." +
              "<br> <br>" +
              "Также на этой странице Вы можете скачать историю операций за определенный период или за все время использования кабинета. Чтобы сделать это, нажмите кнопку <b>«Скачать»</b>, выберите формат скачивания и, по желанию, установите флажок <b>«Отправить копию на электронную почту»</b>. В этом случае файл будет отправлен на Вашу электронную почту, указанную при регистрации в RC CITY. После этого снова нажмите кнопку <b>«Скачать»</b>." +
              "<br> <br>" +
              "На вкладке <b>RC Processing</b> Вы можете увидеть информацию по всем проведенным операциям на кассовых аппаратах.",
            video: ["//video.platformcraft.ru/embed/658aeba50e47cf2ffec366a7"],
          },
        },
      },

      clients: {
        title: "Клиенты",

        subsections: {
          clients: {
            title: "Список клиентов",
            description:
              "В разделе <b>«Список клиентов»</b> доступна информация о Ваших покупателях. Чтобы просмотреть данные, выберите организацию из выпадающего списка или отметьте флажком пункт «Все организации»." +
              "<br> <br>" +
              "На вкладке <b>«Все клиенты»</b> вы увидите информацию обо всех клиентах Вашего заведения. <br>" +
              "Вкладка <b>«Внутренние клиенты»</b> покажет данные о клиентах, оцифрованных в Вашем заведении. <br>" +
              "На вкладке <b>«Внешние клиенты»</b> представлена информация о клиентах, которые посещали Ваше заведение и сделали в нем хотя бы одну покупку." +
              "<br> <br>" +
              "Внизу страницы Вы также найдете кнопку <b>«Пригласить»</b>, нажав на которую можно скопировать ссылку или скачать QR-код для регистрации клиента.",
            video: ["//video.platformcraft.ru/embed/658b5e700e47cf2ffec3675c"],
          },
          feedback: {
            title: "Отзывы",
            description:
              "В подразделе <b>«Отзывы»</b> отображаются отзывы всех клиентов, которые посещали Ваше заведение и оставили отзыв на сайте rcpay или в приложении RC PAY. <br> Для удобной работы с отзывами доступно несколько фильтров:  " +
              "<ol class='number_list'>" +
              "<li><ul class='doted_list circle'>" +
              "<li>При нажатии кнопки <b>«Выберите торговую точку»</b> отобразятся все отзывы, оставленные клиентом о данной точке</li>" +
              "<li>При выборе пункта <b>«Все торговые точки»</b> Вы сможете увидеть отзывы на все торговые точки.</li>" +
              "</ul></li>" +
              "<li>Предусмотрена возможность посмотреть отзывы за определенный период или дату. Для этого откройте календарь, выберете из списка нужный период или задайте его в календаре</li>" +
              "<li>Отфильтровать отзывы можно по 3 характеристикам: <ul class='doted_list circle'>" +
              "<li>по новизне;</li>" +
              "<li>только положительные;</li>" +
              "<li>только отрицательные. </li>" +
              "</ul></li>" +
              "</ol>" +
              "<br><br>" +
              "Также Вы можете выбрать конкретные отзывы, которые будут отображены на сайте rcpay и в приложении RC PAY. Для этого нажмите на знак просмотра у тех отзывов, которые хотите, чтобы отображались на сайте и в приложении. Обратите внимание, что скрытые отзывы не будут влиять на оценку заведения, которая будет отображаться на сайте.",
            video: ["//video.platformcraft.ru/embed/658b5e01ef3db51ca61a4ee2"],
          },
          pushNotifications: {
            title: "Push-уведомления",
            description:
              "В подразделе <b>«Push-уведомления»</b> можно настроить рассылку для Ваших клиентов, чтобы они всегда были в курсе Ваших акций и мероприятий." +
              "<ol class='number_list'>" +
              "<li>Для этого нажмите плюс напротив «Создать Push-рассылку» и заполните соответствующие поля;</li>" +
              "<li>Выберите Торговую точку, на клиентов которой планируете делать рассылку;</li>" +
              "<li>Укажите текст заголовка (например: «праздничные скидки всем»);</li>" +
              "<li>Введите текст сообщения push-уведомление в течение 15 минут. Обратите внимание на то, что после создания уведомления изменить его будет невозможно. Все разосланные оповещения отображаются в виде таблицы на этой же странице. По каждому можно нажать «Подробнее» и посмотреть Точку продаж, на которую сделали рассылку, дату отправки и текст.</li>" +
              "</ol>" +
              "<br>" +
              "Ваши клиенты получат push-уведомление в течение 15 минут. Обратите внимание на то, что после создания уведомления изменить его будет невозможно. Все разосланные оповещения отображаются в виде таблицы на этой же странице. По каждому можно нажать «Подробнее» и посмотреть Точку продаж, на которую сделали рассылку, дату отправки и текст.\n",
            video: ["//video.platformcraft.ru/embed/63aeb56e0e47cf7b45610121"],
          },
        },
      },

      wallet: {
        title: "Карты Wallet",

        subsections: {
          spread: {
            title: "Распространение карт Wallet",
            description:
              "Карта Wallet — это электронный эквивалент карт магазинов и заведений. В подразделе <b>«Распространение карты Wallet»</b> можно получить QR-код для использования его в оцифровке клиентов, а также ссылку для скачивания карты, которую можно разместить в социальных сетях, на сайте и любых других источниках, что позволит оцифровывать клиентов в любой точке мира.",
            video: ["//video.platformcraft.ru/embed/61f796daef3db553eb6a2312"],
          },
          designWallet: {
            title: "Дизайн карты Wallet",
            description:
              "В разделе <b>«Дизайн карты Wallet»</b> предусмотрена возможность создания уникального дизайн ваших карт. Для этого необходимо:" +
              "<ol class='number_list'>" +
              "<li>Выбрать вариант из выпадающего списка в разделе <b>«Выбрать организацию»</b>, для которого Вы хотите создать карты Wallet.</li>" +
              "<li>Выбрать фон: перетащите или загрузите файл с изображением в окно <b>«Вставьте картинку для фона вашей Wallet-карты»</b>. Обратите внимание, что вес файла не должен превышать 2 МБ, соотношение сторон 16:9.</li>" +
              "<li>Выбрать логотип. Перетащите или загрузите файл с изображением в окно Вставьте логотип вашей Wallet-карты. Обратите внимание, что вес файла не должен превышать 2 МБ, соотношение сторон 1:1.</li>" +
              "<li>Выберите цвет QR-кода, заголовка и номера карты.</li>" +
              "</ol>" +
              "Чтобы сохранить настройки, нажмите <b>«Изменить дизайн»</b>, а для сброса параметров — <b>«Вернуть стандартный дизайн»</b>.",
            video: ["//video.platformcraft.ru/embed/61f796e7ef3db553eb6a2313"],
          },
        },
      },

      employeesAndManagers: {
        title: "Мои сотрудники",

        subsections: {
          managingManagers: {
            title: "Менеджеры",
            description:
              "В разделе <b>«Менеджеры»</b> отображены все менеджеры, которых зарегистрировал владелец ТСП:" +
              "<ul class='doted_list'>" +
              "<li>Ф.И.О сотрудника;</li>" +
              "<li>должность;</li>" +
              "<li>номер телефона;</li>" +
              "<li>логин.</li>" +
              "</ul>" +
              "Также можно добавить, изменить, заблокировать и удалить менеджера. Для того, чтобы добавить менеджера нужно нажать плюс, указать фамилию, имя, отчество, должность, номер телефона и настроить доступ к страницам личного кабинета. Логин и пароль для входа будут сформированы программой автоматически. Помните, что пароль, после добавления менеджера, показывается единожды. Рекомендуем его сохранить в удобном для Вас месте, так как он не будет отображен в программе." +
              "<br><br>" +
              "Обратите внимание, что менеджер не имеет доступа к проведению транзакций в приложении RC.Kassa.",
            video: ["//video.platformcraft.ru/embed/61f796ad0e47cf171e558b08"],
          },
          managingEmployee: {
            title: "Кассиры",
            description:
              "В разделе <b>«Кассиры»</b> представлены данные по всем созданным сотрудникам. Для создания сотрудника необходимо нажать плюс и ввести фамилию, имя, а также выбрать торговую точку сотрудника. В таблице вы найдете логин, пароль, которые необходимы для входа в RC.Kassa, и статус сотрудника." +
              "<br><br>" +
              "Кроме того, в этой вкладке можно посмотреть информацию по набранным баллам, заблокировать, либо удалить сотрудника.",
            video: ["//video.platformcraft.ru/embed/61f796c10e47cf171e558b09"],
          },
          addPoints: {
            title: "Очки кассирам",
            description:
              "В разделе <b>«Очки кассирам»</b> можно настроить количество выдаваемых сотруднику очков за проведение операции и проведение операции с новым клиентом.\n",
            video: ["//video.platformcraft.ru/embed/61f796ccef3db553eb6a2311"],
          },
        },
      },

      enterprises: {
        title: "Мои предприятия",

        subsections: {
          organization: {
            title: "Юр лица",
            description:
              "В подразделе <b>«Юр лица»</b> Вы можете добавить имеющуюся у Вас организацию, чтобы впоследствии на ее заведения распространялась программа лояльности. Для этого нажмите знак плюса напротив «Юр лица» и заполните все имеющиеся поля: " +
              "<ul class='doted_list'>" +
              "<li>ИНН организации;</li>" +
              "<li>юридическое наименование;</li>" +
              "<li>юридический адрес;</li>" +
              "<li>банк, обслуживающий организацию; </li>" +
              "<li>БИК;</li>" +
              "<li>корреспондентский счет;</li>" +
              "<li>расчетный счет;</li>" +
              "<li>Ф.И.О руководителя.</li>" +
              "</ul>" +
              "Обратите внимание особое внимание на коррекспондентский и расчетный счета. В них должно быть одинаковое колличество цифр и отсутствовать лишние знаки (пробелы и точки). Также при выборе типа организации «Халяль», необходимо загрузить подтверждающий документ в формате pdf, jpeg или png. " +
              "<br><br>" +
              "Кроме того, возможность добавить вторую и последующие организации присутствует только на платных тарифах. Если у Вас несколько организаций, то Вы можете выбрать, какая из них станет основной и будет действовать даже в случае понижения тарифа. После создании организации Вы сможете отслеживать статус проверки внесенной вами информации со стороны модерации:" +
              "<ul class='doted_list'>" +
              "<li>«Подтверждено», если все данные заполнены верно и проверка пройдена успешно;</li>" +
              "<li>«Отклонено», если информация заполнена неверно или требует уточнения. Пожалуйста, обращайте внимание на комментарии модератора, они подскажут Вам, какую информацию нужно скорректировать;</li>" +
              "<li>«На модерации», если организация ожидает проверки или уже проверяется модератором.</li>" +
              "</ul>" +
              "Если модерация будет отклонена, то в поле «Ответ модерации» появится поясняющее причину сообщение. Внимательно изучите его и выполните рекомендации модератора." +
              "<br><br>" +
              "Также Вы всегда можете посмотреть информацию о выбранной организации, нажав на кнопку «Подробнее» напротив наименования:" +
              "<ul class='doted_list'>" +
              "<li>наименование организации;</li>" +
              "<li>ИНН;</li>" +
              "<li>юридический адрес;</li>" +
              "<li>БИК банка;</li>" +
              "<li>расчетный счет;</li>" +
              "<li>корреспондентский счет;</li>" +
              "<li>сумма депозита.</li>" +
              "</ul>" +
              "На этой странице Вы сможете поменять данные и задать сумму депозита, нажав на кнопку «Изменить реквизиты организации»." +
              "<br><br>" +
              "Сумма депозита — это размер минимального остатка на счете, т.е. при превышении выбранного вами значения со стороны RC Group будет осуществлена выплата вашей компании, а при недостижении — оплата не происходит.",
            video: ["//video.platformcraft.ru/embed/61f795f9ef3db553eb6a2303"],
          },
          shops: {
            title: "Торговые точки",
            description:
              "В разделе <b>«Торговые точки»</b> Вы найдете список всех зарегистрированных точек, историю чеков. Также здесь есть возможность при необходимости удалить точку ." +
              "<br><br>" +
              "Для добавления новой торговой точки перейдите на вкладку <b>«Торговые точки»</b> и нажмите кнопку <b>«Создать»</b> в правом верхнем углу. Затем заполните все обязательные поля. Для Вашего удобства рядом с полями есть подсказки. Постарайтесь максимально подробно и точно описать деятельность Вашей торговой точки и подчеркнуть ее уникальность с помощью описания и фотографий. " +
              "<br><br>" +
              "Важно указать юрлицо, с которым будут производиться взаиморасчеты. Для этого выберите соответствующее юридическое лицо из выпадающего списка." +
              "<br>" +
              "Заполненная информация будет отправлена на модерацию." +
              "<br>" +
              "Вы сможете отслеживать статус проверки после того, как данные торговой точки будут отправлены на модерацию:" +
              "<ul class='doted_list'>" +
              "<li><i>«Подтверждено»</i>, если все данные заполнены корректно и проверка пройдена успешно. В этом случае Ваша точка будет размещена на сайте rcpay и в приложении RC PAY.</li>" +
              "<li><i>«Отклонено»</i>, если недостаточно информации или требуются уточнения. Вы увидите пояснительное сообщение от модератора с рекомендациями по исправлению.</li>" +
              "<li><i>«На модерации»</i>, если точка ожидает проверки или уже проверяется модератором.\n</li>" +
              "</ul>" +
              "<br><br>" +
              "<b>«История чеков»</b><br>" +
              "Чтобы просмотреть историю чеков, нажмите на кнопку <b>«История чеков»</b> в строке соответствующей торговой точки. Вы можете отфильтровать и отсортировать таблицы или просмотреть конкретную транзакцию более детально, нажав кнопку <b>«Подробнее»</b>.",
            video: ["//video.platformcraft.ru/embed/658b5ee80e47cf2ffec3675e"],
          },
          // paymentMethods: {
          //   title: "Способы оплаты",
          //   description:
          //     "Теперь Вы можете подключить электронный тип оплаты (банковская карта, СБП, Yandex Pay и др.) <br>" +
          //     "На странице <b>«Способы оплаты»</b> выберите юрлицо и перейдите на вкладку <b>«Документы»</b>. В этом разделе Вы найдете документы для подписания: <b>«Заявление о присоединении»</b>, <b>«Ставки банковского эквайринга»</b>, а также <b>«Инструкцию по активации ключей»</b>. В разделе <b>«Статус»</b> Вы можете отследить процесс подключения." +
          //     "<br><br>" +
          //     "После того как документы будут подписаны и пройдут модерацию, Вы увидите сообщение модератора о ставке, которая вам одобрена. <br>" +
          //     "Далее ожидайте ключи от нашего партнера «Рокет Ворк» на электронную почту, которую Вы указали при регистрации. <br>" +
          //     "С помощью <b>«Инструкции по активации ключей»</b> Вы сможете самостоятельно активировать кассу и начать принимать платежи в этот же день.",
          //   video: ["//video.platformcraft.ru/embed/658b5e9a0e47cf2ffec3675d"],
          // },
          documents: {
            title: "Документы",
            description:
              "В разделе <b>«Документы»</b> на вкладках «Документы» и «Маркетинговые материалы» Вы сможете ознакомиться с инструкциями и материалами, относящимся к продукции RC GROUP.",
            video: ["//video.platformcraft.ru/embed/61f79688ef3db553eb6a230f"],
          },
          actsAndAccounts: {
            title: "Акты и счета",
            description:
              "В подразделе <b>«Акты и счета»</b> можно просмотреть счета и акты по вашим организациям, выбрав нужную. На вкладке «Счета» можно ознакомиться с наименованием, суммой, датой и временем, статусом платежа, также скачать нужный Вам счет в любое время. На вкладке «Акты» представлены все выставленные акты, а также есть возможность сформировать по ним счет, скачать или просмотреть детальную информацию. Кроме того, в разделе «Акты» Вы можете указать период, за который Вы бы хотели сформировать акт." +
              "<br><br>" +
              "Для удобства клиентов с 01.04.2022 в отображающуюся информацию в актах сверки внесены изменения. В старой версии документа (сформированной до 31.03.2022) отражена информация только в баллах, а в новой версии (с 01.04.2022) — информация о фактическом пополнении и списании средств с баланса в RC CITY. Чтобы скачать документы, достаточно выбрать необходимый вам вид документа и нажать кнопку «Сформировать».",
            video: ["//video.platformcraft.ru/embed/61f796680e47cf171e558af9"],
          },
          tariffs: {
            title: "Тарифы",
            description:
              "В разделе <b>«Тарифы»</b> показаны все имеющиеся тарифы. Чтобы приобрести тариф, выберите интересующее Вас предложение и нажмите «Купить тариф», выберите «Юрлицо» и нажмите кнопку «Оплатить». <br>" +
              "Для оплаты тарифа вы должны пополнить баланс, для этого сформируйте счет в разделе «Баланс» на сумму стоимости тарифа и произведите оплату с реквизитов, принадлежащих выбранной организации.",
            video: ["//video.platformcraft.ru/embed/658b5e43ef3db51ca61a4ee3"],
          },
        },
      },

      services: {
        title: "Сервисы",

        subsections: {
          checkingCounterparties: {
            title: "Проверка контрагентов",
            description:
              "В подразделе <b>«Проверка контрагентов»</b> Вы можете с помощью бота проверить контрагентов. Это необходимо для оперативного выявления финансовых и налоговых рисков, которые могут возникнуть при работе с контрагентом, и принятия обоснованных решений: продолжить или прекратить сотрудничество." +
              "<br><br>" +
              "Бот автоматически проверяет новых и текущих контрагентов по разным источникам и сообщает вам о критических факторах и сведениях, требующих внимания." +
              "<ol class='number_list'>" +
              "<li>Статус юридического лица: «действующая», «в процессе ликвидации», «ликвидирована».</li>" +
              "<li>Руководители.</li>" +
              "<li>Уставной капитал.</li>" +
              "<li>Состояние счетов (если они заблокированы, то вряд ли контрагент сможет расплатиться).</li>" +
              "</ol>" +
              "Кроме того, с помощью бота вы можете проводить массовую проверку и мониторинг изменений по всей базе контрагентов или по конкретно выделенной. Это позволяет вовремя реагировать на существенные изменения у клиентов и партнеров, снижая риск невозврата денежных средств.",
            video: ["//video.platformcraft.ru/embed/63aec1bb0e47cf7b45610151"],
          },
          eMenu: {
            title: "Электронное меню",
            description:
              "На странице <b>«Электронное меню»</b> Вы можете добавить свои товары или услуги, чтобы клиенты могли ознакомиться с ассортиментом Вашего заведения или магазина на сайте rcpay или в приложении RC PAY." +
              "<br>" +
              "Также внизу страницы Вы можете сформировать и скачать QR-код, который позволит перейти к меню Вашей торговой точки в несколько кликов.",
            video: ["//video.platformcraft.ru/embed/658aedae0e47cf2ffec366af"],
          },
        },
      },

      contacts: {
        title: "Контакты",

        subsections: {
          contacts: {
            title: "Способы связи",
            description:
              "По всем интересующим вопросам обращайтесь по телефону <a href='tel:88005005762' class='phone'>8 (800) 500-57-62</a> или по электронной почте  <a href='mailto:hello@rc.city' class='email'>hello@rc.city</a>. Либо напишите свой вопрос в форму обратной связи, которая расположена в правом нижнем углу, и мы свяжемся с Вами удобным для Вас способом.",
            // video: ["//video.platformcraft.ru/embed/658aecadef3db51ca61a4e4a"],
          },
        },
      },
    },
  },

  // Corporate Communication Page
  orporate: {
    title: "Comunicare corporativă",
    view: "Vizualizare",
    createClaim: "Creare cerere",
    suitableFor: "Pentru ce va fi de folos?",
    suitableParagraph:
      "Oferta unică pe piața de servicii mobile - raportul calitate-preț, precum și caracteristicile exclusive disting clienții RC Mobile de utilizatorii altor operatori de telefonie mobilă",
    suitable: "Se potrivește",
    forTraveling: "Pentru călătorii",
    forCorporateTasks: "Pentru sarcini corporative",
    forBusinessTrips: "Pentru călătorii de serviciu",
    benefits: "Care sunt avantajele?",
    easyConnection: "Conectare simplă",
    easyConnectionDescription:
      "Detectarea automată a operatorului de telefonie mobilă optimă permite utilizatorilor să plătească la prețurile operatorilor locali de telecomunicații unde operează RC Mobile",
    favorableRates: "Tarife avantajoase",
    favorableRatesDescription:
      "După plata tarifului, indiferent de regiunea de reședință a proprietarului cartelei SIM, acesta poate utiliza tariful fără costuri suplimentare fără a schimba operatorul de telefonie mobilă",
    technicalSupport: "Suport tehnic",
    technicalSupportDescription:
      "Asistență promptă și comodă a departamentului de deservire RC consultă utilizatorii RC Mobile cu privire la toate problemele emergente direct din aplicație în secțiunea „Suport”",
    ourTariffs: "Tarifele noastre",
    modernApplication: "Aplicație modernă",
    countSimCard: "Cantitatea de cartele sim",
    purposesPurchaseSIMCards: "Care este scopul achiziționării cartelei sim",
    thanksForClaim:
      "Vă mulțumim pentru solicitarea dvs. <br> În scurt timp, managerul nostru vă va contacta pentru a clarifica detaliile.",
    forUseAbroad: "Pentru utilizarea peste hotare",
    foUseYourCountry: "Pentru utilizarea în țara sa",
    newGenerationMobileCommunication: "Comunicare mobilă de nouă generație!",
    joinUs:
      "Alăturăți-vă nouă, descărcând aplicația de la partenerul nostru tehnologic Glonass",
    countSim: "Cantitatea de cartele sim",
    enterFullName: "Introduceți NPP",
    enterEmail: "Introduceți emailul",
    contactPhone: "Telefonul de contact",
    enterPhone: "Introduceți telefonul",
    writeCountOfSIM:
      "Indicați câte cartele SIM doriți să comandați pentru utilizare corporativă",
    different: "Altele",
    comment: "Comentarii",
    commentDescription: "Introduceți scopul achiziției",
  },

  // Answer moderation
  status: {
    moderation: "La moderare",
    confirmation: "Confirmat",
    reject: "Respins",
    paid: "Achitat",
    active: "Activ",
    locked: "Blocat",
    activateProcess: "În proces de activare",
    awaitingPayment: "Așteaptă plata",
    sent: "Expediat",
    received: "Recepționat ",
    canceled: "Anulat",
    unknown: "Неизвестно",
    confirm: "Подтвержден",
    base: "Базовая",
  },
  // Table
  table: {
    status: "Statut",
    nameStatus: "Denumirea statutului",
    percentageAccrual: "Procentul de calcul",
    fullName: "NPP",
    position: "Funcția",
    phone: "Numărul de telefon",
    login: "Login",
    number: "Număr",
    sum: "Sumă",
    customer: "Cumpărător",
    cashback: "Cashback",
    currency: "Valuta",
    dateTime: "Data și ora",
    date: "Data",
    paid: "Achitat",
    purchase: "De la achiziție la",
    transaction: "Tranzacție",
    type: "Tipologie",
    points: "Puncte",
    name: "Prenume",
    visits: "Frecvența vizitelor",
    averageCheck: "Suma achizițiilor",
    birthday: "Data nașterii",
    age: "Vârstă",
    nameAndSurname: "Nume și prenume",
    store: "Punct de vânzare",
    entityName: "Denumirea organizației juridice",
    operationType: "Tipul operațiunii",
  },
  // Common text
  common: {
    organizationSelect: "Alegeți organizația",
    allOrganizationCheckbox: "",
    tradingPointsSelect: "Adăugarea punctului de vânzare",
    allTradingPointsCheckbox: "Toate punctele de vânzare",
    from: "de la",
    util: "până",
    to: "în zilele de",
    fillField: "Completați acest câmp",
    invalidEmail: "Email greșit",
    minLengthPass: "Parola nu mai puțin de 8 simboluri",
    fillTime: "Indicați timpul",
    calendar: "Calendar",
    filter: "Filtru",
    back: "Înapoi",
    loadDescription: "Vă rugăm să descărcați certificatul halal al produsului:",
    loadFormat: "Sunt acceptate certificatele în format .PDF .JPG .PNG",
    loadSize: "Greutatea fișierului nu poate fi mai mare de 10 MB",
    dropFiles: "Trage fișierul aici sau",
    uploadFiles: "încărcați",
    chooseFile: "Alegeți fișier",
    fileName: "Numele fișierului",
    Monday: "Luni ",
    Tuesday: "Marți",
    Wednesday: "Miercuri",
    Thursday: "Joi",
    Friday: "Vineri",
    Saturday: "Sâmbătă",
    Sunday: "Duminică",
    1: "Luni",
    2: "Marți",
    3: "Miercuri",
    4: "Joi",
    5: "Vineri",
    6: "Sâmbătă",
    7: "Duminică",
    dayAndNightTime: "Punctul lucrează 24/24",
    notWorkTime: "Punctul nu lucrează",
    month: "luna",
    mo: "lună",
    year: "an",
    organizations: "Organizația",
    shops: "Puncte de vânzare",
    employees: "Colaboratori",
    managers: "Manageri",
    manager: "Manager",
    owner: "Proprietar",
    business: "Afacere",
    tariffs: "Tarife",
    historyOperations: "Istoricul operațiunilor",
    actsAndAccounts: "Acte și facturi",
    cashback: "Calcularea punctelor",
    documents: "Documente",
    addBalance: "Plată anticipată",
    addPoints: "Calcularea punctelor",
    application: "Achitarea aparatului de casă",
    claims: "Cereri",
    analytics: "Analitică",
    sortNew: "După noutate",
    sortAscStars: "În funcție de descreșterea stelelor",
    sortDscStars: "În funcție de creșterea stelelor",
    email: "E-mail",
    lastName: "Nume",
    firstName: "Prenume",
    error404: "Eroarea 404",
    noPage: "Pagina nu a fost găsită",
    errorTypeFile: "Tip greșit de fișier",
    tryTypeFile: "Fișierul trebuie să fie jpg sau png",
    clients: "Clienți",
    exitAccount: "Ieșire din cont",
    accountsForAct: "Factură în baza contului",
    downloadOn: "disponibil in",
    downloadOnRuStore: "Descărcați din",
    phone: "Telefon",
    comment: "Comentarii",
    pcs: "buc.",
    gb: "GB",
    min: "min",
    group: "Группа",
    emptyField: "Заполните все поля",
    correctField: "Все поля должны быть корректны!",
    bigFile: "Большой размер файла",
    "e-menu": "Электронное меню",
  },
  // Buttons
  button: {
    invite: "Пригласить",
    download: "Descărcare",
    edit: "Editare",
    save: "Salvare",
    saveChange: "Salvează modificările",
    accept: "Acceptă",
    change: "Modifică",
    login: "Intră",
    create: "Creare",
    confirm: "Confirmă",
    details: "Detalii",
    copy: "Copiere",
    topUp: "Suplinire",
    back: "Înapoi",
    send: "Trimite",
    sendAgain: "Trimite repetat",
    sendCode: "Trimite cod",
    next: "Mai departe",
    proceed: "Continuare",
    buy: "Cumpără",
    goto: "Treci",
    open: "Deschide",
    delete: "Șterge",
    cancel: "Anulare",
    pay: "Achitare",
    ok: "Bine!",
    cancelClaim: "Anulează comanda",
    generate: "Сгенерировать",
    apply: "Применить",
    reset: "Сбросить",
    clearAll: "Очистить все",
    calendarButton: {
      today: "Сегодня",
      yesterday: "Вчера",
      lastWeek: "Последние 7 дней",
      last30Days: "Последние 30 дней",
      lastMonth: "Прошлый месяц",
      thisMonth: "Этот месяц",
      lastYear: "Последний год",
      thisYear: "Текущий год",
      allPeriod: "Весь период",
      selectRange: "Свой период",
    },
    preview: "Предпросмотр",
    resetAll: "Сбросить все",
    showResult: "Показать результаты",
    upload: "Загрузить",
    expand: "Развернуть",
    hide: "Скрыть",
    try: "Попробовать",
    add: "Добавить",
    reveal: "Показать",
    readAll: "Читать полностью",
    collapse: "Свернуть",
    logout: "Выйти",
    show: "Показать",
    moreDetails: "Подробнее",
    settings: "Настройки",
  },
  month: {
    1: "Ianuarie",
    2: "Februarie",
    3: "Martie",
    4: "Aprilie",
    5: "Mai",
    6: "Iunie",
    7: "Iulie",
    8: "August",
    9: "Septembrie",
    10: "Octombrie",
    11: "Noiembrie",
    12: "Decembrie",
  },
  charts: {
    pointsAccrued: "Puncte calculate",
    pointsWritten: "Radiere puncte",
  },

  tips: {
    instruction: {
      title: "Инструкция для работы чаевых",
      tipsTitle: "Безналичные чаевые",
      tipsDescription:
        "Помощь ресторанному бизнесу и салонам красоты <br> 80% посетителей заведений оплачивают товары и услуги картами, а не наличными средствами. Это приводит к падению мотивации сотрудников и качества обслуживания: ведь персонал перестает получать чаевые. Безналичные чаевые — сервис, позволяющий мастерам и официантам получать чаевые от клиентов. Бизнес ничего не теряет — все комиссии лежат на мастерах и клиентах, к тому же чаевые не облагаются налогом.",
      howTitle: "Как это работает?",
      howDescription:
        "Персонал регистрируется в системе сервиса <br> Каждый зарегистрированный участник получает QR-код <br> QR-код можно разместить на чеке, визитке или любой другой печатной продукции <br> Клиент переводит чаевые по QR-коду <br> Персонал получает деньги <br> Успех! <br> Клиент также может взять на себя комиссию: тогда сотрудник получит полную сумму чаевых.",
    },
    presentation: "Презентация",
    forRestaurants: "Для ресторанов",
    forShops: "Для салонов",
    arrange: "Оформить заявку",
  },

  eMenu: {
    title: "Электронное меню",
    hint: '<p>С подробной информацией о работе функционала «Электронное меню» Вы можете ознакомиться в разделе Мои предприятия -  <a href={link} target="_blank">Документы</a> - Маркетинговые материалы - Инструкция по использованию RC CITY</p>',
    searchPlaceholder: "Поиск по товарам",
    productsStatuses: {
      all: "Все товары",
      moderation: "Модерация",
      reject: "Отклоненные",
    },
    categoriesStatuses: {
      all: "Все категории",
      moderation: "Модерация",
      reject: "Отклоненные",
    },
    noData: {
      title: "Данных по электронному меню нет",
      noTradingPoints: "У вас пока нет Торговых точек ",
      noProducts: "У вас пока не создано ни одного товара",
    },
    category: "Категория",

    table: {
      productName: "Название товара",
      category: "Категория",
      price: "Цена",
      status: "Статус",
      noCategory: "Без категории",
    },

    qr: "QR - код",

    QRModalTitle: "QR-код на меню",
    QRModalSubTitle:
      "Выберите торговую точку, QR-код на меню которой хотите скачать",

    productCard: {
      createTitle: "Создание карточки товара",
      editTitle: "Информация о товаре",
      images: "Изображения",
      productName: "Название товара",
      price: "Цена, {currency}",
      discount_price: "Цена со скидкой, {currency}",
      category: "Категория",
      description: "Описание",
      descriptionMax: "Макс. 1000 символов",
      image: {
        cover: "Обложка",
        coverTitle: "Загрузить обложку",
        cropPhoto: "Обрежьте фото",
        imageTitle: "Загрузить изображение",
        tooltip:
          "Добавьте обложку и изображения к товару. Максимальное количество изображений — 5.",
      },
      moderationMessageTitle: "Ответ модерации",
      dimensions: "Единицы измерения",
      dimensionsPlaceholder: "Выберите единицу измерения",
      noDimension: "Без единиц измерения",
    },

    pointsListTitle: "Отображение в торговой точке",

    deleteProduct: "Вы уверены, что хотите удалить “{label}”?",
    hideProduct: "Вы уверены, что хотите скрыть “{label}”?",
    revealProduct: "Вы уверены, что хотите показать “{label}”?",
    categoryPlaceholder: "Выберите категорию",

    limits: {
      lite: "Вы создали 20 товаров, ваш лимит исчерпан. <br> Чтобы добавить больше позиций, повысьте тариф до Promo.",
      promo:
        "Вы создали 150 товаров, ваш лимит исчерпан. <br> Для добавления товаров без ограничений повысьте тариф до Pro+",
      tariffs: "Тарифы",
    },
  },

  validationErrors: {
    emptyField: "Это поле не может быть пустым",
    invalidEmailFormat: "Введите email вида {email}",
    invalidSiteFormat: "URL должен содержать http:// или https://",
    invalidFormat: "Неверный формат",
    incorrectValueStart: "Необходимо указать значение от",
    incorrectValueEnd: "до",
    invalidValueMaxLength: "Максимальное количество символов ",
    invalidValueMinLength: "Минимальное количество символов ",
    invalidHouseNumber: "Уточните номер дома",
    invalidAddress: "Уточните адрес",
    invalidWorkDays: "Необходимо выбрать хотя бы один рабочий день",
    invalidWorkTime: "Неверный формат времени",
    errorWorkTime:
      "Время открытия не может быть позже времени закрытия или совпадать с ним",
    notEnoughElement: "Недостаточное количество элементов",
    manyElement: "Вы превысили максимальное количество элементов",
    maxLessMin: "Меньше минимального",
    invalidAge: "Некоректный возраст",
    minDate: "Дата должна быть не позднее {date}",
    maxDate: "Дата должна быть не ранее {date}",
    requiredPhone: "Введите номер телефона",
    reqiredPromocode: "Промокод введен неверно",
    requiredCountry: "Необходимо выбрать страну",
    havePhone: "Этот телефон уже зарегистрирован",
    haveMail: "Данная почта уже зарегистрирована",
    invalidEmail: "Некорректная почта",
    email: "Введите почту",
    first_name: "Введите имя",
    last_name: "Введите фамилию",
    password: "Введите пароль",
    corresp_account: "Необходимо указать корсчет",
    number_account: "Необходимо указать номер расчетного счета",
    bik_of_bank: "Необходимо указать БИК банка",
    international_bank:
      "Необходимо указать международный номер банковского счета",
    vat_code: "Необходимо указать код НДС",
    inn: "Необходимо указать инн",
    entityAddress: "Необходимо указать юридический адрес",
    fullName: "Необходимо указать ФИО",
    ogrn: "Необходимо указать ОГРН",
    monthly_turnover: "Необходимо указать размер ежемесячного оборота",
    deposit_sum: "Необходимо указать размер депозита",
    tax_system: "Необходимо указать тип налогообложения",
    legal_type: "Необходимо указать форму юрлица",
    okved_code: "Необходимо указать основной вид деятельности",
    minFillingPhotos: "Минимальное количество файлов - {amount}",
    notValidPromocode: "Промокод не существует",
    cyrillicOnly: "Только кириллица и латиница",
    haveInn: "Юрлицо с этим ИНН уже существует",
    addressWithoutNumber:
      "Необходимо указать номер здания/сооружения, помещения",
    login: "Введите логин",
  },

  //  marketplaces Page
  marketplaces: {
    title: "Маркетплейсы",
    inn: "ИНН",
    phone: "Номер телефона",
    link: "Ссылка на сайт (не обязательно)",
    error: "Поля заполнены неверно",
  },

  promotions: {
    title: "Акции",
    createNewPromo: "Создать новую акцию",
    headersList: {
      name: "Название акции",
      tradingPoint: "Торговая точка",
      cashback: "Процент начисления",
      discount: "Процент скидки",
    },
    name: "Название акции",
    cashback: "Процент начисления",
    discount: "Процент скидки",
    createTitle: "Создание новой акции",
    aboutPageModal: {
      title: "Функционал подраздела “Акции”",
      body:
        'Подраздел "Акции" помогает проводить товары с разным значением скидки и разным значением начисления' +
        "вознаграждения. Для того чтобы создать акцию, нажмите соответствующую кнопку в подразделе “Акции“, затем," +
        " в всплывающем окне укажите торговую точку, название акции, процент начисления и процент скидки.\n" +
        "После успешного создания акции, зайдите как сотрудник в RC Кассу и нажмите на иконку всплывающего " +
        "меню в правом верхнем углу смартфона. Нажав на кнопку “Акции“, выберите созданную в кабинете акцию и " +
        "проведите стандартную операцию списания или начисления баллов.",
    },
    delete: "Вы уверены, что хотите удалить ",
    editTitle: "Редактор акций",
    deleteTitle: "Удалить акцию",
    activate: "Активировать акцию",
    deactivate: "Деактивировать акцию",
    noPromotions: "У вас еще нет акций",
    createWarning: "*До трёх активированных акций на одну торговую точку",
  },

  tooltips: {
    tradingPoints: {
      name: "Данное название будет отображаться у пользователей в клиентском сервисе.",
      tags: "Будут использоваться для поиска Вашего заведения в клиентском сервисе. Правила написания: начало с букв русского/латинского алфавита, цифр,  до 50 символов, возможны с нижним подчеркиванием. Максимальное количество тегов - 20 шт.",
      description: "Будет отображаться у пользователей в клиентском сервисе.",
      businessType:
        "Служит для фильтрации и поиска Вашего заведения в клиентском сервисе.",
      isBonusPayable:
        "Укажите процент скидки, который будет предоставляться пользователям экосистемы RC Group, при покупке любого товара.",
      cashbackPercent: "Укажите общий процент вознаграждения. ",
      addressComment:
        "Вы можете указать уточнения к адресу, а именно: как пройти, предварительно позвонить и другую сопутствующую информацию.",
      links:
        'В "Название ссылки" укажите как бы Вы хотели, чтоб отображалась ссылка в клиентском сервисе. Например, https://vk.com/id123456  -  petrivanov.',
      logo: "Изображение с фирменным знаком, которое будет использоваться в качестве логотипа вашей торговой точки.",
      filling:
        "Должны быть в максимально хорошем качестве, не содержать надписи, коллажи и водяные знаки",
      banner:
        "Обложка вашего заведения в каталоге торговых точек клиентского сервиса. Не должна быть логотипом, коллажом, содержать надписи и водяные знаки",
      activeWait:
        "Количество дней, через которые будет начислено вознаграждение",
    },
  },

  globalMessagesButton: {
    newTradingPointPage: "Перейти в новый подраздел “Торговые точки”",
    "clients-update": "Попробовать",
    "product-release": "Попробовать",
    "review-update-release": "Попробовать",
  },

  declinationHelperWords: {
    years: [" лет", " год", " года"],
    days: [" дней", " день", " дня"],
    grades: [" оценок", " оценка", " оценки"],
    symbols: [" символов", " символа", " символов"],
    result: [" рузальтатов", " результат", " результата"],
    review: [" отзывов", " отзыв", " отзыва"],
    star: [" звезд", " звезда", " звезды"],
    clients: [" клиентов", " клиент", " клиента"],
    product: [" товаров", " товар", " товара"],
  },

  noData: {
    title: "Данных нет",
    emptyHistory: "Ваша история пуста",
  },

  mobileFiltersName: {
    "trading-points": "Торговые точки",
    category: "Категория",
    "product-status": "Статус товара",
    "review-status": "Тип отзыва",
    sort: "Сортировка",
    date: "Период",
  },

  debt: {
    title: "Задолженность",
    text:
      "Оплатите ее с помощью счета, отправленного на электронную почту, или создайте его самостоятельно\n" +
      "в разделе “Баланс”",
    link: "Перейти к погашению",
    message:
      "Вам необходимо оплатить задолженность в размере {debt}&nbsp;{currency} <br>" +
      "Оплату можно произвести одним из способов: создать счет в личном кабинете или скачать счет, отправленный на электронную почту.",
  },

  paymentMethod: {
    title: "Способы оплаты",
    hint: "Быстрое и простое увеличение вариантов оплаты в вашей торговой точке с помощью инновационного платежного решения для бизнеса RC POS",
    nodataTitle: "Данных по способам оплаты нет",
    nodataDescription: "У вас пока нет торговых точек ",
    noModerationDataDescription:
      "У вас пока нет торговых точек, прошедших модерацию",
    haveAccountTitle: "Ваша почта уже зарегистрирована в RC POS",
    haveAccountText:
      "Если у вас ранее не были подключены способы оплаты, перейдите в свой кабинет RC POS",

    create: {
      firstStep: {
        asideTitle: "Несколько шагов и инновационное решение у вас в кармане!",
        asideText:
          "<p>Эта разработка позволяет не использовать дополнительное оборудование — нет необходимости приобретать кассу, терминал \n" +
          "и фискальный накопитель, а чековую ленту успешно заменили электронные чеки. </p>" +
          "<p>Подключение дополнительных способов оплаты\n" +
          "доступно на одну торговую точку.</p>" +
          "<p>Требования законодательства и партнерских договоров не позволяют нам обслуживать некоторые категории бизнеса:</p>",

        asideText_open:
          "<ul>" +
          "<li>— продающие алкоголь любого вида;</li>" +
          "<li>— продающие табачные изделия, наркосодержащие препараты, курительные смеси и их аналоги, включая электронные сигареты и их наполнители;</li>" +
          "<li>— продающие лекарственные средства, включая БАДы;</li>" +
          "<li>— продающие товары и контент сексуальной направленности («товары для взрослых», секс-шопы и т.п.);</li>" +
          "<li>— продающие б/у запчасти для автомобилей;</li>" +
          "<li>— продающие огнестрельное и холодное оружие, запасные части и боеприпасы к нему, а также любые товары и услуги военного назначения;</li>" +
          "<li>— организующие куплю-продажу валюты, включая криптовалюты, ценных бумаг, финансовых инструментов и их аналогов;</li>" +
          "<li>— связанные с азартными играми;</li>" +
          "<li>— букмекерские конторы;</li>" +
          "<li>— продающие товары или оказывающие услуги, нарушающие действующее законодательство или способные ввести в заблуждение или обмануть покупателя.</li>" +
          "</ul>" +
          '<p>С полным <a target="_blank" href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/ограничения.pdf">списком ограничений</a> можно ознакомиться в документе ограничений </p>',
        asideSubText:
          "На этом этапе происходит создание кабинета в RS POS. <br> Логин и пароль для входа в RC POS будут отправлены на указанную при регистрации почту.",
        //статичные данные
        title: "Юридическое лицо",
        tradingPoint: "Торговая точка",
        inn: "ИНН:",
        bic: "Бик банка:",
        headName: "Ф.И.О. руководителя:",
        bankName: "Название банка:",
        organizationType: "Тип организации:",
        currentAccountNumber: "Номер расчетного счета",
        address: "Юридический адрес:",
        correspondentAccount: "Корсчет:",
        // изменяемые даннык
        entityType: "Форма юрлица",
        taxType: "Тип налогообложения",
        monthlyTurnover: "Размер ежемесячного оборота",
        ogrn: "ОГРН",
        okved: "Основной вид деятельности",
        phone: "Телефон",
        oferta:
          'Я принимаю условия <a href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/Договор%20о%20присоединении%20к%20RC%20POS.pdf">договора</a> сервиса RC POS',
      },
      secondStep: {
        title1: "Выберите подключаемые способы оплаты",
        online: "Онлайн",
        sbp: "СБП",
        // блок 2
        title2: "Личные данные",
        passportSeries: "Серия паспорта",
        passportNumber: "Номер паспорта",
        issuedPlace: "Кем выдан",
        dateOfIssue: "Дата выдачи",
        departmentCode: "Код подразделения",
        dateOfBirth: "Дата рождения",
        placeOfBirth: "Место рождения",
        // блок 3
        title3: "Данные о компании",
        headPosition: "Должность руководителя",
        VATPayer: "Плательщик НДС",
        procents: "Процентная ставка",
        procentsHint:
          "Будет внесена автоматически после прохождения проверки данных",
        preferredCommunicationChanel: "Канал для связи",
        factAddress: "Фактический адрес",
        processSellingGoodsAndServices: "Процесс реализации товаров/услуг",
        processSellingGoodsAndServicesHint:
          "Как это происходит и где, если у вас нет торговой точки/офиса",
        descriptionOfActivity: "Описание деятельности",
        descriptionOfActivityHint: "За что будет приниматься оплата",
        note: "Примечание",
      },

      thirdStep: {
        title: "Подписание документа",
      },
    },

    card: {
      tabs: {
        entities: "Юрлицо",
        "payment-methods": "Способы оплаты",
        documents: "Документы",
      },
    },
  },

  registration: {
    loyalty:
      'Я принимаю условия <a target="_blank" href="{link}">программы лояльности</a> партнеров RC Group',
    posOferta:
      'Я принимаю условия <a target="_blank" href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/Договор%20о%20присоединении%20к%20RC%20POS.pdf">договора</a> сервиса RC POS',
    1: "Основная информация",
    2: "Персональная информация",
    3: "Юридическое лицо",
    haveAcc: "Есть аккаунт?",
    login: "Войти",

    firstStep: {
      title: "Основная информация",
      promocode: "Cod promoțional",
      country: "Țară",
      phone: "Номер телефона",
      checkbox:
        " Даю свое согласие на обработку персональных данных в соответствии\n" +
        "          с Политикой конфиденциальности",
    },
  },

  permissions: {
    analytics: "Аналитика",
    "legal-entity": "Юрлица",
    "trading-point": "Торговые точки",
    employee: "Кассиры",
    document: "Документы",
    tariff: "Тарифы",
    operation: "История операций",
    "act-and-receipt": "Акты и счета",
    manager: "Менеджеры",
    cashback: "Начисление баллов",
    balance: "Баланс",
    points: "Очки кассирам",
    // 'claim': 'claim',
    client: "Список клиентов",
    spread: "Распространение  карт Wallet",
    "wallet-pass": "Дизайн карты Wallet",
    review: "Отзывы",
    // 'application': 'application',
    "noncash-tips": "Безналичные чаевые",
    "check-counterparties": "Проверка контрагентов",
    "push-notifications": "Push-уведомления",
    // 'group': 'Начисление баллов (группы клиентов)',
    "client-portrait": "Портрет клиента",
    "active-wait": "Начисление баллов (Активное ожидание)",
    "e-menu": "Электронное меню",
    faq: "FAQ",
    terminal: "Processing",
    promotion: "Акции",
    cashback_group: "Начисление баллов (Группы клиентов)",
    payment_info: "Способы оплаты",
    orders: "Предзаказы",
    "trading-point-promotion": "Продвижение",
    "gift-card": "Подарочные карты",
  },

  promotionServices: {
    servicesList: {
      "xxl-tag": {
        name: "XXL-размер карточки",
        description:
          "Увеличение размера карточки вашего заведения и размещение на ней бейджа «Топ место»",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет увеличена в размере, а также выделена бейджем «Топ место» на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Срок размещения – 1 неделя, 1 месяц или 3 месяца с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>Выбрать срок размещения можно на этапе оформления заказа.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      tag: {
        name: "Бейдж «Топ место»",
        description:
          "Размещение на карточке вашего заведения бейджа «Топ место» и перемещение её выше в общем списке",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет выделена бейджем «Топ место» и перемещена выше в списке на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Срок размещения –  1 неделя, 2 недели, 1 месяц или 3 месяца с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>Выбрать срок размещения можно на этапе оформления заказа.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      top: {
        name: "Топ 1 или 2 место",
        description:
          "Размещение карточки вашего заведения на 1 или 2 местах в списке (без изменения её внешнего вида и размера)",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет размещена на 1 или 2 месте на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Размещение на 1 или 2 месте определяется очередностью оформления заказа и оплаты данной услуги.</p></li>" +
          "<li><span>●</span><p>Срок размещения – 2 недели с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      hint: "В настоящий момент все доступные слоты по данной услуге в городе вашей торговой точки заняты. Заказ будет возможен с указанной даты.",
    },
    periodsList: {
      "7 days": "1 неделя",
      "14 days": "2 недели",
      "30 days": "1 месяц (30 дн)",
      "90 days": "3 месяца (90 дн)",
    },
  },

  //   pre-orders page
  preOrders: {
    title: "Предзаказы",
    notAvailablePreOrders: "Предзаказы пока недоступны",
    noOrders: "Заказов пока нет",
    noTradingPoints:
      "<p>У вас пока нет торговых точек, которые прошли модерацию и размещены на сайте <a class='blue-link' href='https://rcpay.ru/places/' target='_blank'>rcpay.ru</a> и в мобильном приложении RC PAY</p>",
    noProducts:
      "Чтобы принимать заказы необходимо добавить товары или услуги в электронное меню",
    noOrdersText:
      "<p>Чтобы ваши товары или услуги были доступны для заказа на сайте <a class='blue-link' href='https://rcpay.ru/places/' target='_blank'>rcpay.ru</a> и в мобильном приложении RC PAY, необходимо добавить их в электронное меню и включить прием предзаказов в настройках</p>",
  },
};
