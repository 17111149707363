export function useStopZoom() {
  // Отключаем масштабирование только при жестах масштабирования (pinch-to-zoom)
  document.addEventListener("gesturestart", function (e: any) {
    e.preventDefault();
  });

  // Отключаем масштабирование при изменении `scale` только, когда scale больше 1 (то есть, начинается зум)
  document.addEventListener(
    "touchmove",
    function (e: any) {
      if (e.scale && e.scale !== 1) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
}
