export const hideContactWidget = (value: boolean) => {
  const foundWidget = document.getElementById("usedesk-messenger");
  if (foundWidget) {
    foundWidget.style.display = value ? "none" : "block";
  }
};

export const toggleVisibilityContactWidget = (value: boolean) => {
  const foundWidget = document.getElementById("usedesk-messenger");
  if (foundWidget) {
    foundWidget.style.visibility = value ? "visible" : "hidden";
  }
};
