import { Directive, DirectiveBinding } from "vue";

// !ВАЖНО!!! Не применять на элементы с v-if и v-else, ведь это аналог этих директив
// Локально можно скорректировать стили с помощью :deep(.directive-loader)

export const LoadingDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding<boolean>) {
    const loaderSpan = document.createElement("span");
    loaderSpan.className = "directive-loader";

    if (binding.value) {
      el.style.display = "none";
      el.parentNode?.insertBefore(loaderSpan, el.nextSibling);
    }
  },

  updated(el: HTMLElement, binding: DirectiveBinding<boolean>) {
    const loaderSpan = el.nextElementSibling as HTMLSpanElement | null;

    if (binding.value && !loaderSpan) {
      el.style.display = "none";
      const newLoaderSpan = document.createElement("span");
      newLoaderSpan.className = "directive-loader";
      el.parentNode?.insertBefore(newLoaderSpan, el.nextSibling);
    } else if (!binding.value && loaderSpan) {
      el.style.display = "";
      loaderSpan.remove();
    }
  },

  unmounted(el: HTMLElement) {
    const loaderSpan = el.nextElementSibling as HTMLSpanElement | null;
    if (loaderSpan) {
      loaderSpan.remove();
    }
    el.style.display = "";
  },
} as Directive;
