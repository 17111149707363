import axios from "axios";
import store from "../store";

import { i18n } from "@/boot/i18n";

export const IS_PROD_MODE = import.meta.env.MODE === "production";

export let BASE_URL = "";
export let FRONT_URL = "";
export function changeBaseUrl() {
  if (
    window.location.host.includes("kz.rc") ||
    window.location.host.includes("kz.stage") ||
    window.location.host.includes("kz-review")
  ) {
    BASE_URL = import.meta.env.VITE_APP_BACK_URL_KZ;
    FRONT_URL = import.meta.env.VITE_APP_FRONT_URL_KZ;
  } else {
    BASE_URL = import.meta.env.VITE_APP_BACK_URL;
    FRONT_URL = import.meta.env.VITE_APP_FRONT_URL;
  }
}

changeBaseUrl();
export const API_URL = BASE_URL + "api/";

export const API = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const settings = {
  apiKey: import.meta.env.VITE_APP_YANDEX_MAP_KEY,
  lang: "ru_RU",
  coordorder: "longlat",
  enterprise: false,
  version: "2.1",
};

API.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("access_token")) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "access_token"
      )}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
API.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    let ttl = "";
    let desc = "";
    let mes = "";
    let descr = "";
    const exceptions = [
      "User unauthorized",
      "Отсутствует авторизация",
      "Invalid one-time code was passed",
    ];

    const SLUG_EXCEPTIONS = [
      "trading_point.exceeded_the_monthly_limit_on_gift_cards",
      "trading_point.available_limit_not_enough",
      "gift_card.not_enough_money",
    ];

    const URL_EXCEPTIONS = ["account/documents"];

    // Отключение вывода нотифая для ошибок авторизации
    if (exceptions.includes(error.response?.data?.message)) {
      return Promise.reject(error.response);
    }

    // Отключение вывода нотифая по slug
    if (SLUG_EXCEPTIONS.includes(error.response?.data?.code)) {
      return Promise.reject(error.response);
    }

    // Отключение вывода нотифая по url
    if (URL_EXCEPTIONS.includes(error.response?.config?.url)) {
      return Promise.reject(error.response);
    }

    if (error?.response?.data?.message) {
      switch (error.response.data.message) {
        case "Wrong login": {
          descr = "wrongLogin";
          break;
        }
        case "Wrong password": {
          descr = "wrongPass";
          break;
        }
        case "Unauthenticated.": {
          descr = "unauthenticated";
          localStorage.removeItem("access_token");
          if (window.location.pathname !== "/login") {
            setTimeout(() => {
              location.href = "/";
            }, 2000);
          }
          break;
        }
        case "Legal Entity with this IIK already exist": {
          descr = "iikAlready";
          break;
        }
        case "This first login, please create password": {
          descr = "firstLoginManager";
          break;
        }
        case "Insufficient balance on the account": {
          descr = "noMoney";
          break;
        }
        case "Wrong period": {
          descr = "wrongPeriod";
          break;
        }
        case "Trading Point creation is limited by tariff": {
          descr = "limitShops";
          break;
        }
        case "Legal entity creation is limited by tariff": {
          descr = "limitLegalEntity";
          break;
        }
        case "Wrong tariff": {
          descr = "wrongTariff";
          break;
        }
        case "wrong tariff": {
          descr = "wrongTariffPromo";
          break;
        }
        case "This tag already exist": {
          descr = "tagExist";
          break;
        }
        case "Manager blocked": {
          descr = "blockManager";
          break;
        }
        case "wrong code":
        case "Wrong code": {
          descr = "wrongCode";
          break;
        }
        case "Promocode doesn't exists": {
          descr = "codeExists";
          break;
        }
        case "exam is not passed": {
          descr = "noExam";
          break;
        }
        case "Account not activated": {
          descr = "noActivateAccount";
          setTimeout(() => {
            location.href = "email-check";
          }, 2000);
          break;
        }
        case "Owner with this email already exist": {
          descr = "emailExist";
          break;
        }
        case "User already exist":
        case "user already exist": {
          descr = "userExist";
          break;
        }
        case "User does not have the right permissions.": {
          descr = "noPermissions";
          break;
        }
        case "Agreement not accepted": {
          descr = "noAccepted";
          break;
        }
        case "Balance not enough": {
          descr = "balanceNotEnough";
          break;
        }
        case "Legal Entity with this INN already exist": {
          descr = "innExist";
          break;
        }
        case "Today you cannot change tariff": {
          descr = "noTodayChangeTariff";
          break;
        }
        case "Bad token for password reset": {
          descr = "badToken";
          break;
        }
        case "Wrong email": {
          descr = "wrongEmail";
          break;
        }
        case "Not available at the current tariff": {
          descr = "notAvailableCurrentTariff";
          break;
        }
        case "Data is not correct": {
          descr = "dataNoCorrect";
          break;
        }
        case "Account not found": {
          descr = "accountNotFound";
          break;
        }
        case "Trading Point already exist": {
          descr = "TPExist";
          break;
        }
        case "Server error": {
          descr = "serverError";
          break;
        }
        case "Server Error": {
          descr = "serverError";
          break;
        }
        case "Email or Company already exist": {
          descr = "emailOrCompanyExist";
          break;
        }
        case "Owner does not exist": {
          descr = "ownerExist";
          break;
        }
        case "This action is unauthorized": {
          descr = "unauthorizedAction";
          break;
        }
        case "Legal Entity does not exist": {
          descr = "legalEntityExist";
          break;
        }
        case "Tariff can be changed one day before the end": {
          descr = "tariffChangeOneDay";
          break;
        }
        case "Blocked by Tariff": {
          descr = "blockedByTariff";
          break;
        }
        case "Document in the process of creation": {
          descr = "documentInProcessOfCreation";
          break;
        }
        case "Email already verified": {
          descr = "emailAlreadyVerified";
          break;
        }
        case "Code does not exist": {
          descr = "codeDoesNotExist";
          break;
        }
        case "Default cashback can't be deleted": {
          descr = "defaultCashbackCanNotBeDeleted";
          break;
        }
        case "Cashback update successful": {
          descr = "cashbackUpdateSuccessful";
          break;
        }
        case "Cashback delete successful": {
          descr = "cashbackDeleteSuccessful";
          break;
        }
        case "Cashback create successful": {
          descr = "cashbackCreateSuccessful";
          break;
        }
        case "Full cashback": {
          descr = "fullCashback";
          break;
        }
        case "Client group already in use": {
          descr = "clientGroupAlreadyInUse";
          break;
        }
        case "Entered amount is already in use": {
          descr = "enteredAmountIsAlreadyInUse";
          break;
        }
        case "Unable to update wallet pass": {
          descr = "updateWallet";
          break;
        }
        case "Unable to reset wallet pass": {
          descr = "resetWallet";
          break;
        }
        case "30 days have not passed since the last choice": {
          descr = "changeMainOrganizationError";
          break;
        }
        case "User unauthorized": {
          descr = "userUnauthorized";
          break;
        }
        case "The date start is not a valid date.": {
          descr = "dateStartIsInvalid";
          break;
        }
        case "The date end is not a valid date.": {
          descr = "dateEndIsInvalid";
          break;
        }
        case "Legal Entity not confirmed": {
          descr = "organizationNotConfirmed";
          break;
        }
        case "This period is not available": {
          descr = "periodNotAvailable";
          break;
        }
        case "Not available in your country": {
          descr = "notAvailableInYourCountry";
          break;
        }

        case "Exceeded activated promotion groups limit": {
          descr = "exceededActivatedPromotionLimit";
          break;
        }

        case "Tariff ProPlus allows to have no more than 30 trading points": {
          descr = "tradingPointLimitOnProPlus";
          break;
        }

        case "Tariff ProPlus allows to have no more than 15 trading points": {
          descr = "tradingPointLimitOnPromo";
          break;
        }

        case "Tariff ProPlus allows to have no more than 5 trading points": {
          descr = "tradingPointLimitOnLight";
          break;
        }

        case "The date end must be a date after or equal to date start.": {
          descr = "wrongDateOrder";
          break;
        }

        default: {
          descr = error?.response?.data?.message;
          break;
        }
      }

      const mes = !Object.keys(i18n.global.tm("sys")).includes(descr)
        ? descr
        : i18n.global.t(`sys.${descr}`);

      store.dispatch("createNotification", {
        type: "error",
        status: i18n.global.t("sys.error") + error?.response?.status,
        message: mes,
      });
      return Promise.reject(error.response);
    }

    if (error?.response?.status) {
      switch (error.response.status) {
        case 401:
          store.dispatch("createNotification", {
            type: "error",
            status: i18n.global.t("sys.error") + error?.response?.status,
            message: i18n.global.t(`sys.${descr}`),
          });

          setTimeout(() => {
            if (window.location.href.includes("/cabinet/")) {
              if (window.location.href.includes("localhost")) {
                window.location.href = "http://" + window.location.host;
              } else {
                window.location.href =
                  localStorage.country === "kz"
                    ? import.meta.env.VITE_APP_FRONT_URL_KZ
                    : import.meta.env.VITE_APP_FRONT_URL;
              }
            }
          }, 1000);

          return Promise.reject(error.response);
        case 500:
          store.dispatch("createNotification", {
            type: "error",
            status: i18n.global.t("sys.error") + error?.response?.status,
            message: i18n.global.t("sys.500}"),
          });
          break;
        case 404:
        case 423:
          store.dispatch("createNotification", {
            type: "error",
            status: i18n.global.t("sys.error") + error?.response?.status,
            message: i18n.global.t(`sys.${descr}`),
          });
          break;
        case 400:
          if (error?.response?.data?.message !== "Agreement not accepted") {
            store.dispatch("createNotification", {
              type: "error",
              status: i18n.global.t("sys.error") + error?.response?.status,
              message: i18n.global.t(`sys.${descr}`),
            });
          }
          break;
        case 403:
          console.log(descr);
          if (descr === "firstLoginManager") {
            store.dispatch("createNotification", {
              type: "warning",
              status: i18n.global.t("sys.error") + error?.response?.status,
              message: i18n.global.t(`sys.${descr}`),
            });
            break;
          } else {
            console.log(descr);
          }
          //потому что бекенд иногда отдает готовый текст ошибки
          mes = !Object.keys(i18n.global.tm("sys")).includes(descr)
            ? descr
            : i18n.global.t(`sys.${descr}`);

          store.dispatch("createNotification", {
            type: "error",
            status: i18n.global.t("sys.error") + error?.response?.status,
            message: mes,
          });
          break;
        case 422:
          if (error.response.data.errors) {
            const errors = error.response.data.errors;

            for (const key in errors) {
              ttl = key;

              for (const i in errors[key]) {
                desc = errors[key][i];
              }
            }

            if (ttl === "email") {
              ttl = "";
            }

            if (ttl.includes("files")) {
              ttl = i18n.global.t("sys.error") + error?.response?.status;
              desc = i18n.global.t("sys.maxFileSizeError");
            }

            switch (desc) {
              case "The address field is required.": {
                ttl = i18n.global.t("sys.error") + error?.response?.status;
                desc = i18n.global.t("organization.addressFieldRequired");
                break;
              }
              case "The meta field is required.": {
                ttl = i18n.global.t("sys.error") + error?.response?.status;
                desc = i18n.global.t("organization.metaIsRequired");
                break;
              }
              case "The email has already been taken.": {
                ttl = i18n.global.t("sys.error") + error?.response?.status;
                desc = i18n.global.t("sys.emailTaken");
                break;
              }

              case "The description format is invalid.": {
                ttl = i18n.global.t("sys.error") + error?.response?.status;
                desc = i18n.global.t("sys.descriptionFormatInvalid");
                break;
              }

              case "The description must not contain emoji.": {
                ttl = i18n.global.t("sys.error") + error?.response?.status;
                desc = i18n.global.t("sys.descriptionMustNotContainEmoji");
                break;
              }

              case "The description must be at least 800 characters.": {
                ttl = i18n.global.t("sys.error") + error?.response?.status;
                desc = i18n.global.t("sys.descriptionMinLength");
                break;
              }

              case "The description must not be greater than 3000 characters.": {
                ttl = i18n.global.t("sys.error") + error?.response?.status;
                desc = i18n.global.t("sys.descriptionMaxLength");
                break;
              }
            }
          } else if (error.response.data.message) {
            ttl = i18n.global.t(`sys.${descr}`);
          }

          if (
            !Object.keys(i18n.global.tm("sys")).includes(
              error.response.data.message
            )
          ) {
            store.dispatch("createNotification", {
              type: "error",
              status: i18n.global.t("sys.error") + error?.response?.status,
              message: error.response.data.message,
            });
            break;
          }

          store.dispatch("createNotification", {
            type: "error",
            status: ttl,
            message: desc,
          });
          break;
        case 502:
          location.href = "/";
          store.dispatch("createNotification", {
            type: "error",
            status: i18n.global.t("sys.error") + error?.response?.status,
            message: i18n.global.t(`sys.${descr}`),
          });
          break;
        case 503:
          store.dispatch("createNotification", {
            type: "error",
            status: i18n.global.t("sys.error") + error?.response?.status,
            message: i18n.global.t("sys.503"),
          });
          break;
        case 424:
          store.dispatch("createNotification", {
            type: "error",
            message: i18n.global.t("sys.syncError"),
            status: i18n.global.t("sys.error") + error?.response?.status,
          });
          break;
      }

      return Promise.reject(error.response);
    }
  }
);
