export const balanceEn = {
  title: "Balance",
  chose: "Select entity to replenish",
  enterSum: "Enter amount",
  confirmSum: "An account will be created for the given amount",
  choseOrganization: "Accrual",
  bill: "Invoicing",
  billOk: "Invoice has been issued successfully",
  downloadBill: "Download invoice",
};
