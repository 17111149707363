export const SYSTEM_COUNTRIES = [
  { value: "ru", label: "Россия", name: "RU" },
  { value: "kz", label: "Казахстан", name: "KZ" },
  { value: "md", label: "Молдова", name: "RO" },
];

export const LANGUAGES = [
  { value: "ru", label: "Русский", icon: "ru.png", name: "RU" },
  { value: "en-us", label: "English", icon: "en.png", name: "EN" },
  { value: "kz-kz", label: "Қазақша", icon: "kz.png", name: "KZ" },
];
