export const balanceKz = {
  title: "Баланс",
  chose: "Толтыру үшін ұйымды таңдаңыз",
  enterSum: "Соманы енгізіңзі",
  confirmSum: "Бұл сомаға шот жасалады",
  choseOrganization: "Есептеу ұйымы",
  bill: "Шотты ұсыну",
  billOk: "Шот сәтті ұсынылды",
  downloadBill: "Шотты жүктеу",
};
