import { EntityPayloadProcessing } from "@/package/global-use-case/ProcessingBeforeSending";
import fileDownload from "js-file-download";
import { API } from "@/boot/api";

const state = {
  entities: [],
  viewedEntity: null,
  entitiesList: [],
  entityById: null,
  viewedEntityType: "creation",
};

const getters = {};

const mutations = {
  SET_ENTITIES: (state, entities) => {
    state.entities = entities;
  },

  SET_ENTITY_BY_ID: (state, entities) => {
    state.entityById = entities;
  },

  SET_ENTITIES_LIST(state, entitiesList) {
    state.entitiesList = entitiesList;
  },

  CREATE_VIEWED_ENTITY(state, entity) {
    state.viewedEntity = entity;
  },

  SET_VIEWED_ENTITY(state, entity) {
    for (const key in entity) {
      if (key === "meta" || key === "status") {
        continue;
      }

      state.viewedEntity[key] = entity[key] === null ? "" : entity[key];
    }
  },

  SET_VIEWED_ENTITY_META(state, meta) {
    if (!state.viewedEntity?.meta) {
      return;
    }

    for (const key in meta) {
      state.viewedEntity.meta[key] = meta[key] ?? "";
    }
  },

  SET_VIEWED_ENTITY_TYPE(state, type) {
    state.viewedEntityType = type;
  },
};

const actions = {
  sendEntity(_, data) {
    return new Promise((resolve, reject) => {
      API.post("entities", EntityPayloadProcessing(data))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateEntity(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`entities/${data.id}/update`, EntityPayloadProcessing(data))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadFile(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`entities/${data.id}/acceptance`, {
        params: {
          type: data.type,
        },
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
        .then((response) => {
          fileDownload(response.data, data.document_name ?? "payment.pdf");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeMainEntity(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`entities/${data.id}/main `)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // старый запрос, удалить во время рефакторинга
  getEntities({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("account/entities", {
        params: data,
      })
        .then((response) => {
          commit("SET_ENTITIES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getEntityById({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`entities/${data.id}`)
        .then((response) => {
          commit("SET_ENTITY_BY_ID", response.data.legal_entity);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeEntityRequisites(_, data) {
    return new Promise((resolve, reject) => {
      API.patch(`entities/${data.id}/bank-data`, EntityPayloadProcessing(data))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Список минимальной информации о Юр Лицах, подходит для селекторов
  loadEntitiesList({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("account/filters/entities")
        .then((response) => {
          commit("SET_ENTITIES_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
