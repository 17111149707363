<script setup lang="ts">
import { PropType, computed } from "vue";
import { useRoute, RouterLink } from "vue-router";
import TransitionExpand from "@/components/TransitionExpand.vue";
import TransitionOpacity from "@/ui/TransitionOpacity.vue";
import ButtonWrapper from "@/components/common/ButtonWrapper.vue";
import type { TMenuItem, TMenuItemDetail } from "../../types";

defineOptions({
  name: "MainSidebarMenuItem",
});

const props = defineProps({
  item: {
    type: Object as PropType<TMenuItem>,
    default: () => {},
  },
  collapse: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["collapse"]);
const route = useRoute();

const collapseMenu = () => {
  if (props.item.details.length > 0) {
    emit("collapse", props.item.visible ? "" : props.item.name);
  }
};

const isShowBody = computed(() => {
  return props.item.details.length > 0 && props.item.visible;
});

const headerComponent = computed(() => {
  return props.item.details.length > 0 ? ButtonWrapper : RouterLink;
});

const transitionComponent = computed(() => {
  return props.collapse ? TransitionOpacity : TransitionExpand;
});

const activeClass = (element: TMenuItem | TMenuItemDetail) => {
  let activeDetails = false;

  if (element?.details) {
    for (const key in element?.details || []) {
      activeDetails = route.fullPath
        .split("/")[2]
        .includes(element.details[key].path);
      if (activeDetails) {
        break;
      }
    }

    return element.path
      ? route.fullPath.split("/")[2].includes(element.path)
      : activeDetails;
  }

  return route.fullPath.split("/")[2].includes(element.path);
};

const mouseenter = () => {
  if (props.collapse) {
    collapseMenu();
  }
};
const mouseleave = () => {
  if (props.collapse) {
    collapseMenu();
  }
};
</script>

<template>
  <div
    class="main-sidebar-menu__item menu_item"
    :class="{ collapse }"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <component
      :is="headerComponent"
      class="menu_item__header header"
      :class="{
        active: activeClass(item),
        open: isShowBody,
        collapse,
      }"
      :to="{ name: item.componentName || null }"
      @click="collapseMenu"
    >
      <div class="header__icon-wrapper icon-wrapper">
        <span
          v-if="props.item.icon"
          :class="props.item.icon"
          class="header__icon icon"
        />
      </div>

      <div class="header__title title">
        <span>{{ $t(`leftPanel.${props.item.name}`) }}</span>
      </div>

      <div
        v-if="props.item.details.length"
        class="header__arrow arrow icon-arrow-down"
        :class="{ rotate: props.item.visible, open: isShowBody }"
      />
    </component>

    <component :is="transitionComponent">
      <div v-if="isShowBody" class="menu_item__body body">
        <RouterLink
          v-for="detail in props.item.details"
          :key="detail.name"
          class="body__item item"
          :class="{ active: activeClass(detail) }"
          :to="{ name: detail.componentName }"
        >
          <div class="item__title title">
            <span>{{ $t(`leftPanel.${detail.name}`) }}</span>
          </div>
        </RouterLink>
      </div>
    </component>
  </div>
</template>

<style scoped lang="scss">
.menu_item {
  width: 100%;
  position: relative;
  cursor: pointer;
  @include transition;

  .icon {
    width: 32px;
    height: 32px;
    background: var(--color-semantic-content-normal-tertiary);
    flex-shrink: 0;

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 32px;
    }
  }

  .title {
    flex: 1 1 100%;
    margin: 0;
    @include transition;

    > span {
      @include body;
      color: var(--color-semantic-content-normal-primary);
    }
  }

  .arrow {
    width: 16px;
    height: 16px;
    background: var(--color-semantic-content-normal-tertiary);
    flex-shrink: 0;
    @include transition;

    &.open {
      transform: rotate(180deg);
    }
  }

  .item::before,
  .header::before {
    content: "";
    display: flex;
    width: 4px;
    height: 40px;
    align-items: flex-start;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    background-color: var(--color-semantic-content-normal-brand);
    border-radius: 0 4px 4px 0;
    opacity: 0;
  }

  .item,
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 12px 16px;
    border-radius: 8px;
    position: relative;
    @include transition;

    &:hover {
      .title {
        overflow: hidden;

        > span {
          color: var(--color-semantic-content-hover-primary);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .header {
    display: flex;

    .title {
      overflow: hidden;

      > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &.active,
    &.router-link-exact-active {
      &.collapse {
        background: var(--color-semantic-background-normal-secondary);

        &::before {
          opacity: 1;
        }
      }

      &:not(.open) {
        background: var(--color-semantic-background-normal-secondary);
        .title {
          > span {
            @include body-bold;
            color: var(--color-semantic-content-normal-primary);
          }
        }

        &::before {
          opacity: 1;
        }
      }
    }

    &.collapse {
      gap: 0;

      &:hover {
        background: var(--color-semantic-background-normal-secondary);
      }
    }
  }

  .item {
    padding: 16px 16px 16px 64px;

    &.active,
    &.router-link-exact-active {
      background: var(--color-semantic-background-normal-secondary);

      .title {
        > span {
          @include body-bold;
          color: var(--color-semantic-content-normal-primary);
        }
      }

      &::before {
        opacity: 1;
      }
    }
  }

  .body {
    position: static;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 4px;
    min-width: 216px;
  }

  &.collapse {
    .header {
      padding: 10px;
      justify-content: center;

      .title,
      .arrow {
        width: 0;
      }

      &::before {
        left: -8px;
      }
    }

    .body {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(0, -50%);
      width: max-content;
      max-width: 260px;
      border-radius: 8px;
      border: 1px solid var(--color-semantic-border-normal-soft);
      background: var(--color-semantic-background-normal-primary);
      box-shadow: var(--box-shadow);
      padding: 4px 0;
      margin-left: 20px;
      pointer-events: none;
      z-index: -1;
      margin-top: 0;

      &::before {
        content: "";
        width: 20px;
        height: 100%;
        position: absolute;
        left: -20px;
      }

      .item {
        padding-left: 16px;

        // Доработаем отображение маркера после правок макета
        // &::before {
        //   opacity: 0;
        // }
      }
    }

    &:hover {
      .body {
        pointer-events: initial;
        z-index: 1;
        transform: translate(0, -50%);
      }
    }
  }
}

@media (max-width: 1535px) {
  .menu_item {
    .icon {
      width: 24px;
      height: 24px;
    }

    .item,
    .header {
      padding: 12px;
      gap: 8px;

      .title {
        overflow: hidden;
        > span {
          @include text-2;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .header {
      &.active {
        &:not(.open) {
          .title {
            > span {
              @include text-2-bold;
            }
          }
        }
      }
    }

    .item {
      padding: 14px 12px 14px 44px;

      &.active {
        .title {
          > span {
            @include text-2-bold;
          }
        }
      }
    }
  }
}
</style>
