import { API, BASE_URL } from "@/boot/api";

let lang = localStorage.getItem("selected-language");

if (lang && lang.length > 2) {
  lang = lang.substring(0, 2);
}

const state = {
  currentStep: { id: "mainInfo", step: 1 },
  lastFilledStep: { id: "", step: 0 },
};

const getters = {};

const mutations = {
  SET_REGISTER_STEP(state, step) {
    state.currentStep = step;
  },

  SET_LAST_FILLED_STEP(state, step) {
    state.lastFilledStep = step;
  },
};

const actions = {
  promoCodeCheck(_, data) {
    return new Promise((resolve, reject) => {
      API.post("auth/promocode", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  email(_, data) {
    return new Promise((resolve, reject) => {
      API.post("auth/mail", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // account(_, data) {
  //   return new Promise((resolve, reject) => {
  //     Api.post("/auth/register", data)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  emailcheck(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        "/auth/verification",
        data /*{
        code: data.code,
      }*/
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  emailCodeAgain(_, data) {
    return new Promise((resolve, reject) => {
      API.post("/auth/code-repeat", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  backToService() {
    if (window.location.hostname === "rc.city") {
      window.location.href = "https://rcgroup.wiki";
    } else {
      window.location.href = "https://rc-wiki-front-staging.stage.rccore.net/";
    }
  },

  authForWiki() {
    window.location.href = BASE_URL + "oauth2/continue";
  },

  internalNotificationsAccount() {
    return new Promise((resolve, reject) => {
      API.get("/account/internal-notifications", {
        headers: {
          "Accept-Language": lang,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  notificationMarkAsReady(_, notification_id) {
    return new Promise((resolve, reject) => {
      API.post(
        `notifications/${notification_id}/mark-as-read`,
        {},
        {
          headers: {
            "Accept-Language": lang,
          },
        }
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
