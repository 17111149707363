export const balanceRu = {
  title: "Баланс",
  aboutPage: {
    title: "Информация о балансе",
    subTitle:
      "Отображаемые RC на балансе являются ожидаемой суммой на счету после осуществления взаиморасчетов по всем операциям.",
  },

  debtTitle: "Оплатите задолженность",
  debtMessage:
    "Скачайте и оплатите счет, отправленный на вашу электронную почту, или создайте его самостоятельно, нажав на кнопку «Пополнить»",
};
