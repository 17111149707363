<script>
export default {
  name: "LoaderInline",

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <div v-if="loading" class="loader" />
</template>

<style lang="scss" scoped>
.loader {
  width: 100px;
  height: 100px;
  background-image: url("../assets/icons/loader.svg");
  background-repeat: no-repeat;
  background-size: 100px;
  margin: 250px auto;
}
</style>
